import { useEffect, useState } from "react";
import { Form, Modal, Tab, Tabs } from "react-bootstrap";
import ImageUploader from "../ImageUploader";
import { useApi } from "../../hooks/useApi"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import ModifyTopAd from "./ModifyTopAd";

const ModifyAd = ({ post, refreshAd }) => {
    const [tabKey, setTabKey] = useState('basic');
    const [show, setShow] = useState(false);
    const [deletedMedia, setDeletedMedia] = useState([]);
    const [newMedia, setNewMedia] = useState([]);
    const [privacyTypes, setPrivacyTypes] = useState([]);
    const [data, setData] = useState({})
    const [changedData, setChangedData] = useState({})
    const [enableSave, setEnableSave] = useState(false)

    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();

    const units = [
        "Per Item",
        "Per Accessory", "Per Acre", "Per Ampoule", "Per Apartment", "Per Bag", "Per Barrel",
        "Per Batch", "Per Battery", "Per Battery Pack", "Per Bottle", "Per Box",
        "Per Brand", "Per Building", "Per Bundle", "Per CPU",
        "Per Can", "Per Capsule", "Per Carton", "Per Cartridge", "Per Case", "Per Charger", "Per Coil",
        "Per Collection", "Per Color", "Per Component", "Per Cube", "Per Design",
        "Per Desktop", "Per Device", "Per Dozen", "Per Drum",
        "Per Estate", "Per Floor", "Per Foot",
        "Per Gallon", "Per Garment", "Per Handset", "Per Hanger", "Per Head", "Per Hectare",
        "Per House", "Per Jar", "Per Keyboard", "Per Keyboard Layout", "Per Kilogram (kg)",
        "Per Laptop", "Per Lease", "Per Length", "Per Liter (L)",
        "Per Lot", "Per Meter", "Per Model", "Per Monitor", "Per Mouse", "Per Pack",
        "Per Pad", "Per Pair", "Per Pallet", "Per Parcel", "Per Pattern", "Per Piece",
        "Per Plot", "Per Pound (lb)",
        "Per Property", "Per Rack", "Per Roll", "Per Room",
        "Per SKU", "Per Sachet", "Per Screen", "Per Serial Number", "Per Set", "Per Sheet",
        "Per Size", "Per Slice", "Per Software License", "Per Square Foot", "Per Square Meter",
        "Per Style", "Per Survey", "Per Syringe", "Per Tablet",
        "Per Tray", "Per Tube", "Per Unit", "Per Unit Load", "Per Vial", "Per Warranty", "Per Zone", "Per Vehicle"
    ];

    const handleClose = () => {
        setData({})
        setChangedData({})
        cm.showLoader(false)
        setShow(false);
    }

    const handleFormInput = (e) => {
        const { name, value } = e.target;
        setChangedData({ ...changedData, [name]: value })
        setData({ ...data, [name]: value })
    }

    const handlePricingInput = (e) => {
        const { name, value } = e.target;
        const pricing = { ...data?.pricing, [name]: value }
        setChangedData({ ...changedData, pricing: pricing })
        setData({ ...data, pricing: pricing })
    }

    const handleTopAdUpdate = (value) => {
        setChangedData({ ...changedData, top_ads: value })
        setData({ ...data, top_ads: value })
    }

    const getPrivacyTypeFromApi = async () => {
        try {
            const res = await api.getPrivacyTypes();
            setPrivacyTypes(res);
        } catch (err) {
            alert.show(err?.message, "error");
        }
    };

    const getAdDetailFromApi = async (aliasId) => {
        try {
            cm.showLoader(true);
            const res = await api.getAdDetail(aliasId || post?.alias_id);
            if (res) {
                setData(res);
                cm.showLoader(false);
            }
        } catch (err) {
            handleClose();
            alert.show(err?.response?.data?.msg || err, "error")
        }
    };


    const renderCategories = () => {
        const uniq = (items) => [...new Set(items)];
        const uniqueGroups = uniq(cm?.categories.map((item) => item?.group?.name));
        return uniqueGroups?.map((g, ix) => {
            return <optgroup key={g + "_" + ix} label={g} style={{ background: "#fff" }}>
                {cm?.categories && cm?.categories?.map(c => c?.group?.name === g && <option key={"cat_" + c.id} value={c?.id} >{cm?.translate(c?.name)}</option>)}
            </optgroup>
        })
    }

    const renderPrivacyTypes = () => {
        return privacyTypes && privacyTypes?.map(p => <option key={"pt_" + p.id} value={p.id}>{p.name}</option>)
    }

    const handleUpdate = async () => {
        try {
            cm?.showLoader(true);
            const cd = { ...changedData, description: changedData?.description?.replaceAll("\n", "<br />") }
            const res = await api.updateAd(post?.alias_id, cd);
            if (res) {
                if (changedData?.pricing?.unit_price) {
                    await api.updatePricing(post?.alias_id, changedData?.pricing)
                }
                alert.toast("Updated", "success")
                window.location.href = "/mypage/?page=ads"
            }
            cm.showLoader(false);
        } catch (err) {
            cm?.showLoader(false)
            setData({});
            handleClose();
            alert.show(err?.response?.data?.msg || err, "error")
        }

    }

    const getCategoriesFromApi = async () => {
        try {
            cm?.showLoader(true)
            const res = await api.getCategories();
            cm.setCategories(res.sort((a, b) => a?.group?.id - b?.group?.id));
            cm?.showLoader(false)
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.message, "error")
        }
    };

    const enableSaveButton = () => {
        if (changedData && Object.keys(changedData).length) {
            if (changedData?.title === "" ||
                changedData?.category === "" ||
                changedData?.description === ""
            ) {
                setEnableSave(false)
            } else {
                setEnableSave(true)
            }
        } else {
            setEnableSave(false)
        }
    }

    useEffect(() => {
        getCategoriesFromApi();
        getPrivacyTypeFromApi();
        getAdDetailFromApi();
    }, [show])

    useEffect(() => {
        newMedia.length > 0 && setChangedData({ ...changedData, new_media: newMedia })
    }, [newMedia])

    useEffect(() => {
        deletedMedia.length > 0 && setChangedData({ ...changedData, deleted_media: deletedMedia })
    }, [deletedMedia])


    useEffect(() => {
        enableSaveButton()
    }, [changedData])


    return <>
        <button className="btn btn-sm btn-outline-dark" onClick={e => setShow(true)}>{cm?.translate("Modify Ad")}</button>
        <Modal size="lg" scrollable="true" show={show} backdrop="static" onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Modify Ad")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={tabKey}
                    onSelect={(k) => setTabKey(k)}
                    className="mb-3 font-size-12 bg-light"
                >
                    <Tab eventKey="basic" title={cm?.translate("Basic Information")}>
                        <div className="row m-0 p-2 pb-4">
                            <div className="col-sm-12 col-md-8">
                                <span className="fw-semibold">{cm?.translate("Photos")}</span>
                                <div className="mt-3 d-flex">
                                    <ImageUploader setNewFiles={setNewMedia} setDeletedExistingFile={setDeletedMedia} deletedExistingFile={deletedMedia} existingMediaFiles={data?.media} label={cm?.translate("Select")} width="auto" maxSize={10000000} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Users can upload up to 10 photos per ad, with a total size limit of 10MB for all photos.")}</p>
                            </div>
                        </div>

                        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                            <div className="col-sm-12 col-md-8">
                                <span className="fw-semibold">{cm?.translate("Category")}</span>
                                <div className="mt-3">
                                    <Form.Select autoFocus name="category" value={data?.category?.id} onChange={e => handleFormInput(e)}>
                                        <option value="">{cm?.translate("Select Category")} *</option>
                                        {renderCategories()}
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Users must select the appropriate category from the list to ensure buyers can easily find the ad.")}</p>
                            </div>
                        </div>

                        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                            <div className="col-sm-12 col-md-8">
                                <span className="fw-semibold">{cm?.translate("Title")}</span>
                                <div className="mt-3">
                                    <input type="text" name="title" className="form-control" required placeholder="Write a suitable title" value={data?.title || ""} onChange={e => handleFormInput(e)} ></input>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                <p className="my-2 my-md-0 font-size-9"></p>
                            </div>
                        </div>

                        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                            <div className="col-sm-12 col-md-8">
                                <span className="fw-semibold">{cm?.translate("Description")}</span>
                                <div className="mt-3">
                                    <textarea name="description" rows="5" className="form-control" value={data?.description?.replaceAll("<br />", "\n") || ""} onChange={e => handleFormInput(e)}></textarea>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                <p className="my-2 my-md-0 font-size-9"></p>
                            </div>
                        </div>

                        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                            <div className="col-sm-12 col-md-8">
                                <span className="fw-semibold">{cm?.translate("Privacy")}</span>
                                <div className="mt-3">
                                    <select name="privacy_type" value={changedData?.privacy_type || data?.privacy_type || data?.privacy?.id} onChange={handleFormInput} className="form-select">
                                        {renderPrivacyTypes()}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                <p className="my-2 my-md-0 font-size-9"></p>
                            </div>
                        </div>

                    </Tab>
                    <Tab eventKey="pricing" title={cm?.translate("Pricing")}>
                        {data?.category?.group?.name?.toLowerCase() === "job" || !data?.category?.id ?
                            <h6>{cm?.translate("Select the appropriate category to set a price. Categories such as 'Job' is not eligible for price settings.")}</h6>
                            : <>
                                <div className="row m-0 p-2 pb-4">
                                    <div className="col-sm-12 col-md-8">
                                        <span className="fw-semibold">{cm?.translate("Price & Unit")}</span>
                                        <div className="mt-3 d-flex">
                                            <input type="number" min={0} className="form-control flex-fill" name="unit_price" required placeholder={cm?.translate("Price in Taka")} onChange={e => handlePricingInput(e)} value={data?.pricing?.unit_price || ""}></input>
                                            <select name="serving_unit" className="form-select ms-2" value={data?.pricing?.serving_unit} onChange={(e) => handlePricingInput(e)}>
                                                {units?.map((u, i) => <option key={"unit_" + i} value={u}>{cm?.translate(u)}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Include the unit price to display it in ad and unit name. Buyers can place orders based on this pricing.")}</p>
                                    </div>
                                </div>

                                <div className="row m-0 p-2 pb-4">
                                    <div className="col-sm-12 col-md-8">
                                        <span className="fw-semibold">{cm?.translate("Tax Rate")}</span>
                                        <div className="mt-3">
                                            <input type="number" min={0} className="form-control" name="tax" required placeholder={cm?.translate("% of Tax")} onChange={e => handlePricingInput(e)} value={data?.pricing?.tax || ""}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Specify the percentage of tax to be applied if applicable.")}</p>
                                    </div>
                                </div>

                                <div className="row m-0 p-2 pb-4">
                                    <div className="col-sm-12 col-md-8">
                                        <span className="fw-semibold">{cm?.translate("Delivery Charge")}</span>
                                        <div className="mt-3">
                                            <input type="number" min={0} className="form-control" name="delivery_charge" required placeholder={cm?.translate(`Delivery charge in Taka`)} onChange={e => handlePricingInput(e)} value={data?.pricing?.delivery_charge || ""}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("The specified delivery charge will be displayed when a buyer places an order.")}</p>
                                    </div>
                                </div>

                                <div className="row m-0 p-2 pb-4">
                                    <div className="col-sm-12 col-md-8">
                                        <span className="fw-semibold">{cm?.translate("Additional Instruction or Note")}</span>
                                        <div className="mt-3">
                                            <textarea className="form-control" name="order_related_info" placeholder={cm?.translate("Detail and additional info from seller")} style={{ height: 100 }} onChange={e => handlePricingInput(e)} value={data?.pricing?.order_related_info || ""} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Sellers can include essential information to be displayed when the buyer places an order. This ensures that buyers are well-informed and can proceed with a clear understanding.")}</p>
                                    </div>
                                </div>
                                <div className="row m-0 p-2 pb-4">
                                    <div className="col-sm-12 col-md-8">
                                        <span className="fw-semibold">{cm?.translate("Notification Email")}</span>
                                        <div className="mt-3">
                                            <input name="notification_email" type="text" className="form-control" required placeholder={cm?.translate("Email address")} onChange={e => handlePricingInput(e)} value={data?.pricing?.notification_email || ""}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Order will be notfied to given email address.")}</p>
                                    </div>
                                </div>
                            </>
                        }
                    </Tab>
                    {post?.top_ads?.expire && <Tab eventKey="topad" title={cm?.translate("Top Ad")}>
                        {show && <ModifyTopAd post={post} updateTopAd={handleTopAdUpdate} />}
                    </Tab>}
                </Tabs>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-outline-dark border-0" onClick={handleClose}>{cm?.translate("Cancel")}</button>
                <button className="btn btn-sm btn-success border-0" onClick={handleUpdate} disabled={!enableSave}>{cm?.translate("Save")}</button>
            </Modal.Footer>
        </Modal >
    </>
}
export default ModifyAd;