import { ImSpinner10, ImSpinner9 } from "react-icons/im"

const Loading = ({ option }) => {
    return option?.show &&
        <div className="most-top position-fixed top-0 start-0 end-0 bottom-0 bg-white bg-opacity-25 d-flex align-items-center justify-content-center">
            <div className="py-4 d-flex justify-content-center align-items-center rounded-0 bg-white bg-opacity-25 text-success">
                {<ImSpinner10 className="spin font-size-72 mx-4" />}
                {/* {option?.title ? option?.title : "Loading.."} */}
            </div>
        </div>
}
export default Loading