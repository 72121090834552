import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useCommon } from "../../../hooks/useCommon";
import { useApi } from "../../../hooks/useApi";
import useAlert from "../../../hooks/useAlert";

const AdminModifyShortsOption = ({ shorts, onUpdate }) => {
  const [data, setData] = useState({})
  const [show, setShow] = useState(false);
  const cm = useCommon();
  const api = useApi();
  const alert = useAlert();

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    setData({ title: shorts?.title, youtube_link: shorts?.youtube_link, is_deleted: shorts?.is_deleted });
  }, [show])

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "is_deleted") {
      setData({ ...data, [name]: value === "true" ? true : false })
    } else {
      setData({ ...data, [name]: value })
    }
  }

  const modifyShorts = async () => {
    try {
      cm.showLoader(true)
      const res = await api.updateShorts(shorts?.id, data)
      if (res) {
        alert.show(cm?.translate("Shorts was updated"), "success")
        cm.showLoader(false);

        onUpdate(res);
        handleClose();

      }
    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error")
      cm.showLoader(false)
    }
  };

  return (
    <>
      <button className="btn btn-sm btn-outline-dark  border ms-1" onClick={handleShow}>Modify</button>
      <Modal size="lg" scrollable="true" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center"><img src={process.env.PUBLIC_URL + "/static/shorts.png"} height={30} alt="shorts_logo" className="" /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <div className="row m-0 mb-1">
            <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1 position-relative">
              {cm?.translate("Title")}
            </div>
            <div className="col py-1">
              <input name="title" className="form-control" value={data?.title} onChange={handleChange}></input>
            </div>
          </div>
          <div className="row m-0 mb-1">
            <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1 position-relative">
              {cm?.translate("YouTube Link")}
            </div>
            <div className="col py-1">
              <input name="youtube_link" className="form-control" value={data?.youtube_link} onChange={handleChange}></input>
            </div>
          </div>
          <div className="row m-0 mb-1">
            <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1 position-relative">
              {cm?.translate("Activation")}
            </div>
            <div className="col py-1">
              <select name="is_deleted" className="form-select" value={data?.is_deleted} onChange={handleChange}>
                <option value={false}>Active</option>
                <option value={true}>Suspend</option>
              </select>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-success" onClick={modifyShorts}
          >
            {cm?.translate("Save")}
          </button>
          <button
            className="btn btn-sm btn-secondary" onClick={handleClose}
          >
            {cm?.translate("Close")}
          </button>
        </Modal.Footer>
      </Modal >
    </>
  );
}
export default AdminModifyShortsOption