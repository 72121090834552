import Swal from "sweetalert2";

const useAlert = () => {
    const show = (message, type) => {
        Swal.fire({
            html: message, icon: type,
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-secondary ms-2",
            }, buttonsStyling: false
        })
    };

    const showThenRedirect = (message, type, url) => {
        Swal.fire({
            html: message, icon: type,
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-secondary ms-2",
            }, buttonsStyling: false
        }).then((result) => {
            if (result.isConfirmed) {
                window.location = url;
            }
        })
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });


    const toast = (message, type) => {
        Toast.fire({
            icon: type,
            title: message
        });
    };

    const splash = (message, type) => {
        Swal.fire({
            position: "center",
            icon: type,
            html: message,
            showConfirmButton: false,
            timer: 15000
        });
    }

    return { show, showThenRedirect, toast, splash };
}

export default useAlert;