import { useEffect, useState } from "react";
import { useCommon } from "../../../hooks/useCommon"
import PostTemplate from "../../PostTemplate"
import { useApi } from "../../../hooks/useApi";
import useAlert from "../../../hooks/useAlert";

const OrderNowConfirm = ({ post, order, onConfirm, onCancel }) => {
    const [isValid, setIsValid] = useState(false)
    const [verifyCode, setVerifyCode] = useState("")
    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();

    const handleCancel = (e) => {
        onCancel(false);
    };

    useEffect(() => {
        setVerifyCode(order?.order_no.split("-")[1])
    }, [])

    const handleCodeInput = (e) => {
        if (e.target.value === verifyCode) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }

    const handleConfirm = async (e) => {
        try {
            cm?.showLoader(true, "Confirming Order..")
            const res = await api.updateOrder(order?.order_no);
            if (res) {
                onConfirm()
                cm?.showLoader(false);
                alert.show(cm?.translate(`Order No. ${res?.order_no} <br/><br/>Your order was placed successfully.`), "success")
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm?.showLoader(false)
        }
    }


    return <>

        <div className="bg-secondary bg-opacity-10 mx-0 row">
            <div className="col-sm-12 p-3">
                <div className="d-flex justify-content-between align-items-center bg-white rounded p-2 mb-1">
                    <span className="fs-6 fw-semibold text-dark">{cm?.translate("Quantity")}</span>
                    <span className="fs-6 fw-semibold text-dark">{order?.quantity} {order?.serving_unit}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center bg-white rounded p-2 mb-1">
                    <span className="fs-6 text-dark">{cm?.translate("Total Price")}</span>
                    <span className="fs-6 text-dark"> {order?.unit_price + " " + post?.pricing?.currency}</span>
                </div>
                {order?.tax > 0 && <div className="d-flex justify-content-between align-items-center bg-white rounded p-2 mb-1">
                    <span className="fs-6 text-dark">{cm?.translate("Tax")}</span>
                    <span className="fs-6 text-dark"> {order?.tax + " " + post?.pricing?.currency}</span>
                </div>}
                {order?.delivery_charge > 0 && <div className="d-flex justify-content-between align-items-center bg-white rounded p-2 mb-1">
                    <span className="fs-6 text-dark">{cm?.translate("Delivery Charge")}</span>
                    <span className="fs-6 text-dark"> {order?.delivery_charge + " " + post?.pricing?.currency}</span>
                </div>}
                <div className="d-flex justify-content-between align-items-center bg-danger bg-opacity-25 rounded p-2 mb-1">
                    <span className="fs-6 text-dark fw-semibold">{cm?.translate("Total Pay")}</span>
                    <span className="fs-6 text-danger fw-semibold"> {order?.total_pay + " " + post?.pricing?.currency}</span>
                </div>
            </div>
        </div>
        <div className="mt-3">
            <h1 className="border text-center text-danger p-2 rounded bg-light border">{verifyCode}</h1>
            <input type="text" className="mt-3 form-control fs-4 text-center" autoFocus={true} onChange={handleCodeInput}></input>
            <span className="d-block text-center font-size-10 text-secondary mt-1">{isValid ? cm?.translate("Code Matched") : cm?.translate("Type Above Code")}</span>
        </div>
        <div className="mt-4 border-top pt-3 d-flex justify-content-end">
            <button
                className="ms-2 btn btn-sm btn-outline-dark me-2"
                onClick={handleCancel}
            >
                {cm?.translate("Cancel")}
            </button>
            <button
                disabled={!isValid}
                className="btn btn-sm btn-success"
                onClick={handleConfirm}
            >
                {cm?.translate("Confirm Order")}
            </button>

        </div>
    </>
}
export default OrderNowConfirm