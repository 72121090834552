import { useEffect, useState } from "react";
import useAlert from "../../hooks/useAlert";
import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import { useCommon } from "../../hooks/useCommon"
import ImageUploader from "../ImageUploader"
import PreviewMediaFile from "../PreviewMediaFile";
import useRenewAuth from "../../hooks/useRenewAuth";
import CoinTransaction from "../modals/CoinTransaction";
import BuyCoins from "../modals/BuyCoins";

const BusinessProfile = () => {
    const [disableSaving, setDisableSaving] = useState(false)
    const [profile, setProfile] = useState({})

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();
    const auth = useAuth();
    const renewAuth = useRenewAuth()

    useEffect(() => {
        setProfile({
            alias_id: auth?.authUser?.alias_id,
            email_or_phone: auth?.authUser?.email_or_phone,
            is_superuser: auth?.authUser?.is_superuser,
            is_active: auth?.authUser?.is_active,
            user_business_profile: auth?.authUser?.user_business_profile || {},
        })
    }, [])

    // useEffect(() => {
    //     console.log(profile?.user_business_profile)
    // }, [profile])


    const handleUploadLogoImage = (e) => {
        let up = { ...profile?.user_business_profile }
        up["changed_company_logo"] = e[0]?.base64;
        setProfile({ ...profile, user_business_profile: up })
    }
    const handleUploadIdImage = (e) => {
        let up = { ...profile?.user_business_profile }
        up["changed_identification_doc"] = e[0]?.base64;
        setProfile({ ...profile, user_business_profile: up })
    }

    const handleFormInput = (e) => {
        const { name, value } = e.target;

        setProfile((currVal) => {

            let up = { ...currVal?.user_business_profile }

            if (name === "location") {
                up = {
                    ...up,
                    [name]: cm?.locations?.find((l) => l.id === parseInt(value)),
                };
            }
            else {
                up = { ...up, [name]: value };
            }

            const updateValue = { ...currVal, user_business_profile: up };

            if ((updateValue?.user_business_profile?.company_name && updateValue?.user_business_profile?.location && updateValue?.user_business_profile?.company_phone && updateValue?.user_business_profile?.address) && ("identification_doc" in updateValue?.user_business_profile)) {
                setDisableSaving(false)
            } else {
                setDisableSaving(true)
            }

            return updateValue;
        });

    };

    const renderLocation = () => {
        return cm?.locations?.map((l) =>
            <option key={"loc_" + l.id} value={l.id}>{l.name}</option>
        )
    }

    const removeCompanyLogo = () => {
        let up = { ...profile?.user_business_profile, company_logo: "", deleted_company_logo: profile?.user_business_profile?.company_logo }
        delete up?.changed_company_logo;
        setProfile({ ...profile, user_business_profile: up });
    };

    const removeIdentificationDoc = () => {
        let up = { ...profile?.user_business_profile, identification_doc: "", deleted_identification_doc: profile?.user_business_profile?.identification_doc }
        delete up?.changed_identification_doc;
        setProfile({ ...profile, user_business_profile: up });
    };

    const handleSave = async (vProfile) => {
        try {
            cm?.showLoader(true)
            let res = {}
            if ("user_business_profile" in auth?.authUser && Object.keys(auth?.authUser?.user_business_profile).length) {
                res = await api.updateBusinessProfile(auth.authUser?.alias_id, vProfile?.user_business_profile || profile?.user_business_profile);
            } else {
                res = await api.createBusinessProfile(vProfile?.user_business_profile || profile?.user_business_profile);
            }

            if (res) {
                setProfile({ ...profile, user_business_profile: res })
                renewAuth.renew();
                cm?.showLoader(false);
                alert.toast(cm?.translate("Business profile was saved"), "success")

                return true;
            }

        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err?.msg || err, "error")
            return false
        }
    };


    const activateBusinessProfile = (e) => {
        const ubp = { ...profile?.user_business_profile, is_active: true }
        const vProfile = { ...profile, user_business_profile: ubp }
        setProfile(vProfile)
    }

    const deactivateBusinessProfile = (e) => {
        const ubp = { ...profile?.user_business_profile, is_active: false }
        const vProfile = { ...profile, user_business_profile: ubp }
        setProfile(vProfile)
    }

    const profileStatus = () => {
        if (profile?.user_business_profile?.is_approved && profile?.user_business_profile?.is_active) {
            return <button className="btn btn-sm btn-success rounded-pill px-3" onClick={e => deactivateBusinessProfile(e)}>{cm?.translate("Active")}</button>
        } else if (!profile?.user_business_profile?.is_approved) {
            return <span className="text-danger font-size-11">{cm?.translate("Under review by admin. You will be informed when it is approved.")}</span>
        } else if (!profile?.user_business_profile?.is_active) {
            return <div><button className="btn btn-sm btn-secondary rounded-pill px-3" onClick={e => activateBusinessProfile(e)}>{cm?.translate("Inactive")}</button><span className="text-secondary font-size-9 ms-2">{cm.translate("Click to active")}</span></div>
        } else {
            return <span>{cm?.translate("Update your business profile")}</span>
        }
    }

    return <div className="position-relative">
        <div className="row m-0 p-2 pt-3 pb-4 mb-2 border-secondary border-opacity-10">
            <div className="ol-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("What is Business Profile?")}</span>
                <div className="mt-3 d-flex justify-content-start align-items-center">
                    <p className="my-2 my-md-0 font-size-10">{cm?.translate("If you have a company or shop, you can activate a business profile. Individual users can still sell without a business profile. However, for verification purposes, users must upload a National ID, trade license, or any government-approved valid document. A business profile helps sellers build trust with buyers and strengthen their brand.")}</p>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">

            </div>
        </div>
        <div className="row m-0 p-2 pt-3 pb-4 mb-2 border-top border-secondary border-opacity-10">
            <div className="ol-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Status")}</span>
                <div className="mt-3 d-flex justify-content-start align-items-center">
                    {profileStatus()}
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("It reflects the current status of Business profile. User can active or deactive after it is approved by Monozuna. It needs approval of every changes and save in business profile. Usually it takes 15 minutes to 1 week to verify updated information.")}</p>
            </div>
        </div>


        <div className="row m-0 p-2 pt-3 pb-4 mb-2 border-top border-secondary border-opacity-10">
            <div className="ol-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Logo")}</span>
                <div className="mt-3 d-flex flex-column">
                    {(profile?.user_business_profile?.changed_company_logo || profile?.user_business_profile?.company_logo) && <PreviewMediaFile
                        src={
                            profile?.user_business_profile?.changed_company_logo || profile?.user_business_profile?.company_logo
                        }
                        removeFromPreview={removeCompanyLogo}
                    />}
                    <div className="mt-1">
                        <ImageUploader
                            setNewFiles={handleUploadLogoImage}
                            label={profile?.user_business_profile?.company_logo ? cm?.translate("Change") : cm?.translate("Upload")}
                            multipleUpload="false"
                            width="auto"
                            clearPreview={true}
                        />
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Logo will grow your brand trust.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Coin")}</span>
                <div className="mt-3 d-flex">
                    <CoinTransaction />
                    <div className="ms-2">
                        <BuyCoins />
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Coin is equivelent to Taka. You can purchase premium features using coin. You can buy coins clicking Buy Coins button.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Company Name")}</span>
                <div className="mt-3 ">
                    <input
                        type="text"
                        name="company_name"
                        className="form-control"
                        value={profile?.user_business_profile?.company_name || ""}
                        onChange={handleFormInput}
                        required={true}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Company name or your personal name is required. This name will be visible to user's Ad. post.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Address")}</span>
                <div className="mt-3 ">
                    <textarea
                        type="text"
                        rows={2}
                        name="address"
                        value={profile?.user_business_profile?.address || ""}
                        className="form-control"
                        onChange={handleFormInput}
                    ></textarea>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Correct address is important to reach user. It is required to write in detail.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Post Code")}</span>
                <div className="mt-3 ">
                    <input
                        type="text"
                        name="post_code"
                        className="form-control"
                        value={profile?.user_business_profile?.post_code || ""}
                        onChange={handleFormInput}
                        required={true}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Although it is optional but it will help the delivery quickly.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Location")}</span>
                <div className="mt-3 ">
                    <select
                        name="location"
                        className="form-select"
                        value={profile?.user_business_profile?.location?.id || ""}
                        onChange={handleFormInput}
                    >
                        <option value="">{cm?.translate("Select Location")}</option>
                        {renderLocation()}
                    </select>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("It is required so that users' post can be sorted quickly and user may get preference Ads.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Business Email")}</span>
                <div className="mt-3 ">
                    <input
                        type="text"
                        name="company_email"
                        className="form-control"
                        value={profile?.user_business_profile?.company_email || ""}
                        onChange={handleFormInput}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("It is optional but it will help user to communicate easily.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Business Phone")}</span>
                <div className="mt-3 ">
                    <input
                        type="text"
                        name="company_phone"
                        className="form-control"
                        value={profile?.user_business_profile?.company_phone || ""}
                        onChange={handleFormInput}
                        required={true}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("It is required and without valid phone number business account will not active.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Website Link")}</span>
                <div className="mt-3 ">
                    <input
                        type="text"
                        name="web_link"
                        className="form-control"
                        value={profile?.user_business_profile?.web_link || ""}
                        onChange={handleFormInput}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("It is not required but people may reach to product and service easily.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 mb-2 border-top border-secondary border-opacity-10">
            <div className="ol-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Identification Document")}</span>
                <div className="mt-3 d-flex flex-column">
                    {(profile?.user_business_profile?.changed_identification_doc || profile?.user_business_profile?.identification_doc) && <PreviewMediaFile
                        src={
                            profile?.user_business_profile?.changed_identification_doc || profile?.user_business_profile?.identification_doc
                        }
                        removeFromPreview={removeIdentificationDoc}
                        height={72} width={72}
                    />}
                    <div className="mt-1">
                        <ImageUploader
                            setNewFiles={handleUploadIdImage}
                            label={profile?.user_business_profile?.identification_doc ? cm?.translate("Change") : cm?.translate("Upload")}
                            multipleUpload="false"
                            width="auto"
                            maxSize={2000000}
                            clearPreview={true}
                        />
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Identification document is essential. As a proof user can upload image of NID card or Passport or any other valid document recognised by Bangladesh government. Any image format inlcuding .jpg, .png, .webp etc. are allowed. Maximum 2MB size is allowed.")}</p>
            </div>
        </div>

        <div className="row m-0 mt-2 p-3 bg-light">
            <div className="col d-flex justify-content-center align-items-center">
                <button className="btn btn-sm btn-success" disabled={disableSaving} onClick={handleSave}>{cm?.translate("Save Changes")}</button>
            </div>
        </div>

    </div>

}
export default BusinessProfile