import { GrNext, GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";

const CreateAdStepSix = ({ navigateToStep, setNavigateToStep }) => {
    const cm = useCommon();
    const auth = useAuth();

    const units = [
        "Per Item",
        "Per Accessory", "Per Acre", "Per Ampoule", "Per Apartment", "Per Bag", "Per Barrel",
        "Per Batch", "Per Battery", "Per Battery Pack", "Per Bottle", "Per Box",
        "Per Brand", "Per Building", "Per Bundle", "Per CPU",
        "Per Can", "Per Capsule", "Per Carton", "Per Cartridge", "Per Case", "Per Charger", "Per Coil",
        "Per Collection", "Per Color", "Per Component", "Per Cube", "Per Design",
        "Per Desktop", "Per Device", "Per Dozen", "Per Drum",
        "Per Estate", "Per Floor", "Per Foot",
        "Per Gallon", "Per Garment", "Per Handset", "Per Hanger", "Per Head", "Per Hectare",
        "Per House", "Per Jar", "Per Keyboard", "Per Keyboard Layout", "Per Kilogram (kg)",
        "Per Laptop", "Per Lease", "Per Length", "Per Liter (L)",
        "Per Lot", "Per Meter", "Per Model", "Per Monitor", "Per Mouse", "Per Pack",
        "Per Pad", "Per Pair", "Per Pallet", "Per Parcel", "Per Pattern", "Per Piece",
        "Per Plot", "Per Pound (lb)",
        "Per Property", "Per Rack", "Per Roll", "Per Room",
        "Per SKU", "Per Sachet", "Per Screen", "Per Serial Number", "Per Set", "Per Sheet",
        "Per Size", "Per Slice", "Per Software License", "Per Square Foot", "Per Square Meter",
        "Per Style", "Per Survey", "Per Syringe", "Per Tablet",
        "Per Tray", "Per Tube", "Per Unit", "Per Unit Load", "Per Vial", "Per Warranty", "Per Zone", "Per Vehicle"
    ];

    const handleInput = (e) => {
        const pricing = { ...cm?.createAdData?.pricing, currency: "Taka", [e?.target?.name]: e?.target?.value }
        cm?.setCreateAdData({ ...cm?.createAdData, pricing: pricing })
    }

    const handleNext = () => {
        setNavigateToStep(navigateToStep + 1)
    }

    const handlePrev = () => {
        setNavigateToStep(navigateToStep - 1)
    }

    useEffect(() => {
        cm?.setCreateAdData({ ...cm?.createAdData, location: cm?.createAdData?.location || auth?.user_business_profile?.location?.id || cm?.locations?.find(fl => fl?.name === "Dhaka")?.id })
    }, [])

    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Pricing")}</h4>
        <div className="m-0 mt-4 mb-5 px-3 text-start">
            <div className="row m-0 p-2 pb-4">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Price and Unit")}</span>
                    <div className="mt-3 d-flex">
                        <input type="number" min={0} className="form-control flex-fill" name="unitPrice" required placeholder={cm?.translate("Price in Taka")} onChange={e => handleInput(e)} value={cm?.createAdData?.pricing?.unitPrice || ""}></input>
                        <select name="servingUnit" className="form-select ms-2" value={cm?.createAdData?.pricing?.servingUnit || ""} onChange={(e) => handleInput(e)}>
                            {units?.map((u, i) => <option key={"unit_" + i} value={u}>{cm?.translate(u)}</option>)}
                        </select>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9">{cm?.translate("Include the unit price to display it in ad and unit name. Buyers can place orders based on this pricing.")}</p>
                </div>
            </div>

            <div className="row m-0 p-2 pb-4">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Tax Rate")}</span>
                    <div className="mt-3">
                        <input type="number" min={0} className="form-control" name="tax" required placeholder={cm?.translate("% of Tax")} onChange={e => handleInput(e)} value={cm?.createAdData?.pricing?.tax || ""}></input>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9">{cm?.translate("Specify the percentage of tax to be applied if applicable.")}</p>
                </div>
            </div>

            <div className="row m-0 p-2 pb-4">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Delivery Charge")}</span>
                    <div className="mt-3">
                        <input type="number" min={0} className="form-control" name="deliveryCharge" required placeholder={cm?.translate(`Delivery charge in Taka`)} onChange={e => handleInput(e)} value={cm?.createAdData?.pricing?.deliveryCharge || ""}></input>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9">{cm?.translate("The specified delivery charge will be displayed when a buyer places an order.")}</p>
                </div>
            </div>

            <div className="row m-0 p-2 pb-4">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Additional Instruction or Note")}</span>
                    <div className="mt-3">
                        <textarea className="form-control" name="orderRelatedInfo" placeholder={cm?.translate("Detail and additional info from seller")} onChange={e => handleInput(e)} value={cm?.createAdData?.pricing?.orderRelatedInfo} />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9">{cm?.translate("Sellers can include essential information to be displayed when the buyer places an order. This ensures that buyers are well-informed and can proceed with a clear understanding.")}</p>
                </div>
            </div>

            <div className="row m-0 p-2 pb-4">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Notification to Email")}</span>
                    <div className="mt-3">
                        <input className="form-control" name="notificationEmail" placeholder={cm?.translate("Email address")} onChange={e => handleInput(e)} value={cm?.createAdData?.pricing?.notificationEmail} />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9">{cm?.translate("Sellers will be notified through email address for buyers' order")}</p>
                </div>
            </div>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleNext}>{cm?.translate("Next")} <GrNext className="fs-6 ms-2" /></button>
        </div>
    </div>
}
export default CreateAdStepSix