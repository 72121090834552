import { GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"
import { useState } from "react";
import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import PasswordRecovery from "../modals/PasswordRecovery";

const QuickSignIn = ({ navigateToStep, setNavigateToStep }) => {
    const [user, setUser] = useState({})
    const cm = useCommon();
    const api = useApi();
    const auth = useAuth();
    const alert = useAlert();

    const handleInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const handlePrev = () => {
        setNavigateToStep(8)
    }

    const handleForgetPassword = () => {
        setNavigateToStep(navigateToStep + 1)
    }

    const handleSubmit = async () => {
        try {
            cm?.showLoader(true)
            const res = await api.loginAuth(user?.email_or_phone, user?.password)
            if (res) {
                auth.login(res);
                alert.toast("You have logged in", "success");
                setNavigateToStep("final")
                cm?.showLoader(false)
            }
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err, "error");
        }

    }


    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Sign In")}</h4>
        <div className="m-0 mt-4 my-5 px-3 text-center">
            <div className="w-sm-100-md-50 mx-auto">
                <input className="form-control" name="email_or_phone" placeholder={cm?.translate("Email or Phone")} onChange={e => handleInput(e)}></input>
                <input className="form-control mt-2" type="password" name="password" placeholder={cm?.translate("Password")} onChange={e => handleInput(e)}></input>
            </div>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button disabled={!user?.email_or_phone || !user?.password} className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleSubmit}>{cm?.translate("Login")}</button>
        </div>
    </div>

}
export default QuickSignIn 