import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { useCommon } from "../hooks/useCommon";

const Search = ({ disable }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const cm = useCommon();

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            if (e.target.value) {
                navigate("/ads/?search=" + e.target.value)
            } else {
                navigate("/ads")
            }
            setShow(false);
        }
    }

    return <>
        <button disabled={disable} onClick={handleShow} className="btn btn-outline-dark border-0 not-focusable rounded-1 d-flex justify-content-center align-items-center">
            <BsSearch className="fs-4" />
            <span className="d-inline-block ms-0 ms-md-2">{cm?.translate("Search")}</span>
        </button>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Form.Control
                    type="search"
                    placeholder={cm?.translate("Type your keyword and press enter")}
                    autoFocus
                    onKeyUp={(e) => handleSearch(e)}
                />
            </Modal.Body>
        </Modal>
    </>
}
export default Search;