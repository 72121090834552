import { useEffect, useState } from "react";
import useAlert from "../../hooks/useAlert";
import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import { useCommon } from "../../hooks/useCommon"
import ImageUploader from "../ImageUploader"
import PreviewMediaFile from "../PreviewMediaFile";

const GeneralProfile = () => {
    const [profile, setProfile] = useState({})
    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();
    const auth = useAuth();

    useEffect(() => {
        setProfile({
            alias_id: auth?.authUser?.alias_id,
            email_or_phone: auth?.authUser?.email_or_phone,
            is_superuser: auth?.authUser?.is_superuser,
            is_active: auth?.authUser?.is_active,
            user_profile: auth?.authUser?.user_profile,
        })
    }, [])

    const handleUploadProfileImage = (e) => {
        let up = { ...profile?.user_profile }
        up["changed_profile_image"] = e[0]?.base64;
        setProfile({ ...profile, user_profile: up })
    }

    const handleFormInput = (e) => {
        const { name, value } = e.target;
        let up = { ...profile?.user_profile }

        if (name === "email_or_phone" && auth?.authUser.email_or_phone.includes("@")) {
            if (/^\d+$/.test(value) || value === "") {
                up = { ...up, [name]: value };
            }
        }
        else {
            up = { ...up, [name]: value };
        }
        setProfile({ ...profile, user_profile: up });
    };

    const removeProfilePhoto = () => {
        let up = { ...profile?.user_profile, profile_image: "", deleted_profile_image: profile?.user_profile?.profile_image }
        delete up?.changed_profile_image;
        setProfile({ ...profile, user_profile: up });
    };

    const handleSave = async () => {
        try {
            cm?.showLoader(true, "Saving..")

            const res = await api.updateUserProfile(auth.authUser?.alias_id, profile?.user_profile);
            if (res) {
                const au = { ...auth.authUser, user_profile: res };
                auth.updateAuthUser(au);
                setProfile({ ...profile, user_profile: res })
                alert.toast(cm?.translate("General Profile was saved"), "success")
                cm?.showLoader(false)
                return true;
            }

        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err?.msg || err, "error")
            return false
        }
    };

    return <div>
        <div className="row m-0 p-2 pt-3 pb-4 border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="text-danger fw-semibold">{cm?.translate("Login Id")}</span>
                <div className="mt-3">
                    <span>{auth?.authUser?.email_or_phone}</span>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Login Id is unchangable.")}</p>
            </div>
        </div>
        <div className="row m-0 p-2 pt-3 pb-4 mb-2 border-top border-secondary border-opacity-10">
            <div className="ol-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Profile Photo")}</span>
                <div className="mt-3 d-flex flex-column">
                    {(profile?.user_profile?.changed_profile_image || profile?.user_profile?.profile_image) && <PreviewMediaFile
                        src={
                            profile?.user_profile?.changed_profile_image || profile?.user_profile?.profile_image
                        }
                        removeFromPreview={removeProfilePhoto}
                        height={100}
                        width={100}
                    />}
                    <div className="mt-1 d-flex">
                        <ImageUploader
                            setNewFiles={handleUploadProfileImage}
                            label={profile?.user_profile?.profile_image ? cm?.translate("Change") : cm?.translate("Select")}
                            multipleUpload="false"
                            width="auto"
                            clearPreview={true}
                        />
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm?.translate("Profile image will grow your trust. So upload actual photo.")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10 border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Name")}</span>
                <div className="mt-3">
                    <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={profile?.user_profile?.name || ""}
                        onChange={handleFormInput}
                        required={true}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9"></p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10 border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Gender")}</span>
                <div className="mt-3">
                    <select
                        name="gender"
                        className="form-select"
                        value={profile?.user_profile?.gender || ""}
                        onChange={handleFormInput}
                    >
                        <option value="">{cm?.translate("Select Gender")}</option>
                        <option value="m">{cm?.translate("Male")}</option>
                        <option value="f">{cm?.translate("Female")}</option>
                        <option value="o">{cm?.translate("Other")}</option>
                    </select>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9"></p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{cm?.translate("Date of Birth")}</span>
                <div className="mt-3">
                    <input
                        type="date"
                        name="dob"
                        className="form-control"
                        value={profile?.user_profile?.dob || ""}
                        onChange={handleFormInput}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9"></p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">{auth.authUser?.email_or_phone?.includes("@") ?
                    <>
                        <span>{cm?.translate("Phone")}</span>
                    </>
                    : <span>{cm?.translate("Email")}</span>
                }</span>
                <div className="mt-3">
                    <input
                        type="text"
                        name="email_or_phone"
                        maxLength={auth.authUser?.email_or_phone?.includes("@") ? 14 : 100}
                        value={profile?.user_profile?.email_or_phone || ""}
                        className="form-control"
                        onChange={handleFormInput}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm.translate("Space or special character is not allowed in phone number")}</p>
            </div>
        </div>

        <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
            <div className="col-sm-12 col-md-8">
                <span className="fw-semibold">
                    {cm?.translate("Address")}
                </span>
                <div className="mt-3">
                    <textarea
                        type="text"
                        rows={2}
                        name="address"
                        value={profile?.user_profile?.address || ""}
                        className="form-control"
                        onChange={handleFormInput}
                    ></textarea>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 d-flex align-items-start">
                <p className="my-2 my-md-0 font-size-9">{cm.translate("Space or special character is not allowed in phone number")}</p>
            </div>
        </div>

        <div className="row m-0 mt-2 p-3 bg-light">
            <div className="col d-flex justify-content-center align-items-center">
                <button className="btn btn-sm btn-success" onClick={handleSave}>{cm?.translate("Save Changes")}</button>
            </div>
        </div>
    </div>

}
export default GeneralProfile