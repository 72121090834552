import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";


const AdminSendSms = () => {
    const [messageData, setMessageData] = useState();

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();

    useEffect(() => {
        setMessageData({
            receiver: "",
            message: "",
        })
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setMessageData({ ...messageData, [name]: value })
    }

    const handleSend = async (e) => {
        try {
            cm.showLoader(true, "Sending..");
            const data = { ...messageData, message: messageData?.message }

            const res = await api.sendAdminSms(data);
            if (res) {
                alert.show("Message was sent", "success");

            }
            setMessageData({});
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    };

    return <div className="col-sm-12">
        <div className="row m-0 mb-1">
            <div className={"col-sm-12 col-md-3 d-flex align-items-center bg-light py-1 position-relative"}>
                {cm?.translate("To")}
            </div>
            <div className="col d-flex align-items-center py-1">
                <textarea name="receiver" placeholder={cm?.translate("Receiver")} className="form-control" onChange={handleChange} rows={1} value={messageData?.receiver || ""}></textarea>
            </div>
        </div>
        <div className="row m-0 mb-1">
            <div className={"col-sm-12 col-md-3 d-flex align-items-start bg-light py-1 position-relative"}>
                {cm?.translate("Message")}
            </div>
            <div className="col d-flex align-items-center py-1">
                <textarea name="message" placeholder={cm?.translate("Write your message")} className="form-control" rows={5} onChange={handleChange} value={messageData?.message || ""}></textarea>
            </div>
        </div>
        <div className="row m-0 mt-4 mb-1">
            <button className="btn btn-success rounded" onClick={handleSend}>Send</button>
        </div>
    </div>
};
export default AdminSendSms;
