import { useState } from "react";
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";


const AdminAutoUserCreate = () => {
    const [phoneNumbers, setPhoneNumbers] = useState("")

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();

    const handleChange = (e) => {
        setPhoneNumbers(e.target.value)
    }

    const handleCreate = async (e) => {
        const numbers = phoneNumbers.split(";")
        let members = []
        numbers.forEach(element => {
            const phoneName = element.split(",")
            if (phoneName.length > 1) {
                members.push({ phone: phoneName[0].trim(), name: phoneName[1].trim() })
            }
        });

        try {
            cm.showLoader(true, "Creating..");
            const res = await api.createAutoUsers({ "phone_numbers": members });
            if (res) {
                alert.show(res?.msg, "success");
                setPhoneNumbers("");
                cm.showLoader(false);
            }
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    };

    return <div className="col-sm-12">
        <div className="row m-0 mb-1">
            <div className={"col-sm-12 col-md-3 d-flex align-items-center bg-light py-1 position-relative"}>
                {cm?.translate("Phone Number and Name")}
            </div>
            <div className="col d-flex align-items-center py-1">
                <textarea name="phone" placeholder={"Phone Number, Name;"} className="form-control" onChange={handleChange} rows={6} value={phoneNumbers || ""}></textarea>
            </div>
        </div>
        <div className="row m-0 mt-4 mb-1">
            <button className="btn btn-success rounded" disabled={phoneNumbers === ""} onClick={handleCreate}>Create</button>
        </div>
    </div>
};
export default AdminAutoUserCreate;
