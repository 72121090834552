import { Modal } from "react-bootstrap";
import { useState, useRef } from "react";
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import OrderNowInput from "./ordernow/OrderNowInput";
import OrderNowConfirm from "./ordernow/OrderNowConfirm";
import useRenewAuth from "../../hooks/useRenewAuth";

const OrderNow = ({ post }) => {
  const [show, setShow] = useState(false);
  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const cm = useCommon();
  const auth = useAuth();
  const order = useRef({})
  const renewAuth = useRenewAuth();

  const handleClose = () => {
    setShow(false);
    setShowConfirmOrder(false)
    order.current = ""
  };

  const handleShow = () => {
    setShow(true);
  };

  const onPlaceOrder = (o) => {
    setShowConfirmOrder(true)
    order.current = o
  }

  const onConfirm = () => {
    setShowConfirmOrder(false)
    setShow(false)
    renewAuth.renew();
  }


  return (
    <>
      <button
        onClick={handleShow}
        className="btn btn-sm btn-dark rounded-pill px-3"
        disabled={!auth.authUser}
      >
        {auth.authUser ? cm?.translate("Order Now") : cm?.translate("Login to send order")}
      </button>
      <Modal size="lg" backdrop="static" scrollable="true" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{cm?.translate("Order Now")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          {show === true && showConfirmOrder === false && <OrderNowInput post={post} onPlaceOrder={onPlaceOrder} onCancel={handleClose} />}
          {showConfirmOrder && <OrderNowConfirm post={post} order={order?.current} onConfirm={onConfirm} onCancel={handleClose} />}
        </Modal.Body>
      </Modal >
    </>
  );
}
export default OrderNow