import { GrNext, GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"

const CreateAdStepFive = ({ navigateToStep, setNavigateToStep }) => {
    const cm = useCommon();

    const handleInput = (e) => {
        cm?.setCreateAdData({ ...cm?.createAdData, [e?.target?.name]: e?.target?.value })
    }

    const handleNext = () => {
        if (cm?.createAdData?.categoryGroup?.name?.includes("Product") || cm?.createAdData?.categoryGroup?.name?.includes("Travel")) {
            setNavigateToStep(navigateToStep + 1)
        } else {
            setNavigateToStep(navigateToStep + 2)
        }
    }

    const handlePrev = () => {
        setNavigateToStep(navigateToStep - 1)
    }

    const genAiDescription = async () => {
        try {
            cm?.setCreateAdData({ ...cm?.createAdData, description: cm?.categories?.find(c => c?.id === cm?.createAdData?.category)?.template || "" })
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };


    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Title and Description")}</h4>
        <div className="m-0 mt-4 mb-5 px-3">
            <p className="mb-4 font-size-9 text-start">{cm?.translate("Monozuna recommends a simple yet appealing title to capture buyers' attention. At the same time, seller should provide accurate and detailed information about the item, as a comprehensive description helps buyers understand the product more easily. Seller also have the option to generate a pre-formatted template for convenience.")}</p>
            <input type="text" name="title" placeholder={cm?.translate("Title")} className="form-control font-size-12" value={cm?.createAdData?.title || ""} onChange={e => handleInput(e)} />
            <textarea name="description" rows="5" className="form-control mt-3 font-size-12" placeholder={cm?.translate("Description")} value={cm?.createAdData?.description || ""} onChange={e => handleInput(e)}></textarea>
            <div className="d-flex justify-content-end w-100">
                <button className="btn btn-sm btn-light self-align-start  mt-1 font-size-8" onClick={genAiDescription}>{cm?.translate("Generate Template")}</button>
            </div>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button disabled={!cm?.createAdData?.title || !cm?.createAdData?.description} className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleNext}>{cm?.translate("Next")} <GrNext className="fs-6 ms-2" /></button>
        </div>
    </div>
}
export default CreateAdStepFive