import { useEffect, useState } from "react";
import { useCommon } from "../../../hooks/useCommon"
import { useApi } from "../../../hooks/useApi"
import useAlert from "../../../hooks/useAlert"
import { Modal } from "react-bootstrap";
import { BsCardImage } from "react-icons/bs";
import { GrTextWrap } from "react-icons/gr";
import BuyCoins from "../BuyCoins";

const SetToTopAd = ({ post, setPost }) => {
    const [show, setShow] = useState(false);
    const [owner, setOwner] = useState();
    const [duration, setDuration] = useState(1);
    const [selectedImage, setSelectedImage] = useState();
    const [type, setType] = useState("image-only");

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();
    const perDayCost = 25;


    const handleClose = () => {
        setShow(false)
        setDuration(1)
    }

    const checkCoinsToSubscribe = (expectedDuration = 1, ownersCoins) => {
        if (expectedDuration < 1) {
            return 1;
        }
        if (expectedDuration * perDayCost > ownersCoins) {
            alert.show("Not enough coins!", "info");
            return parseInt(ownersCoins / perDayCost);
        }
        return expectedDuration
    }

    // useEffect(() => {
    //     const exDuration = checkCoinsToSubscribe(duration, owner?.coins)
    //     setDuration()
    // }, [duration])

    const updateDuration = (value) => {
        const exDuration = checkCoinsToSubscribe(value, owner?.coins)
        setDuration(exDuration)
    }


    const handleShow = () => {
        setShow(true);
    };

    const getOwnerDetail = async () => {
        try {
            cm?.showLoader(true)
            const res = await api.getUserDetail(post?.owner?.alias_id);
            if (res) {
                setOwner(res)
            }
            cm?.showLoader(false)
        } catch (err) {
            cm?.showLoader(true)
            alert.show(err?.message, "error")
        }
    };

    useEffect(() => {
        show && getOwnerDetail()
        show && updateDuration(1)
    }, [show])

    useEffect(() => {
        if (owner?.coins < 25) {
            alert.toast("Not enough coins!", "info");
        }
    }, [owner])

    const handleSetToTopAds = async () => {
        try {
            cm.showLoader(true)
            const data = { duration: duration, type: type, image: selectedImage }
            const res = await api.setAdToTop(post?.alias_id, data)
            if (res) {
                setPost(res)
                alert.toast("This Ad. was set to top", "success")
                handleClose()
            }
            cm.showLoader(false)
        } catch (err) {
            cm.showLoader(false)
            alert.show(err?.response?.data?.msg || err, "error")
        }
    }

    const renderImages = () => {
        return post?.media?.map((im, ix) => {
            return <button key={"img" + im?.media_url} className={(selectedImage === im?.media_url ? "btn-success" : "btn-outline-success") + " btn border-0 p-2 me-1 mb-1"} onClick={() => setSelectedImage(im?.media_url)}>
                <img src={process.env.REACT_APP_MEDIA_URL + im?.media_url} alt="img" className="object-fit-cover" height={72} />
            </button >
        })
    }

    return <>
        <button className="btn btn-sm btn-outline-dark" onClick={handleShow}>{cm?.translate("Set to Top")}</button>
        <Modal size="lg" scrollable={true} show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Set to Top Ads")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <div className="row m-0 p-2 pb-4">
                    <div className="col-sm-12">
                        <span className="fw-semibold">{cm?.translate("Duration")}</span>
                        <div className="mt-3 d-flex align-items-center justify-content-start">
                            <input type="number" className="form-control w-25 fs-5" min={1} value={duration} onChange={e => updateDuration(e.target.value)}></input>
                            <span className="ms-2 fs-4">{duration <= 1 ? cm?.translate("Day") : cm?.translate("Days")}</span>
                            <span className="ms-3 fs-4">=</span>
                            <span className="ms-3 fs-4 text-danger">{duration * perDayCost} {cm.translate("Coins")}</span>

                        </div>
                    </div>
                </div>
                <div className="row m-0 p-2 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12">
                        <span className="fw-semibold">{cm?.translate("Style")}</span>
                        <div className="mt-3 d-flex align-items-center justify-content-start">
                            <button className={"btn " + (type === "image-with-info" ? "btn-success" : "btn-outline-success")} onClick={e => setType("image-with-info")}><GrTextWrap className="fs-3" /> {cm?.translate("Image and Info")}</button>
                            <button className={"btn ms-3 " + (type === "image-only" ? "btn-success" : "btn-outline-success")} onClick={e => setType("image-only")}><BsCardImage className="fs-3" /> {cm?.translate("Only Image")}</button>
                        </div>
                    </div>
                </div>

                <div className="row m-0 p-2 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Photo")}</span>
                        <div className="mt-3 d-flex flex-wrap align-items-center justify-content-start">
                            {renderImages()}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Select 1 photo that will be displayed in top ad section.")}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <BuyCoins />
                <div>
                    <button className="btn btn-sm btn-outline-dark border-0 me-2 " onClick={handleClose}>{cm?.translate("Cancel")}</button>
                    <button className="btn btn-sm btn-success border-0" disabled={owner?.coins < 25} onClick={handleSetToTopAds}>{cm?.translate("Set to Top")}</button>
                </div>
            </Modal.Footer>
        </Modal >
    </>
}

export default SetToTopAd