import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert";
import { useCommon } from "../../hooks/useCommon";

const VerificationModal = ({ data, showModal, setShowModal }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(false);
  const api = useApi();
  const alert = useAlert();
  const cm = useCommon();

  const handleInput = (e) => {
    if (isFinite(e.target.value.trim())) {
      setVerificationCode(e.target.value.trim())
    }
  }

  const handleVerify = async () => {

    try {
      const res = await api.verifyCode({ email_or_phone: data?.email_or_phone, code: verificationCode });
      if (res) {
        handleClose();
        alert.showThenRedirect("Your account was verified.", "success", "/login")
      }

    } catch (err) {
      setError(true);
      if (err?.response?.status === 400) {
        alert.show(err?.response?.data?.msg || "Code is not correct!", "error")
      } else {
        alert.show(err?.response?.data?.msg || err, "error")
      }

    }
  };

  const handleClose = () => {
    setVerificationCode("");
    setShowModal(false);
  }

  return (
    <Modal show={showModal} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{cm?.translate("Verification Code")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <span className="small">
            {cm?.translate("Code has been sent to")} <b>{data?.email_or_phone}</b>
          </span>

          <Form.Control
            className="mt-2"
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={handleInput}
            isInvalid={error}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-success text-white"
          disabled={verificationCode.length < 6}
          onClick={handleVerify}
        >
          {cm?.translate("Verify")}
        </button>
        <button className="btn btn-sm btn-secondary" onClick={handleClose}>
          {cm?.translate("Cancel")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerificationModal;
