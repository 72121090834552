import { useEffect, useState } from "react";

export default function useIsScrolled() {

    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        const updatePosition = () => {
            if (window.scrollY > 2000) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        };

        window.addEventListener('scroll', updatePosition);

        return () => window.removeEventListener('scroll', updatePosition);
    }, []);

    return isScrolled;

}
