import React, { useEffect, useState } from "react";
import UserReaction from "./UserReaction";
import MemberThumb from "./MemberThumb";
import PostBasicInfoBig from "./PostBasicInfoBig";

const TopAdTemplate = ({ post }) => {

    const [selectedMedia, setSelectedMedia] = useState(null);

    const getMedia = (media_list) => {
        const media = media_list.map(i => i.media_url);

        if (media.length > 0) {
            setSelectedMedia(process.env.REACT_APP_MEDIA_URL + media[0]);
        } else {
            setSelectedMedia(null);
        }
    }

    useEffect(() => {
        if (post && !post?.top_ads?.image) {
            getMedia(post?.media);
        } else {
            setSelectedMedia(process.env.REACT_APP_MEDIA_URL + post?.top_ads?.image)
        }
    }, [post]);


    return (
        <>
            {
                post?.top_ads?.type === "image-with-info" ?
                    <div className="bg-secondary row m-0 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                        <div className="col-sm-12 col-lg-6 ps-0 rounded">
                            <a href={`/ads/${post?.alias_id}`} target="_blank" rel="noreferrer">
                                {selectedMedia !== null ? <img
                                    src={selectedMedia}
                                    className="rounded"
                                    style={{ width: "100%", height: 300, objectFit: "cover" }}
                                    alt="top_image"
                                /> : <img
                                    src={process.env.REACT_APP_STATIC_URL + "no_image.png"}
                                    style={{ width: "100%", height: 300, objectFit: "cover", opacity: "0.1" }}
                                    alt="top_image"
                                />}
                            </a>
                        </div>
                        <div className="col-sm-12 col-lg-6 d-flex flex-column justify-content-start position-relative p-2 align-self-stretch">
                            <div>
                                <PostBasicInfoBig post={post} />
                                <div className="mt-1">
                                    <UserReaction post={post} disable={true} />
                                </div>
                            </div>
                            <span className="font-size-13 fw-semibold ellipsis-1 mt-2">
                                {post?.pricing?.unit_price && post?.pricing?.unit_price + " " + post?.pricing?.currency}
                            </span>
                            {post?.owner?.user_business_profile?.is_active && post?.owner?.user_business_profile?.logo &&
                                <div className="position-absolute bottom-0 end-0 m-1">
                                    <MemberThumb user={post?.owner} size={48} />
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="">
                        <a href={`/ads/${post?.alias_id}`} target="_blank" rel="noreferrer">
                            {selectedMedia !== null ? <img
                                src={selectedMedia}
                                className="rounded"
                                style={{ width: "100%", height: 300, objectFit: "contain" }}
                                alt="top_image"
                            /> : <img
                                src={process.env.REACT_APP_STATIC_URL + "no_image.png"}
                                style={{ width: "100%", height: 300, objectFit: "contain", opacity: "0.1" }}
                                alt="top_image"
                            />}
                        </a>
                        {post?.owner?.user_business_profile?.is_active && post?.owner?.user_business_profile?.logo &&
                            <div className="position-absolute bottom-0 end-0 m-1">
                                <MemberThumb user={post?.owner} size={48} />
                            </div>
                        }
                    </div>
            }
            <a href={"/docs?title=sponsored"} target="_blank" rel="noreferrer" className="text-decoration-none link-dark font-size-9 position-absolute top-0 start-0 ms-2 mt-1">Sponsored</a>
        </>
    );
};
export default TopAdTemplate;
