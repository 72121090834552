import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon"
import { LiaCoinsSolid } from "react-icons/lia";
import { Modal } from "react-bootstrap";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";

const GiveCoins = ({ receivableUser }) => {
    const [show, setShow] = useState(false);
    const [coins, setCoins] = useState(20);
    const [trxId, setTrxId] = useState();

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();


    const handleClose = () => {
        setShow(false)
        setCoins(20)
        setTrxId("")
    }

    const handleGive = async () => {
        if (coins < 20) {
            alert.show(cm?.translate("Minimum 20 Coins can be purchased"), "error");
            return false;
        }
        try {
            cm.showLoader(true);
            const res = await api.createCoinTransactions({ to: receivableUser?.alias_id, quantity: coins, purpose: "Puchase coins by Admin: Transaction ID " + trxId });
            if (res) {
                alert.showThenRedirect(cm?.translate("Thanks for purchaging coins!"), "success", window.location.href)
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    };

    const handleShow = () => {
        setShow(true);
    };

    return <>
        <button className="btn btn-sm btn-outline-dark mx-1" onClick={handleShow}>{cm?.translate("Give Coins")}</button>
        <Modal scrollable={true} show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title><LiaCoinsSolid className="fs-2" /> {cm?.translate("Give Coins")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className="d-flex align-items-center justify-content-center">
                    <input type="number" className="form-control w-25 fs-3" min={20} value={coins} onChange={e => setCoins(e.target.value)}></input>
                    <span className="ms-2 fs-4">{cm?.translate("coins")}</span>
                    <span className="ms-3 fs-4">=</span>
                    <span className="ms-3 fs-4 text-danger">{coins} {cm.translate("Taka")}</span>
                </div>
                <div className="mt-4">
                    <input name="trxId" className="form-control" placeholder="bKash Transaction Id" value={trxId || ""} onChange={e => setTrxId(e.target.value)}></input>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-outline-dark " onClick={handleClose}>{cm?.translate("Cancel")}</button>
                <button className="btn btn-sm btn-success" disabled={coins < 20 || !trxId} onClick={handleGive}>{cm?.translate("Give")}</button>
            </Modal.Footer>
        </Modal >
    </>
}

export default GiveCoins