import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useCommon } from "../../../hooks/useCommon"
import { useApi } from "../../../hooks/useApi";
import useAlert from "../../../hooks/useAlert";

const OrderNowInput = ({ post, onPlaceOrder, onCancel }) => {
    const [isValid, setIsValid] = useState(false);
    const [formValues, setFormValues] = useState({
        "order_option": "",
        "quantity": 1,
        "tax": "",
        "unit_price": "",
        "serving_unit": "",
        "total_price": "",
        "delivery_charge": "",
        "total_pay": "",
        "delivery_info": ""
    });
    const cm = useCommon();
    const auth = useAuth();
    const api = useApi();
    const alert = useAlert();


    const handleCancel = (e) => {
        onCancel(false);
        setFormValues({})
    };
    const handlePlaceOrder = async (e) => {
        const seniteizedValue = { ...formValues, delivery_info: formValues.delivery_info.replaceAll("\n", "<br />") }
        try {
            cm?.showLoader(true, "Placing Order..")
            const res = await api.createOrder(post?.alias_id, seniteizedValue);
            if (res) {
                onPlaceOrder(res)
                cm?.showLoader(false)
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm?.showLoader(false)
        }
    }

    const totalPrice = (quantity, order_option, withCurrency = false) => {
        return (parseFloat(quantity * order_option?.unit_price) || 0) + (withCurrency && (" " + order_option?.currency))
    }

    const totalTax = (quantity, order_option, withCurrency = false) => {
        return (parseFloat(quantity * order_option?.unit_price * (order_option?.tax / 100)) || 0) + (withCurrency && (" " + order_option?.currency))
    }

    const totalDeliveryCharge = (order_option, withCurrency = false) => {
        return (parseFloat(order_option?.delivery_charge) || 0) + (withCurrency && (" " + order_option?.currency))
    }

    const totalPay = (quantity, order_option, withCurrency = false) => {

        const price = parseFloat(quantity * parseFloat(order_option?.unit_price)) || 0
        const tax = parseFloat(quantity * order_option?.unit_price * (order_option?.tax / 100)) || 0
        const delivery = parseFloat(order_option?.delivery_charge) || 0
        return price + tax + delivery + (withCurrency && (" " + order_option?.currency))
    }



    useEffect(() => {
        setFormValues({
            ...formValues,
            order_option: post?.pricing?.id,
            quantity: 1,
            unit_price: post?.pricing?.unit_price,
            serving_unit: post?.pricing?.serving_unit,
            total_price: totalPrice(1, post?.pricing),
            tax: totalTax(1, post?.pricing),
            delivery_charge: totalDeliveryCharge(post?.pricing),
            total_pay: totalPay(1, post?.pricing),
        });
    }, [])

    useEffect(() => {
        if (formValues?.delivery_info === "" || formValues?.delivery_info === undefined) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }, [formValues])


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "quantity") {
            const data = {
                "quantity": value,
                "total_price": totalPrice(value, post?.pricing),
                "tax": totalTax(value, post?.pricing),
                "delivery_charge": totalDeliveryCharge(post?.pricing),
                "total_pay": totalPay(value, post?.pricing),
            }
            setFormValues({ ...formValues, ...data })
        } else {
            setFormValues({ ...formValues, [name]: value })
        }
    };


    return <>
        <div>
            {/* <PostTemplate post={post} fullWidth={true} /> */}
            {post?.pricing?.order_related_info && <div className="p-2 font-size-10 bg-warning bg-opacity-25" style={{ minHeight: 50 }} dangerouslySetInnerHTML={{ __html: post?.pricing?.order_related_info }} ></div>}
        </div>

        <div className="bg-secondary bg-opacity-10 py-4 mx-0 row mt-3 mb-3">
            <div className="col-sm-12 col-md-12 col-lg-6 mb-3 mb-md-0 d-flex flex-column justify-content-center px-4">
                <div className="d-flex justify-content-between align-items-center">
                    <label className="fs-5">{cm?.translate("Quantity")}</label>
                    <select name="quantity" className="ms-3 form-control w-50 fs-5 text-end" onChange={handleChange} >
                        {Array(100).fill(0).map((_, i) => i + 1).map(i => <option key={"qty_" + i} value={i}>{cm?.translate(i)}</option>)}
                    </select>
                </div>
                <div className="mt-2 d-flex justify-content-between align-items-center">
                    <label className="font-size-11">{cm?.translate("Unit Price")}</label>
                    <input type="text" name="unit_price" disabled className="ms-3 form-control bg-white font-size-11 w-50 text-end" value={post?.pricing?.unit_price + " " + post?.pricing?.currency}></input>
                </div>
                <div className="mt-2 d-flex justify-content-between align-items-center">
                    <label className="font-size-11">{cm?.translate("Unit")}</label>
                    <input type="text" name="unit" disabled readOnly={true} className="ms-3 form-control bg-white font-size-11 w-50 text-end" value={post?.pricing?.serving_unit}></input>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 border-start px-4 mt-2 mt-lg-0">
                <div className="d-flex justify-content-between align-items-center bg-white rounded p-2 mb-1">
                    <span className="fs-6 text-dark">{cm?.translate("Price")}</span>
                    <span className="fs-6 text-dark"> {totalPrice(formValues?.quantity, post?.pricing, true)}</span>
                </div>
                {
                    post?.pricing?.tax > 0 && <div className="d-flex justify-content-between align-items-center bg-white rounded p-2 mb-1">
                        <span className="fs-6 text-dark">{cm?.translate("Tax")}</span>
                        <span className="fs-6 text-dark"> {totalTax(formValues?.quantity, post?.pricing, true)}</span>
                    </div>
                }

                {
                    post?.pricing?.delivery_charge > 0 && <div className="d-flex justify-content-between align-items-center bg-white rounded p-2 mb-1">
                        <span className="fs-6 text-dark">{cm?.translate("Delivery Charge")}</span>
                        <span className="fs-6 text-dark"> {totalDeliveryCharge(post?.pricing, true)}</span>
                    </div>
                }
                <div className="d-flex justify-content-between align-items-center bg-white rounded p-2">
                    <span className="fs-6 text-dark fw-semibold">{cm?.translate("Total Pay")}</span>
                    <span className="fs-6 text-danger fw-semibold"> {totalPay(formValues?.quantity, post?.pricing, true)}</span>
                </div>
            </div>
        </div>
        <div className="mb-3">
            <span className="font-size-11 fw-semibold d-block mb-1">{cm?.translate("Customer")}</span>
            <input
                type="text"
                value={auth.authUser?.user_profile?.name || auth.authUser?.email_or_phone}
                readOnly={true}
                className="form-control mb-2"
                required
            />
            <textarea className="form-control mt-2" rows="3" placeholder={cm?.translate("Delivery Instruction")} onChange={handleChange} value={formValues?.delivery_info} name="delivery_info"></textarea>
            <span className="font-size-10 d-block mt-2">{cm?.translate("By placing an order, you confirm that you have read and agree to the terms and conditions")} <a href="/docs?title=terms" target="_blank">{cm?.translate("Terms & Condition")}</a></span>
        </div>
        <div className="mt-4 border-top pt-3 d-flex justify-content-end">

            <button
                className="ms-2 btn btn-sm btn-outline-dark  me-2"
                onClick={handleCancel}
            >
                {cm?.translate("Cancel")}
            </button>
            <button
                className="btn btn-sm btn-success"
                disabled={!isValid}
                onClick={handlePlaceOrder}
            >
                {cm?.translate("Place Order")}
            </button>
        </div>
    </>
}
export default OrderNowInput