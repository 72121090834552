import React, { useState } from "react";
import PostTemplate from "./PostTemplate";
import { useEffect } from "react";
import UserProfile from "./UserProfile";
import { useParams } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { useAuth } from "../hooks/useAuth";
import { useCommon } from "../hooks/useCommon";
import useAlert from "../hooks/useAlert";
import Pagination from "./Pagination";

const UserAds = () => {
    const { aliasId } = useParams();
    const [user, setUser] = useState({})
    const [ads, setAds] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();

    const getUserDetailFromApi = async () => {
        try {
            cm.showLoader(true);
            const res = await api.getUserDetail(aliasId);
            if (res) {
                setUser(res);
                cm.showLoader(false);
            }

        } catch (err) {
            alert.show(err?.message, "error");
            cm.showLoader(false);
        }
    };

    const getAdsFromApi = async () => {
        try {
            cm.showLoader(true);
            const res = await api.getUserAds(aliasId);
            if (res) {
                setAds(res);
                cm.showLoader(false);
            }

        } catch (err) {
            alert.show(err?.message, "error")
            cm.showLoader(false);
        }
    };


    useEffect(() => {
        getUserDetailFromApi();
    }, [aliasId])

    useEffect(() => {
        getAdsFromApi();
    }, [user])

    // useEffect(() => {
    //     let start, end, count = 0;
    //     if (ads?.next !== null && ads?.next !== undefined) {
    //         const params = decodeSearchParams(new URL(ads?.next).searchParams);
    //         const nextPage = parseInt(params["page"]);
    //         const page = nextPage - 2
    //         end = (page + 1) * ads?.results?.length
    //         start = end > 0 ? end - ads?.results?.length + 1 : 0
    //     } else if (ads?.previous) {
    //         const params = decodeSearchParams(new URL(ads?.previous).searchParams);
    //         const prevPage = parseInt(params["page"] || 1);
    //         const page = prevPage
    //         const dataLen = parseInt(ads?.results?.length)
    //         end = dataLen + (page * 100)
    //         start = end > 0 ? end - dataLen + 1 : 0
    //     } else {
    //         end = ads?.results?.length || 0
    //         start = end > 0 ? 1 : 0
    //     }

    //     start = cm?.translate(start, cm?.language, true)
    //     end = cm?.translate(end, cm?.language, true)
    //     count = cm?.translate(ads?.count, cm?.language, true)

    //     setPageRange(start + " - " + end + " / " + count)

    // }, [ads, ads?.next, ads?.previous])


    const renderAds = () => {
        return ads?.results?.map((p, i) =>
            auth.authUser?.templateStyle === "template-1" ? <PostTemplate key={"post_" + i} post={p} /> : <PostTemplate key={"post_" + i} post={p} />);
    };

    // const handlePrev = () => {
    //     if (ads?.previous) {
    //         const params = decodeSearchParams(new URL(ads?.previous).searchParams);
    //         getAdsFromApi(params);
    //     }
    // }


    // const handleNext = () => {
    //     if (ads?.next) {
    //         const params = decodeSearchParams(new URL(ads?.next).searchParams);
    //         getAdsFromApi(params);
    //     }
    // }

    const onChangePage = (e) => {
        getAdsFromApi({ page: e.target.value });
        setCurrentPage(e.target.value)
    }

    return (
        <div className="px-2 px-md-0">
            <div className={"col-sm-12 col-md-10 mx-auto my-4"}>
                <UserProfile user={user} setUser={setUser} />
            </div>
            <div className={"col-sm-12 col-md-10 mx-auto my-4"}>
                <div className="row g-0 m-0 mt-2">{renderAds()}</div>
                <div className="mt-4 mb-5">
                    <Pagination totalItems={ads?.count} onChangePage={onChangePage} currentPage={currentPage} />
                </div>
            </div>
        </div>
    );
};
export default UserAds;
