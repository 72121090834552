import React from "react";
import { useNavigate } from "react-router-dom";
import MyPageMenu from "./MyPageMenu";
import { useAuth } from "../hooks/useAuth";
import { useCommon } from "../hooks/useCommon";
import CreateAd from "./mypage/CreateAd";
import Search from "./Search";

const Header = () => {
    const auth = useAuth()
    const cm = useCommon()
    const navigate = useNavigate();
    const toIndex = () => {
        let path = "/";
        navigate(path);
    };
    const toMypage = () => {
        let path = "/mypage";
        navigate(path);
    };

    const getNamePart = (name) => {
        const nameParts = name.split(" ");
        if (nameParts.length > 1) {
            return nameParts[0]
        } else {
            return name
        }
    }

    return (
        <div
            className="row border-bottom border-success border-3 border-opacity-75 fixed-top"
            style={{ background: "#EAF5EA" }}
        >
            <div className="col-sm-12 col-md-11 col-lg-10 mx-auto px-2 px-md-0" style={{ paddingTop: ".8em", paddingBottom: ".8em" }}>
                <div className="row m-0 d-flex align-items-center">
                    <div className="col col-lg-4 d-flex justify-content-start">
                        <a href="/" className="d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" className="" style={{ width: 200 }} />
                        </a>
                    </div>
                    <div className="d-print-none col col-lg-8 d-flex justify-content-end">
                        <CreateAd className="d-none d-md-block" />
                        <div className="ms-1 d-flex flex-row flex-wrap justify-content-end" role="group">
                            <button className="btn btn-outline-success border-0 me-1 d-none d-lg-block " onClick={toIndex}>{cm?.translate("Home")}</button>
                            <button type="button" className="btn btn-outline-success border-0" onClick={toMypage}>
                                {auth.authUser?.user_profile?.name ? getNamePart(auth.authUser?.user_profile?.name) : cm?.translate("My Page")}
                            </button>
                            {/* <div className="btn-group" role="group">
                                <button type="button" className="btn btn-outline-success border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="pe-1">{auth.authUser?.user_profile?.name ? getNamePart(auth.authUser?.user_profile?.name) : cm?.translate("My Page")}</span>
                                </button>
                                <ul className="dropdown-menu">
                                    <button type="button" className="btn dropdown-item dropdown-item-success" onClick={toMypage}>{auth.authUser?.user_profile?.name ? cm?.translate("My Page") : cm?.translate("Login")}</button>
                                    <button type="button" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasMypageMenu"
                                        aria-controls="offcanvasMypageMenu" className="btn dropdown-item dropdown-item-success">
                                        {cm?.translate("Quick Menu")}
                                    </button>
                                </ul>
                            </div> */}
                        </div>
                        {/* <div className="btn-group d-sm-block d-lg-none" role="group">
                            <button type="button" className="btn btn-outline-success border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="pe-1">{auth.authUser?.user_profile?.name ? getNamePart(auth.authUser?.user_profile?.name) : cm?.translate("Login")}</span>
                            </button>
                            <ul className="dropdown-menu">
                                <button type="button" className="btn dropdown-item dropdown-item-success" onClick={toMypage}>{auth.authUser?.user_profile?.name ? getNamePart(auth.authUser?.user_profile?.name) : cm?.translate("My Page")}</button>
                                <button type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasMypageMenu"
                                    aria-controls="offcanvasMypageMenu" className="btn dropdown-item dropdown-item-success">
                                    {cm?.translate("Quick Menu")}
                                </button>
                            </ul>
                        </div> */}
                        <MyPageMenu />
                    </div>
                </div>
            </div>
        </div >
    );
};
export default Header;
