import { useEffect, useState } from "react";
import MediaCarousel from "./MediaCarousel";
import { useCommon } from "../hooks/useCommon";

const MediaCollage = ({ media }) => {
    const [mediaList, setMediaList] = useState([]);
    const cm = useCommon()


    const getMedia = () => {
        const ms = media?.filter(i => i.media_url != null).map(i => process.env.REACT_APP_MEDIA_URL + i.media_url);
        setMediaList(ms);
    }

    useEffect(() => {
        getMedia();
    }, [media])


    const renderMedia = () => {
        if (mediaList?.length === 1) {
            return (<div className="row g-1 d-flex align-items-center justify-content-center">
                {mediaList.map((i, ix) => <img key={"img_" + ix} src={i} onClick={e => cm.setShowMediaCarousel(true)} className="col-sm-12 col-md-12 col-lg-12 object-fit-cover" alt="img" style={{ maxHeight: 400 }} />)}
            </div>)
        }
        else if (mediaList?.length === 2) {
            return (<div className="row g-1 d-flex align-items-center justify-content-center">
                {mediaList.map((i, ix) => <img key={"img_" + ix} src={i} onClick={() => cm.setShowMediaCarousel(true)} className="col-sm-12 col-md-6 col-lg-6 object-fit-cover" alt="img" style={{ maxHeight: 400 }} />)}
            </div>)
        }
        else if (mediaList?.length === 3) {
            return (<div className="row g-1 d-flex align-items-center justify-content-center">
                {mediaList.map((i, ix) => <img key={"img_" + ix} src={i} onClick={() => cm.setShowMediaCarousel(true)} className="col-sm-12 col-md-4 col-lg-4 object-fit-cover" alt="img" style={{ maxHeight: 400 }} />)}
            </div>)
        }
        else if (mediaList?.length > 3 & mediaList?.length < 6) {
            return (<div className="row g-1 d-flex align-items-center justify-content-start">

                {mediaList.map((i, ix) => <img key={"img_" + ix} src={i} onClick={() => cm.setShowMediaCarousel(true)} className="col-sm-12 col-md-3 col-lg-4 object-fit-cover" alt="img" style={{ maxHeight: 250 }} />)} </div>)
        }
        else if (mediaList?.length >= 6 & mediaList?.length <= 8) {
            return (<div className="row g-1 d-flex align-items-center justify-content-start">

                {mediaList.map((i, ix) => <img key={"img_" + ix} src={i} onClick={() => cm.setShowMediaCarousel(true)} className="col-sm-12 col-md-2 col-lg-3 object-fit-cover" alt="img" style={{ maxHeight: 250 }} />)} </div>)
        }
        else if (mediaList?.length > 8) {
            return (<div className="row g-1 d-flex align-items-center justify-content-start">
                {mediaList.map((i, ix) => <img key={"img_" + ix} src={i} onClick={() => cm.setShowMediaCarousel(true)} className="col-sm-12 col-md-2 col-lg-2 object-fit-cover" alt="img" style={{ maxHeight: 250 }} />)} </div>)
        }
    }

    return <div className="overflow-scroll p-1" style={{ maxHeight: 520 }}>
        {mediaList && !cm.showMediaCarousel && renderMedia()}
        {mediaList && cm.showMediaCarousel && <MediaCarousel mediaList={mediaList} showCarosel={cm.setShowMediaCarousel} />}
    </div>
}
export default MediaCollage