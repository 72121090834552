import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiSolidUserCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import { useCommon } from "../../hooks/useCommon";
import { FaUsers } from "react-icons/fa";

const FollowerSearch = ({ title = "Search Followers", user, resource = "followers", showTotal = false, disabled = false }) => {
    const [followers, setFollowers] = useState([]);
    const [show, setShow] = useState(false);

    const api = useApi();
    const alert = useAlert();
    const cm = useCommon();
    const navigate = useNavigate()


    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
    }

    const toFollowerAdsPage = (alias_id) => {
        navigate("/members/" + alias_id);
        handleClose();
    }

    const getUserFollowersFromApi = async (params) => {
        try {
            let res = {}
            if (resource === "followers") {
                res = await api.getUserFollowers(user?.alias_id, params);
            } else {
                res = await api.getUserFollowings(user?.alias_id, params);
            }
            setFollowers(res);

        } catch (err) {
            alert.show(err, "error")
        }
    };

    const renderFollowers = () => {
        return followers?.results?.map(f => {
            return <button onClick={() => toFollowerAdsPage(f?.alias_id)} key={"follower_" + f?.alias_id} className="btn btn-light bg-white mb-1 d-flex align-items-center p-1 font-size-10">
                <img src={(process.env.REACT_APP_MEDIA_URL + (f?.user_business_profile?.company_logo || f?.user_profile?.profile_image)) || process.env.REACT_APP_NO_IMAGE} alt="follower_image" className="rounded-1" style={{ height: 32, width: 32 }} />
                <span className="ms-2">{f?.user_business_profile?.company_name || f?.user_profile?.name} ({f?.ads})</span>
            </button>
        })
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            getUserFollowersFromApi({ search: e.target.value })
        }
    }

    useEffect(() => {
        show && getUserFollowersFromApi()
    }, [show])


    return <>
        {showTotal ?
            <button onClick={handleShow} style={{ pointerEvents: disabled && "none" }} className={"btn btn-sm w-100 bg-success bg-opacity-25 d-flex justify-content-between align-items-center rounded"}><span className="fs-6">{cm?.translate(title)}</span><span className="fs-2 text-dark lh-1">{user[resource]}</span></button >
            :
            <button onClick={handleShow} className={"align-self-stretch btn btn-outline-success border-0 not-focusable rounded d-flex justify-content-center align-items-center flex-column flex-md-row"}>
                <FaUsers className="fs-4" />
                <span className="d-inline-block ms-0 ms-md-2">{cm?.translate(title)}</span>
            </button>
        }

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
                <div className="position-relative w-100">
                    <input
                        className="form-control"
                        type="text"
                        placeholder={"Search " + resource}
                        autoFocus
                        disabled={followers?.length <= 0}
                        onKeyUp={(e) => handleSearch(e)}
                    ></input>
                    <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-2 mb-1">{cm?.translate("Enter to get result")}</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    {renderFollowers()}
                    {followers?.count <= 0 && <span>{cm?.translate("There is no " + resource)}</span>}
                </div>
            </Modal.Body>
        </Modal>
    </>
}
export default FollowerSearch;