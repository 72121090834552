import { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";

const PostTemplateMedia = ({ post, thumbSize = "post-thumbX120", selectionFrom = "resized" }) => {
    const [showMediaScroll, setShowMediaScroll] = useState(false);
    const [mediaList, setMediaList] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState(null);

    const getMedia = (media_list) => {

        const media = media_list.map(i => selectionFrom === "resized" ? i.media_url_resized100x100 || i.media_url_resized || i.media_url : i.media_url);

        if (media.length > 0) {
            setMediaList(media);
            setSelectedMedia(process.env.REACT_APP_MEDIA_URL + media[0]);
        } else {
            setSelectedMedia(null);
        }
    }


    useEffect(() => {
        post && getMedia(post?.media);
    }, [post]);

    const selectNextImage = () => {
        const selectedMediaIndex = mediaList.findIndex(e => selectedMedia.includes(e))
        setSelectedMedia(process.env.REACT_APP_MEDIA_URL + (mediaList[selectedMediaIndex + 1] || mediaList[0]))
    };

    const renderMedia = () => {
        if (selectedMedia) {
            if (mediaList.length > 1) {
                return <><a href={"/ads/" + post.alias_id} target="_blank" rel="noreferrer">
                    <img
                        src={selectedMedia}
                        alt="img"
                        className={thumbSize + " bg-white object-fit-cover rounded"}
                        onMouseEnter={e => setShowMediaScroll(true)}
                        onMouseLeave={e => setShowMediaScroll(false)}
                    />
                </a>
                    {showMediaScroll && <button onClick={selectNextImage} onMouseEnter={e => setShowMediaScroll(true)} className="btn btn-sm d-flex justify-content-center align-items-center p-2 btn-light rounded-circle position-absolute top-50 start-50 translate-middle shadow-sm"><FaChevronRight /></button>}
                </>
            } else {
                return <a href={"/ads/" + post.alias_id} target="_blank" rel="noreferrer"><img
                    src={selectedMedia}
                    alt="img"
                    className={thumbSize + " bg-white object-fit-cover rounded"}
                    onMouseEnter={e => setShowMediaScroll(true)}
                    onMouseLeave={e => setShowMediaScroll(false)}
                />
                </a>
            }
        } else {
            return <a href={"/ads/" + post.alias_id}><img
                src={process.env.REACT_APP_STATIC_URL + "no_image.png"}
                alt="img"
                className={thumbSize + " bg-white object-fit-contain"}
                style={{ opacity: "0.1" }}
            /></a>
        }
    }

    return <div className={thumbSize + " position-relative"}>
        {renderMedia()}
    </div>
}

export default PostTemplateMedia;