import { useEffect, useState } from "react"
import { useCommon } from "../../hooks/useCommon"
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert"
import { useSearchParams } from "react-router-dom"
import { decodeSearchParams } from "../../utils/utils";
import moment from "moment";
import ApplicantApplication from "../modals/ApplicantApplication"
import { Accordion, Tab, Tabs } from "react-bootstrap"
import { GrDocumentText } from "react-icons/gr"
import { useAuth } from "../../hooks/useAuth"


const JobApplications = () => {
    const [searchParams] = useSearchParams();
    const [applications, setApplications] = useState([])
    const [selectedApplication, setSelectedApplication] = useState({})
    const [ads, setAds] = useState([])
    const [selectedAd, setSelectedAd] = useState(null)
    const [mode, setMode] = useState("employer")

    const cm = useCommon()
    const api = useApi()
    const alert = useAlert()
    const auth = useAuth()

    const getAdsForJob = async () => {
        try {
            cm.showLoader(true);
            const res = await api.getJobApplicationsAds();
            if (Object.keys(res).length) {
                setAds(res);
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err, "error")
        }
    };

    const getApplications = async (params) => {
        try {
            cm.showLoader(true);
            const res = await api.getJobApplications(params);

            if (res) {
                setApplications(res);
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err, "error")
        }
    };

    useEffect(() => {
        setSelectedAd({})
        setSelectedApplication({})

        if (mode === "employer") {
            getAdsForJob()
        } else {
            getApplications({ mode: "candidate" })
        }
    }, [mode])

    // useEffect(() => {
    //     console.log("selectedApplication", selectedApplication)
    // }, [selectedApplication])

    const handleSelectJobAd = (e) => {
        if (e.target.value !== "") {
            const ad = ads?.find(a => a.alias_id === e.target.value)
            setSelectedAd(ad)
        } else {
            setSelectedAd({})
        }
    }

    useEffect(() => {
        selectedAd && Object.keys(selectedAd).length > 0 ? getApplications({ ad: selectedAd?.alias_id }) : setApplications([])
    }, [selectedAd])

    useEffect(() => {
        const params = decodeSearchParams(searchParams)

        if (mode === "employer" && "applicant" in params && Object.keys(applications).length) {
            const findApplication = applications?.results.find(a => a?.applicant === params?.applicant)
            setSelectedApplication(findApplication)
        }
    }, [applications])


    useEffect(() => {
        const params = decodeSearchParams(searchParams)

        if ("mode" in params) {
            setMode(params.mode)
        }
        if ("ad" in params) {
            if (mode === "employer") {
                setSelectedAd(params?.ad);

            } else {
                setSelectedApplication(params?.ad)
            }
        }

    }, [searchParams])



    const getAge = (date) => {
        const d = moment(date).fromNow().split(" ");
        return d[0] + " " + d[1];
    }

    const showApplication = (e, application) => {
        setSelectedApplication(application)
    }

    const renderApplications = () => {
        return Object.keys(applications) && applications?.results?.map((a, index) =>
            <div key={"application_" + index} className="col-sm-12 col-md-6 px-1" onClick={e => showApplication(e, a)}>
                <div className="row m-0 p-1 thumb-hover rounded font-size-11 border border-opacity-25  shadow-sm">
                    <div className="col-3 p-0">
                        <img src={a.photo || "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="} alt="thumb" className="w-100 rounded bg-light" />
                    </div>
                    <div className="col-9">
                        <div className="text-start font-size-12 d-flex align-items-center"><span className="fw-semibold">{a?.first_name}</span><span className="text-secondary font-size-9 ms-1">({getAge(a?.dob)})</span></div>
                        {a?.duration_of_experience && <div className="d-flex justify-content-start align-items-center"><span className="font-size-11">{a?.duration_of_experience} {cm?.translate("experience")}</span></div>}
                        {a?.expected_salary && <div className="d-flex justify-content-start align-items-center"><span className="text-danger font-size-11">{cm?.translate("Expected salary")} {a?.expected_salary}</span></div>}
                        {<div className="d-flex justify-content-start align-items-center"><span className="text-secondary font-size-9">{cm.translate("Applied on")} {moment(a?.ad?.created).format("YYYY-MM-DD")}</span></div>}
                    </div>
                </div>
            </div>
        )
    }

    const renderMyJobAds = () => {
        return Object.keys(ads).length > 0 && ads?.map((a, ix) => {
            return <Accordion.Item eventKey={a?.alias_id} key={"ad_" + ix} className="rounded-0">
                <Accordion.Header>
                    {a?.title} {<span className="ms-2">({cm?.translate(a?.total_application)})</span>}
                </Accordion.Header>
                <Accordion.Body className="overflow-scroll p-4" style={{ maxHeight: 500 }}>
                    <div className="row g-2">
                        {applications && Object.keys(applications).length && renderApplications()}
                        {selectedApplication && Object.keys(selectedApplication).length > 0 && <ApplicantApplication application={selectedApplication} handleClose={e => handleAplicationClose(e)} />}
                    </div>
                </Accordion.Body>
            </Accordion.Item >
        }
        );
    };

    const renderMyJobAdsOptions = () => {
        return Object.keys(ads).length > 0 && ads?.map((a, ix) => {
            return <option key={"jobads_" + ix} value={a?.alias_id}>{a?.title} ({cm?.translate(a?.total_application)})</option>
        }
        );
    };

    const renderMyApplications = () => {
        return Object.keys(applications) && applications.results?.map((a, ix) => {
            return <Accordion.Item eventKey={a?.ad?.alias_id} key={"ad_" + ix} className="rounded-0">
                <Accordion.Header>
                    {a?.ad?.title} <span className="text-secondary ms-2">{cm.translate("applied on")} {moment(a?.ad?.created).format("YYYY-MM-DD")}</span>
                </Accordion.Header>
                <Accordion.Body className="p-4">
                    <div className="overflow-scroll" style={{ maxHeight: 500 }}>

                        <h6 className="border-bottom mb-2">{cm?.translate("Personal Information")}</h6>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Photo")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                {a?.photo && <img src={a?.photo} height={100} width={100} alt="candidate_photo" className="object-fit-contain" />}
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Name")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.first_name} {a?.last_name}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Birth Date")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.dob} ({getAge(a?.dob)})</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Email")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.email}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Phone")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.phone}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Address")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.address}</span>
                            </div>
                        </div>
                        <h6 className="mt-4 border-bottom mb-2">{cm?.translate("Professional Information")}</h6>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Experience")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.duration_of_experience}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Expertise")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.expertise}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Current Employer")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.current_employer}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Current Employer Address")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.current_employer_address}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Current Role")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.current_role}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Current Salary")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.current_salary}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Expected Salary")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.expected_salary}</span>
                            </div>
                        </div>
                        <h6 className="mt-4 border-bottom mb-2">{cm?.translate("Appeal")}</h6>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Message")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <span>{a?.appealing_message}</span>
                            </div>
                        </div>
                        <div className="row m-0 mb-1">
                            <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                                {cm?.translate("Resume")}
                            </div>
                            <div className="col d-flex align-items-center py-1">
                                <a download={a?.resume?.includes("data:application/pdf") ? "resume.pdf" : "resume.doc"} href={a?.resume} className="text-decoration-none d-flex align-items-center text-dark"><GrDocumentText className="fs-5 me-1" /> {cm?.translate("Resume")}</a>
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item >
        }
        );
    };

    const handleAplicationClose = () => {
        setSelectedApplication({})
        searchParams.delete("applicant")
    }

    return <div>
        <Tabs
            activeKey={mode}
            onSelect={(k) => setMode(k)}
            className="mb-3"
        >
            <Tab eventKey="candidate" title={cm?.translate("Applied")} className="p-2">
                <Accordion className="mt-2">
                    {renderMyApplications()}
                </Accordion>
            </Tab>
            {auth?.authUser?.user_business_profile && <Tab eventKey="employer" title={cm?.translate("Candidates' Application")} className="p-2 justify-content-center">
                <select className="form-select" onChange={e => handleSelectJobAd(e)}>
                    <option value={""}>{cm?.translate("Select Job Post")}</option>
                    {renderMyJobAdsOptions()}
                </select>
                {selectedAd?.alias_id && <div className="mt-4" >
                    <h5 className="mx-1">{cm?.translate("Applicants")}</h5>
                    <div className="row gy-2 m-0 mt-2">
                        {renderApplications()}
                        {selectedApplication && Object.keys(selectedApplication).length > 0 && <ApplicantApplication application={selectedApplication} handleClose={e => handleAplicationClose(e)} />}
                    </div>
                </div>}
            </Tab>}
        </Tabs>

    </div>
}

export default JobApplications