import React, { useEffect, useState } from "react";
import PostTemplate from "./PostTemplate";
import { useCommon } from "../hooks/useCommon";
import { useApi } from "../hooks/useApi";
import { useAuth } from "../hooks/useAuth";
import useAlert from "../hooks/useAlert";
import PostTemplate2 from "./PostTemplate2";
import Pagination from "./Pagination";
import TopAds from "./mypage/TopAds";
import { Tab, Tabs } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { decodeSearchParams } from "../utils/utils";
import Search from "./Search";
import { IoMdRefresh } from "react-icons/io";

const Ads = () => {
    const [key, setKey] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [filterParams, setFilterParams] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();


    const cm = useCommon();
    const api = useApi();
    const auth = useAuth();
    const alert = useAlert();


    const getAdsFromApi = async (params) => {
        try {
            cm?.showLoader(true)
            const res = await api.getAds(params || {})
            if (res) {
                cm?.setAds(res);
                cm?.showLoader(false)
            }
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err, "error")
        }
    };

    const renderAds = (groupId) => {
        return cm?.ads?.results?.map((p, i) => {
            if (p?.category?.group?.id === groupId) {
                if (auth?.authUser?.user_settings?.ads_listing_style === "default" || !auth?.authUser) {
                    return <PostTemplate key={"post_" + p?.alias_id} post={p} reactionIconDisable={true} />
                } else {
                    return <PostTemplate2 key={"post_" + p?.alias_id} post={p} reactionIconDisable={true} />
                }
            }
        })
    };

    const handleCategorySelect = (cat) => {
        setSelectedCategory(cat)
        setFilterParams({ ...filterParams, category: cat?.name || "" })
    }

    const handleLocationSelect = (loc) => {
        setSelectedLocation(loc)
        setFilterParams({ ...filterParams, location: loc?.name || "" })
    }

    const handleRefresh = () => {
        setSearchParams({})
        setFilterParams({})
    }


    const handleTab = (k) => {
        setKey(k)
    }

    const renderCategory = (groupId) => {
        return cm?.categories.length > 0 && <div className="btn-group" role="group">
            <button type="button" className="btn btn-sm btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="pe-2">{selectedCategory?.id ? cm?.translate("Category") + " - " + cm?.translate(selectedCategory?.name) : cm?.translate("All Categories")}</span>
            </button>
            <ul className="dropdown-menu overflow-scroll" style={{ maxHeight: 300 }}>
                <button className={"btn dropdown-item dropdown-item-light"} onClick={() => handleCategorySelect("")}>{cm?.translate("All Categories")}</button>
                {cm?.categories && cm?.categories?.map(c => c?.group?.id === groupId && c?.total_ads > 0 && <button key={"cat_" + c.id} className={"btn dropdown-item dropdown-item-light"} onClick={() => handleCategorySelect(c)}>{cm?.translate(c?.name)} ({cm?.translate(c?.total_ads, cm?.language, true)})</button>)}
            </ul>
        </div>
    }

    const renderLocation = () => {
        const selectedGroup = filterParams?.cat_group
        return cm?.locations.length > 0 && <div className="btn-group ms-2" role="group">
            <button type="button" className="btn btn-sm btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="pe-2">{selectedLocation?.id ? cm?.translate("Location") + " - " + cm?.translate(selectedLocation?.name) : cm?.translate("All Locations")}</span>
            </button>
            <ul className="dropdown-menu overflow-scroll" style={{ maxHeight: 300 }}>
                <button className={"btn dropdown-item dropdown-item-light"} onClick={() => handleLocationSelect("")}>{cm?.translate("All Locations")}</button>
                {cm?.locations && cm?.locations?.map(l => l?.total_ads?.[selectedGroup] > 0 && <button key={"loc_" + l.id} className={"btn dropdown-item dropdown-item-light"} onClick={() => handleLocationSelect(l)}>{cm?.translate(l?.name)} ({cm?.translate(l?.total_ads?.[selectedGroup], cm?.language, true)})</button>)}
            </ul>
        </div>
    }


    const renderTabs = () => {
        return cm?.categoryGroups?.map((g, ix) => {
            return <Tab key={`tab-${g?.id}`} eventKey={g?.id} title={<div className="d-flex flex-row justify-content-center align-items-center"><img src={process.env.PUBLIC_URL + "/static/" + (g?.name?.startsWith("Product") ? "product.png" : g?.name?.startsWith("Job") ? "job.png" : g?.name?.startsWith("Social") ? "social.png" : "travel.png")} alt="logo" style={{ width: 28 }} /><span className="ms-2">{g?.name}</span></div>} className="p-3 pt-2">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="d-flex flex-wrap">
                        {renderCategory(g?.id)}
                        {renderLocation()}
                        <div className="mt-2 mt-md-0 ms-md-2 d-flex">
                            <Search />
                            <button title={cm?.translate("Refresh")} className="btn btn-sm btn-outline-dark border-0 ms-2" onClick={handleRefresh}><IoMdRefresh className="fs-4" />                        </button>
                        </div>

                    </div>
                    <div className="mt-2 mt-md-0">
                        <Pagination totalItems={cm?.ads?.count} onChangePage={onChangePage} currentPage={currentPage} pageSplit={50} />
                    </div>
                </div>
                <div className={"mt-3 " + ((auth?.authUser?.user_settings?.ads_listing_style === "default" || !auth?.authUser) ? "row g-0" : "row g-2")}>
                    {renderAds(g?.id)}
                </div>

            </Tab>
        })
    }

    const onChangePage = (e) => {
        getAdsFromApi({ ...filterParams, page: e.target.value });
        setCurrentPage(e.target.value)
    }

    useEffect(() => {
        setKey(cm?.categoryGroups[0]?.id)
    }, [])

    useEffect(() => {
        const catGroup = cm?.categoryGroups?.find(g => parseInt(g?.id) === parseInt(key))
        setSelectedCategory("")
        setSelectedLocation("")
        setFilterParams({ "cat_group": catGroup?.name })
    }, [key])


    useEffect(() => {
        getAdsFromApi({ ...filterParams })
        setCurrentPage(1)
    }, [filterParams])


    useEffect(() => {
        const params = decodeSearchParams(searchParams)
        if (params?.location) {
            const loc = cm?.locations?.find(l => l?.name === params?.location)
            handleLocationSelect(loc)
        }
        if (params?.category) {
            const cat = cm?.categories?.find(c => c?.name === params?.category)
            handleCategorySelect(cat)
        }

        if (params?.search) {
            setFilterParams({ search: params?.search })
        }

    }, [searchParams])

    return (
        <div className="row m-0 my-4 mx-2 mx-lg-0">

            <div className={"col-sm-12 col-md-10 mx-auto"}>
                {<TopAds />}
            </div>

            <div className="col-sm-12 col-md-10 mx-auto">
                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTab(k)}
                    className="my-3 font-size-12 bg-light"
                >
                    {renderTabs()}
                </Tabs>
            </div>
        </div>
    );
};
export default Ads;
