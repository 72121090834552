import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment";

const PostBasicInfoBig = ({ post }) => {

    const renderTitle = () => {
        return <Link to={"/ads/" + post?.alias_id} target="_blank" className="text-decoration-none link-dark">
            <span className="ellipsis-1 fs-4">{post?.title}</span>
        </Link>
    }

    return <div className="d-flex flex-column">
        {renderTitle(post)}
        <div className="d-flex flex-wrap align-items-center">
            <Link to={"/ads/?category=" + post?.category?.name} className="text-decoration-none link-success font-size-10">{post?.category?.name}</Link>
            {(post?.owner?.user_business_profile?.location) && post?.category?.name && <MdOutlineKeyboardArrowRight />}
            <Link to={"/ads/?location=" + (post?.owner?.user_business_profile?.location?.name)} className="text-decoration-none link-success font-size-10">{post?.owner?.user_business_profile?.location?.name}</Link>
            <MdOutlineKeyboardArrowRight />
            <span className="font-size-10">{moment(post?.created).fromNow()}</span>
        </div>
    </div>

};
export default PostBasicInfoBig