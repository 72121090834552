import { useEffect, useState } from "react"
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import Pagination from "../Pagination";
import AdTemplateForAdmin from "../mypage/AdTemplateForAdmin";
import { useAuth } from "../../hooks/useAuth";
import CreateAdFor from "../modals/admin/CreateAdFor";

const AdminAds = () => {
    const [ads, setAds] = useState()
    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1)

    const cm = useCommon()
    const api = useApi()
    const auth = useAuth()
    const alert = useAlert()

    const getAdsFromApi = async (params = {}) => {
        try {
            cm.showLoader(true)

            const res = await api.getAds(params)
            if (res) {
                setAds(res);
                cm.showLoader(false)
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false)
        }
    };


    const handleSearch = (e) => {
        setSearch(e.target.value)
        if (e.key === 'Enter') {
            getAdsFromApi({ search: e.target.value, admin: auth?.authUser?.alias_id });
        }
    }

    const handleSearchClicked = (e) => {
        getAdsFromApi({ search: search, admin: auth?.authUser?.alias_id });
    }

    const renderAds = () => {
        return ads?.results?.map((a, i) => {
            return <AdTemplateForAdmin key={"ad_" + a?.alias_id} ad={a} />
        }
        );
    };

    const onChangePage = (e) => {
        getAdsFromApi({ page: e.target.value });
        setCurrentPage(e.target.value)
    }



    useEffect(() => {
        getAdsFromApi({ admin: auth?.authUser?.alias_id })
    }, [])


    return <>
        <div className="mt-2 mt-md-0 mb-3 d-flex flex-row flex-wrap justify-content-between align-items-center rounded-2 bg-success bg-opacity-10 border border-success border-opacity-25 p-2 ">
            <div className="input-group w-auto mb-1 mb-mt-0">
                <input type="text" name="search" className="form-control w-auto font-size-11" placeholder={cm?.translate("Type keyword")} onKeyUp={e => handleSearch(e)} />
                <button className="btn btn-sm btn-success rounded-end" title={cm?.translate("Search")} onClick={e => handleSearchClicked(e)}>{cm?.translate("Search")}</button>
            </div>
            <CreateAdFor redirectPath="/admin?page=ads" />
        </div>
        <div className="">
            {renderAds()}
        </div>
        <div className="mt-4 mb-5">
            <Pagination totalItems={ads?.count} onChangePage={onChangePage} currentPage={currentPage} />
        </div>
    </>


}
export default AdminAds