import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon"
import { LiaCoinsSolid } from "react-icons/lia";
import { Modal } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";

const BuyCoins = () => {
    const [show, setShow] = useState(false);
    const [coins, setCoins] = useState(20);

    const cm = useCommon();


    const handleClose = () => {
        setShow(false)
        setCoins(20)
    }


    useEffect(() => {
        coins < 20 && setCoins(20)
    }, [coins])

    const handleShow = () => {
        setShow(true);
    };

    return <>
        <button className="btn btn-sm btn-success border-0" onClick={handleShow}>{cm?.translate("Buy Coins")}</button>
        <Modal scrollable={true} show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title><LiaCoinsSolid className="fs-2" /> {cm?.translate("Buy Coins")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 text-center">
                <div className="d-flex align-items-center justify-content-center mb-4">
                    <input type="number" className="form-control w-25 fs-5" min={20} value={coins} onChange={e => setCoins(e.target.value)}></input>
                    <span className="ms-2 fs-4">{cm?.translate("coins")}</span>
                    <span className="ms-3 fs-4">=</span>
                    <span className="ms-3 fs-4 text-danger">{coins} {cm.translate("Taka")}</span>
                </div>
                <a href="https://wa.me/8801321747471" className="btn btn-outline-success border-0 mt-4 fs-5" target="__blank" rel="norefferer"><FaWhatsapp className="fs-3" /> {cm?.translate("Chat to buy coins")}</a>
                <p className="mt-3 fs-5 fw-semibold">{cm?.translate("or Call 01321747471")}</p>

            </Modal.Body>
            <Modal.Footer>
                {/* <Bkash quantity={coins} /> */}
                <button className="btn btn-sm btn-outline-dark border-0" onClick={handleClose}>{cm?.translate("Cancel")}</button>
            </Modal.Footer>
        </Modal >
    </>
}

export default BuyCoins