import { useEffect } from "react";
import { useCommon } from "../hooks/useCommon";
import { useApi } from "../hooks/useApi";

const Error = () => {
    const cm = useCommon();
    const api = useApi();

    useEffect(() => {
        if (!cm?.error) {
            window.location = "/"
        }

    }, [cm?.error])

    return (
        <div className="col-sm-12 col-md-8 mx-auto my-2 my-md-4 px-2 px-md-0">
            {
                cm?.error && cm?.error.includes("<!DOCTYPE html>") ? <div className="bg-success bg-opacity-10 border border-success border-opacity-50 rounded overflow-scroll p-2" dangerouslySetInnerHTML={{ __html: cm?.error }}></div> : <div className="bg-success bg-opacity-10 border border-success border-opacity-50 rounded p-4 my-4 text-danger fs-6">{cm?.error?.msg || cm?.error}</div>
            }
        </div >
    );
};

export default Error;