import { GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"
import { useState } from "react";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";

const QuickRegister = ({ navigateToStep, setNavigateToStep }) => {
    const [newUser, setNewUser] = useState({})
    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();

    const handleInput = (e) => {
        setNewUser({ ...newUser, [e.target.name]: e.target.value })
    }

    const handlePrev = () => {
        setNavigateToStep(navigateToStep - 1)
    }
    const handleSignIn = () => {
        setNavigateToStep(9)
    }


    const handleRegister = async (e) => {
        try {
            cm?.showLoader(true)
            const res = await api.quickRegister(newUser);

            if (res) {
                cm?.setOtpToken(res?.token)
                cm?.showLoader(false)
                cm?.setCreateAdData({ ...cm?.createAdDate, newUser: newUser })
                setNavigateToStep(10)
            }
        } catch (err) {
            cm?.showLoader(false)
            if (err?.response?.status === 409 || err?.response?.data?.msg?.startsWith("Already")) {
                alert.show(cm?.translate("You might already be registered! Sign in to continue."), "error");
                handleSignIn();
            } else {
                alert.show(err?.response?.data?.msg || err, "error");
            }
        }
    };




    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Identify Yourself")}</h4>
        <div className="m-0 mt-3 my-5 px-3 text-center">
            <p className="mb-4 font-size-9 text-start d-block">{cm?.translate("To post an ad, we need your identification. If you're already a member, please sign in. Otherwise, quickly register using your email or phone number used in Bangladesh.")}</p>
            <div className="w-sm-100-md-50 mx-auto">
                <input className="form-control" name="name" placeholder={cm?.translate("Name")} onChange={e => handleInput(e)}></input>
                <input className="form-control mt-2" name="email_or_phone" placeholder={cm?.translate("Email or Phone")} onChange={e => handleInput(e)}></input>
            </div>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button disabled={!newUser?.email_or_phone || !newUser?.name} className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleRegister}>{cm?.translate("Get OTP")}</button>
            <button className="btn btn-light w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleSignIn}>{cm?.translate("Sign In")}</button>
        </div>
    </div>

}
export default QuickRegister 