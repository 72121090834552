import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCommon } from "../../hooks/useCommon"
import { decodeSearchParams } from "../../utils/utils";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import GeneralProfile from "./GeneralProfile";
import BusinessProfile from "./BusinessProfile";
import Settings from "./Settings";
import MyAds from "./MyAds";
import Orders from "./Orders";
import JobApplications from "./JobApplications";
import InquiryPage from "./InquiryPage";
import Disclaimer from "../modals/Disclaimer";


const MyPage = () => {
    const [searchParams] = useSearchParams()
    const [selectedMenu, setSelectedMenu] = useState("")
    const navigate = useNavigate();

    const cm = useCommon();
    const auth = useAuth();

    useEffect(() => {

    }, [])

    useEffect(() => {
        const params = decodeSearchParams(searchParams)
        setSelectedMenu(params?.page)
    }, [searchParams])

    const logout = () => {
        auth.logout();
        window.location = "/login";
    }


    const renderMyPage = () => {
        const params = decodeSearchParams(searchParams);
        switch (params?.page) {
            case "general":
                return <GeneralProfile />
            case "business":
                return <BusinessProfile />
            case "settings":
                return <Settings />
            case "ads":
                return <MyAds />
            case "orders":
                return <Orders />
            case "jobs":
                return <JobApplications />
            case "inquiries":
                return <InquiryPage />
            default:
                return <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: 100 }}>
                    <h3 className="text-success">{cm?.translate(auth?.authUser?.user_profile?.name || "Member" + "'s Page")}</h3>
                    <p>{cm?.translate("Navigate to menu")}</p>
                    {auth.authUser && <button onClick={logout} className="btn btn-sm btn-white">{cm?.translate("Logout")}</button>}
                </div>
        }
    }

    const getSelectedPage = () => {
        const params = decodeSearchParams(searchParams);

        if (params?.page) {
            switch (params?.page) {
                case "general":
                    return cm?.translate("General Profile")
                case "business":
                    return cm?.translate("Business Profile")
                case "settings":
                    return cm?.translate("Settings")
                case "ads":
                    return cm?.translate("My Ads.")
                case "orders":
                    return cm?.translate("Orders")
                case "jobs":
                    return cm?.translate("Jobs")
                case "inquiries":
                    return cm?.translate("Inquiries")
                default:
                    return cm?.translate("My Page")
            }

        } else {
            return cm?.translate("My Page Menu")
        }

    }

    const handleMenuClick = (page) => {
        navigate("/mypage/?page=" + page);
    }


    return <div className={"col-sm-12 col-md-10 mx-auto my-2 my-md-4 p-2 d-print-none"}>
        <div className="row m-0 gx-4">
            <div className="d-none d-md-block col-sm-12 col-md-3 bg-light p-3">
                <button onClick={() => navigate("/mypage")} className={(!selectedMenu && "fw-bold ") + " btn text-start w-100 rounded"}>{cm?.translate("My Page")}</button>
                <button onClick={e => handleMenuClick("general")} className={(selectedMenu === "general" && "fw-bold ") + " btn text-start w-100 rounded"}>{cm?.translate("General Profile")}</button>
                <button onClick={e => handleMenuClick("business")} className={(selectedMenu === "business" && "fw-bold ") + " btn text-start w-100 rounded"}>{cm?.translate("Business Profile")}</button>
                <button onClick={e => handleMenuClick("settings")} className={(selectedMenu === "settings" && "fw-bold ") + " btn text-start w-100 rounded"}>{cm?.translate("Settings")}</button>
                <button onClick={e => handleMenuClick("ads")} className={(selectedMenu === "ads" && "fw-bold ") + " btn text-start w-100 rounded"}>{cm?.translate("My Ads.")}</button>
                <button onClick={e => handleMenuClick("orders")} className={(selectedMenu === "orders" && "fw-bold ") + " btn text-start w-100 rounded"}>{cm?.translate("Orders")}</button>
                <button onClick={e => handleMenuClick("jobs")} className={(selectedMenu === "jobs" && "fw-bold") + " btn text-start w-100 rounded"}>{cm?.translate("Jobs")}</button>
                <button onClick={e => handleMenuClick("inquiries")} className={(selectedMenu === "inquiries" && "fw-bold ") + " btn text-start w-100 rounded"}>{cm?.translate("Inquiries")}</button>
                {auth.authUser?.is_superuser && <button onClick={e => navigate("/admin")} className={"btn text-start w-100 rounded"}>{cm?.translate("Admin")}</button>}
            </div>
            <div className="d-block d-md-none dropdown mb-3">
                <button className="btn btn-outline-success rounded dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {getSelectedPage()}
                </button>
                <ul className="dropdown-menu">
                    <li><Link to={"?page=general"} className={"dropdown-item"}>{cm?.translate("General Profile")}</Link></li>
                    <li><Link to={"?page=business"} className={"dropdown-item"}>{cm?.translate("Business Profile")}</Link></li>
                    <li><Link to={"?page=settings"} className={"dropdown-item"}>{cm?.translate("Settings")}</Link></li>
                    <li><Link to={"?page=ads"} className={"dropdown-item"}>{cm?.translate("My Ads.")}</Link></li>
                    <li><Link to={"?page=orders"} className={"dropdown-item"}>{cm?.translate("Orders")}</Link></li>
                    <li><Link to={"?page=jobs"} className={"dropdown-item"}>{cm?.translate("Jobs")}</Link></li>
                    <li><Link to={"?page=inquiries"} className={"dropdown-item"}>{cm?.translate("Inquiries")}</Link></li>
                    {auth.authUser?.is_superuser && <li><Link to={"/admin"} className={"dropdown-item"}>{cm?.translate("Admin")}</Link></li>}
                </ul>
            </div>
            <div className="col-sm-12 col-md-9">
                {renderMyPage()}
            </div>
        </div>
        {!auth?.authUser?.user_settings?.disclaimer_agree && <Disclaimer />}
    </div >
}
export default MyPage