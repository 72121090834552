import { createContext, useContext, useState } from "react";

const InquiryContext = createContext({});

export const InquiryProvider = (props) => {
    const [inquiries, setInquiries] = useState();
    const [selectedInquiry, setSelectedInquiry] = useState();

    const value = { inquiries, setInquiries, selectedInquiry, setSelectedInquiry };

    return (
        <InquiryContext.Provider value={value}>
            {props.children}
        </InquiryContext.Provider>
    );
};
export const useInquiry = () => {
    return useContext(InquiryContext);
};