import { createContext, useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authUser, setAuthUser] = useLocalStorage("authUser", null)
    const [authToken, setAuthToken] = useLocalStorage("authToken", null)

    const login = async (data) => {
        setAuthUser(data?.user);
        setAuthToken(data?.access_token);
    };

    const logout = () => {
        setAuthUser(null);
        setAuthToken(null);
    };

    const updateAuthUser = (authUser) => {
        setAuthUser(authUser);
    }
    const updateAuthToken = (authToken) => {
        setAuthToken(authToken);
    }

    const value = {
        authUser,
        authToken,
        updateAuthUser,
        updateAuthToken,
        login,
        logout,
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;

}

export const useAuth = () => {
    return useContext(AuthContext);
};