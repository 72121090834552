import useAlert from "./useAlert";
import { useApi } from "./useApi";
import { useAuth } from "./useAuth";

const useRenewAuth = () => {
    const auth = useAuth();
    const api = useApi();
    const alert = useAlert();

    const renew = async () => {
        try {
            if (auth.authUser && auth.authToken) {
                const res = await api.renewAuth(auth.authUser?.email_or_phone);
                if (res) {
                    auth.login(res)
                }
            } else {
                window.location = "/login"
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    return { renew };
}
export default useRenewAuth