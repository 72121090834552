import { GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"

const CreateAdStepFinal = ({ navigateToStep, setNavigateToStep, handleSave }) => {
    const cm = useCommon();


    const handlePrev = () => {
        setNavigateToStep(navigateToStep)
    }


    const renderMediaFiles = () => {
        if (cm?.createAdData?.media?.length > 0) {
            return cm?.createAdData?.media?.map((m, i) => <img src={m?.base64} key={"img_" + i} alt="img" className="me-1" style={{ width: 72, height: 72, objectFit: "cover" }
            } />)
        } else {
            return "-"
        }
    }


    return <div className="text-center">
        <div className="m-0 mt-1 mb-5 px-3 text-start">
            <div className="row m-0 p-2 pb-3">
                <div className="col-sm-12">
                    <span className="fw-semibold">{cm?.translate("Category")}</span>
                    <div className="mt-2 d-flex">
                        {cm?.createAdData?.categoryGroup?.name} - {cm?.createAdData?.category?.name}
                    </div>
                </div>
            </div>
            <div className="row m-0 p-2 pb-3 bg-light">
                <div className="col-sm-12">
                    <span className="fw-semibold">{cm?.translate("Location")}</span>
                    <div className="mt-2 d-flex">
                        {cm?.createAdData?.location?.name}
                    </div>
                </div>
            </div>
            <div className="row m-0 p-2 pb-3">
                <div className="col-sm-12">
                    <span className="fw-semibold">{cm?.translate("Photos")}</span>
                    <div className="mt-2 d-flex">
                        {renderMediaFiles()}
                    </div>
                </div>
            </div>
            <div className="row m-0 p-2 pb-3 bg-light">
                <div className="col-sm-12">
                    <span className="fw-semibold">{cm?.translate("Title")}</span>
                    <div className="mt-2 d-flex">
                        {cm?.createAdData?.title}
                    </div>
                </div>
            </div>
            <div className="row m-0 p-2 pb-3">
                <div className="col-sm-12">
                    <span className="fw-semibold">{cm?.translate("Description")}</span>
                    <div className="mt-2 d-flex overflow-scroll" style={{ maxHeight: 200 }}>
                        <p dangerouslySetInnerHTML={{ __html: cm?.createAdData?.description.replaceAll("\n", "<br />") }}></p>
                    </div>
                </div>
            </div>
            {(cm?.createAdData?.categoryGroup?.name?.startsWith("Product") || cm?.createAdData?.categoryGroup?.name?.startsWith("Ticket")) && <>
                <div className="row m-0 p-2 pb-3 bg-light">
                    <div className="col-sm-12">
                        <span className="fw-semibold">{cm?.translate("Price")}</span>
                        <div className="mt-2 d-flex">
                            {cm?.createAdData?.pricing?.unitPrice ? cm?.createAdData?.pricing?.unitPrice + " " + cm?.createAdData?.pricing?.currency + " " + cm?.createAdData?.pricing?.servingUnit : "-"}
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-2 pb-3">
                    <div className="col-sm-12">
                        <span className="fw-semibold">{cm?.translate("Tax")}</span>
                        <div className="mt-2 d-flex">
                            {cm?.createAdData?.pricing?.tax ? cm?.createAdData?.pricing?.tax + "%" : "-"}
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-2 pb-3 bg-light">
                    <div className="col-sm-12">
                        <span className="fw-semibold">{cm?.translate("Delivery Charge")}</span>
                        <div className="mt-2 d-flex">
                            {cm?.createAdData?.pricing?.deliveryCharge ? cm?.createAdData?.pricing?.deliveryCharge + " " + cm?.createAdData?.pricing?.currency : "-"}
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-2 pb-3">
                    <div className="col-sm-12">
                        <span className="fw-semibold">{cm?.translate("Additional Instruction or Note")}</span>
                        <div className="mt-2 d-flex">
                            {cm?.createAdData?.pricing?.orderRelatedInfo ? <p dangerouslySetInnerHTML={{ __html: cm?.createAdData?.pricing?.orderRelatedInfo.replaceAll("\n", "<br />") }}></p> : "-"}
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-2 pb-3 bg-light">
                    <div className="col-sm-12">
                        <span className="fw-semibold">{cm?.translate("Notification to Email")}</span>
                        <div className="mt-2 d-flex">
                            {cm?.createAdData?.pricing?.notificationEmail ? cm?.createAdData?.pricing?.notificationEmail : "-"}
                        </div>
                    </div>
                </div>
            </>
            }
            <div className="row m-0 p-2 pb-3">
                <div className="col-sm-12">
                    <span className="fw-semibold">{cm?.translate("Privacy")}</span>
                    <div className="mt-2 d-flex">
                        {cm?.createAdData?.privacyType?.name}
                    </div>
                </div>
            </div>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Modify")}</button>
            <button className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleSave}>{cm?.translate("Post")}</button>
        </div>
    </div >
}
export default CreateAdStepFinal