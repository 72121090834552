import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import { Modal } from "react-bootstrap";
import useRenewAuth from "../../hooks/useRenewAuth";

const Disclaimer = () => {
    const [show, setShow] = useState()
    const [disclaimer, setDisclaimer] = useState({})
    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();
    const renew = useRenewAuth()


    const getDisclaimerFromApi = async () => {
        try {
            cm.showLoader(true);
            const res = await api.getStaticContent("disclaimer");
            if (res) {
                setDisclaimer(res);
                cm.showLoader(false);
            }
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg, "error");
        }
    }

    useEffect(() => {
        getDisclaimerFromApi()
        setShow(true);
    }, []);

    const handleAgree = async () => {
        try {
            const res = await api.updateUserSettings(auth.authUser?.alias_id, { "disclaimer_agree": true });
            if (res) {
                renew.renew()
                closeModal()
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        }
    }
    const handleDontAgree = async () => {
        auth.logout()
        // window.location = "/login";
    }

    const closeModal = () => {
        setShow(false)
        setDisclaimer("")
    }

    return (
        <Modal size="lg" backdrop="static" scrollable="true" show={show} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Disclaimer")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-scroll">
                <p dangerouslySetInnerHTML={{ __html: disclaimer?.en }} style={{ textAlign: "justify" }}></p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-sm btn-outline-dark " onClick={handleDontAgree}
                >
                    {cm?.translate("Don't Agree")}
                </button>

                <button
                    className="btn btn-sm btn-outline-success"
                    onClick={handleAgree}
                >
                    {cm?.translate("Agree")}
                </button>

            </Modal.Footer>
        </Modal >
    );
};
export default Disclaimer;
