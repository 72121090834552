import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import moment from "moment";
import { GrDocumentText } from "react-icons/gr";
import SendMail from "./SendMail";

const ApplicantApplication = ({ application, handleClose }) => {
    const [show, setShow] = useState(false);
    const cm = useCommon();
    const auth = useAuth();

    const handleCloseBtn = () => {
        setShow(false);
        handleClose();
    };

    useEffect(() => {
        setShow(true)
    }, [])


    const getAge = (date) => {
        const d = moment(date).fromNow().split(" ");
        return d[0] + " " + d[1];
    }

    return (
        <Modal size="lg" scrollable="true" show={show} onHide={handleCloseBtn} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Application")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-size-11">
                <h6 className="border-bottom mb-2">{cm?.translate("Personal Information")}</h6>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Photo")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        {application?.photo && <img src={application?.photo} height={100} width={100} alt="candidate_photo" className="object-fit-contain" />}
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Name")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.first_name} {application?.last_name}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Birth Date")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.dob} ({getAge(application?.dob)})</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Email")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.email}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Phone")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.phone}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Address")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.address}</span>
                    </div>
                </div>
                <h6 className="mt-4 border-bottom mb-2">{cm?.translate("Professional Information")}</h6>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Experience")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.duration_of_experience}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Expertise")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.expertise}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Current Employer")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.current_employer}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Current Employer Address")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.current_employer_address}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Current Role")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.current_role}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Current Salary")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.current_salary}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Expected Salary")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.expected_salary}</span>
                    </div>
                </div>
                <h6 className="mt-4 border-bottom mb-2">{cm?.translate("Appeal")}</h6>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Message")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <span>{application?.appealing_message}</span>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-1  position-relative"}>
                        {cm?.translate("Resume")}
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <a download={application?.resume?.includes("data:application/pdf") ? "resume.pdf" : "resume.doc"} href={application?.resume} className="text-decoration-none d-flex align-items-center text-dark"><GrDocumentText className="fs-5 me-1" /> {cm?.translate("Resume")}</a>
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer className="d-print-none">

                <button
                    className="btn btn-sm btn-outline-dark " onClick={handleCloseBtn}
                >
                    {cm?.translate("Close")}
                </button>
                <button
                    className="btn btn-sm btn-success"
                    onClick={(e) => window.print()}
                >
                    {cm?.translate("Print")}
                </button>
                <SendMail sender={auth?.authUser?.email_or_phone} receiver={application?.email} subject={"Job application status of " + application?.ad?.title || ""} />

            </Modal.Footer>
        </Modal >
    );
}
export default ApplicantApplication