import { GrNext, GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"
import ImageUploader from "../ImageUploader";
import { useEffect, useState } from "react";

const CreateAdStepFour = ({ navigateToStep, setNavigateToStep }) => {
    const [mediaFiles, setMediaFiles] = useState();
    const cm = useCommon();

    useEffect(() => {
        if (!mediaFiles && cm?.createAdData?.media) {
            setMediaFiles(cm?.createAdData?.media)
        } else {
            setMediaFiles([])
        }
    }, [])

    useEffect(() => {
        cm?.setCreateAdData({
            ...cm?.createAdData, media: mediaFiles?.slice(0, 10)
        })
    }, [mediaFiles])

    const handleNext = () => {
        setNavigateToStep(navigateToStep + 1)
    }

    const handlePrev = () => {
        setNavigateToStep(navigateToStep - 1)
    }

    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Upload Photos")}</h4>
        <div className="m-0 mt-4 mb-5 px-3">
            <p className="mb-4 font-size-9 text-center d-block">{cm?.translate("Users can upload up to 10 photos per ad, with a total size limit of 10MB for all photos.")}</p>
            <div>
                <ImageUploader setNewFiles={setMediaFiles} existingMediaFiles={cm?.createAdData?.media?.length > 0 && cm?.createAdData?.media} label={cm?.translate("Select")} width="auto" maxSize={10000000} className="" />
            </div>
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleNext}>{cm?.translate("Next")} <GrNext className="fs-6 ms-2" /></button>
        </div>
    </div>
}
export default CreateAdStepFour