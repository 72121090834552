import { GrNext, GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";

const CreateAdStepThree = ({ navigateToStep, setNavigateToStep }) => {
    const cm = useCommon();
    const auth = useAuth();

    const handleLocationClick = (l) => {
        cm?.setCreateAdData({
            ...cm?.createAdData, location: cm?.locations?.find(fl => fl?.id === parseInt(l))
        })
    }

    const handleNext = () => {
        setNavigateToStep(navigateToStep + 1)
    }

    const handlePrev = () => {
        setNavigateToStep(navigateToStep - 1)
    }

    const renderLocation = () => {
        return <select className="form-select w-sm-100-md-50 font-size-12" defaultValue={cm?.createAdData?.location?.id || auth?.user_business_profile?.location?.id || cm?.locations?.find(fl => fl?.name === "Dhaka")?.id} onChange={(e) => handleLocationClick(e?.target?.value)}>{
            cm?.locations?.map((l, ix) => <option key={`loc-${l?.id}`} value={l?.id}>
                {cm?.translate(l?.name)}
            </option >
            )
        }
        </select>
    }

    useEffect(() => {
        cm?.setCreateAdData({ ...cm?.createAdData, location: cm?.createAdData?.location || auth?.user_business_profile?.locationd || cm?.locations?.find(fl => fl?.name === "Dhaka") })
    }, [])

    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Location")}</h4>
        <div className="m-0 my-5 px-3 d-flex flex-row flex-wrap justify-content-center">
            {renderLocation()}
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleNext}>{cm?.translate("Next")} <GrNext className="fs-6 ms-2" /></button>
        </div>
    </div>
}
export default CreateAdStepThree