import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert";
import { useCommon } from "../../hooks/useCommon";
import AdOwner from "../admin/AdOwner";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import SetToTopAd from "../modals/admin/SetToTopAd";
import Swal from "sweetalert2";


const AdTemplateForAdmin = ({ ad }) => {
    const [adPost, setAdPost] = useState()

    const api = useApi();
    const alert = useAlert();
    const cm = useCommon();


    useEffect(() => {
        setAdPost(ad)
    }, [])

    const renderMediaFiles = () => {
        if (ad?.media.length > 0) {
            return ad?.media?.map((m, i) => <img src={process.env.REACT_APP_MEDIA_URL + (m?.media_url_resized100x100 || m?.media_url_resized || m?.media_url)} key={"img_" + ad?.alias_id + "_" + i} alt="img" className="me-1" style={{ width: 36, height: 36, objectFit: "cover" }} />)
        } else {
            return <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" key={"img_" + ad?.alias_id} alt="no_img" className="me-1 ratio ratio-1x1" style={{ width: 36, height: 36, objectFit: "contain" }} />
        }
    }

    const handleToggleApproval = async () => {
        try {
            cm.showLoader(true)
            const res = await api.toggleAdApproval(adPost?.alias_id)
            if (res) {
                setAdPost(res)
                alert.toast("Approval Updated", "success")
            }
            cm.showLoader(false)
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false)
        }
    }

    const handleDelete = () => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await api.deleteAd(ad?.alias_id);
                    if (res) {
                        alert.showThenRedirect(cm?.translate("Ad. was deleted"), "success", "/admin?page=ads");
                    }
                } catch (err) {
                    alert.show(err, "error")
                }
            }
        });
    }



    return (
        <div
            className="col-sm-12 mb-3 border border-2"
        >
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Alias Id")}</div>
                <div className="col bg-white p-2 text-break"><a href={"/ads/" + adPost?.alias_id} target="_blank" rel="noreferrer" className="link-success text-decoration-none">{adPost?.alias_id}</a></div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Photos")}</div>
                <div className="col bg-white p-2">
                    {renderMediaFiles()}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Category")}</div>
                <div className="col bg-white p-2">
                    {adPost?.category?.name}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Title")}</div>
                <div className="col bg-white p-2">
                    {adPost?.title}
                </div>
            </div>
            {/* <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Description")}</div>
                <div className="col bg-white p-2">
                    <p className="overflow-scroll mb-0" style={{ maxHeight: 300 }} dangerouslySetInnerHTML={{ __html: adPost?.description }}></p>
                </div>
            </div> */}
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Created on")}</div>
                <div className="col bg-white p-2">
                    {moment(adPost?.created).format("YYYY-MM-DD")}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Expire on")}</div>
                <div className="col bg-white p-2">
                    {moment(adPost?.expire).format("YYYY-MM-DD")}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Approval")}</div>
                <div className="col bg-white p-2">
                    {adPost?.is_approved ? <FaCheck className="text-success" /> : <MdClose className="text-danger fs-5" />}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Top Ad.")}</div>
                <div className="col bg-white p-2">
                    {adPost?.top_ads?.expire ? <><FaCheck className="text-success me-2" /> {"Expire on " + moment(adPost?.top_ads?.expire).format("YYYY-MM-DD HH:mm")}</> : "Not Applied"}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Ad. Owner")}</div>
                <div className="col bg-white p-2">
                    <AdOwner post={adPost} />
                </div>
            </div>

            <div className="bg-light p-2 d-flex flex-row flex-wrap justify-content-end rounded-bottom">
                {<SetToTopAd post={adPost} setPost={setAdPost} />}
                {adPost?.is_approved && <button className="btn btn-sm btn-outline-dark ms-2" onClick={handleToggleApproval}>Revoke Approval</button>}
                {!adPost?.is_approved && <button className="btn btn-sm btn-outline-dark ms-2" onClick={handleToggleApproval}>Approve</button>}
                {<button className="btn btn-sm btn-outline-dark ms-2" onClick={handleDelete}>Delete</button>}

            </div>

        </div>
    );
};
export default AdTemplateForAdmin;
