import { useEffect, useState } from "react"
import { useApi } from "../hooks/useApi";
import useAlert from "../hooks/useAlert";
import { decodeSearchParams } from "../utils/utils";
import { useSearchParams } from "react-router-dom";
import { useCommon } from "../hooks/useCommon";
import PreviewHtml from "./modals/PreviewHtml";

const Faq = () => {
    const [searchParams] = useSearchParams();
    const [faqs, setFaqs] = useState([]);
    const [backupFaqs, setBackupFaqs] = useState([]);
    const [selectedFaq, setSelectedFaq] = useState(null);
    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();

    const getFaqsFromApi = async (params = {}) => {
        try {
            cm?.showLoader(true);
            const res = await api.getFaqs(params);
            if (res) {
                setBackupFaqs(res);
                setFaqs(res?.results);
                cm?.showLoader(false);
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err?.response?.data?.detail || err?.response?.statusText, "error");
            cm?.showLoader(false);
        }
    }

    useEffect(() => {
        getFaqsFromApi()
    }, [])


    useEffect(() => {
        const params = decodeSearchParams(searchParams)
        if ("id" in params) {
            const filteredFaqs = faqs?.find(f => parseInt(f.id) === parseInt(params?.id))
            setSelectedFaq(filteredFaqs)
        }
    }, [faqs]);

    const renderFaqs = () => {
        return faqs?.map((f, i) => <button key={"faq_" + f?.id} className="btn btn-light mb-1 w-100 text-start py-2" onClick={e => setSelectedFaq(f)}>
            {cm?.language === "bn" ? f?.question_bn : cm?.language === "jp" ? f?.question_jp : f?.question_en}
        </button>)
    }

    const handleSearch = (e) => {
        if (backupFaqs?.count > 0) {
            if (e.target.value) {
                const filteredFaqs = faqs.filter(f => f?.question_en?.includes(e.target.value) || f?.question_bn?.includes(e.target.value) || f?.question_jp?.includes(e.target.value))
                setFaqs(filteredFaqs)
            } else {
                setFaqs(backupFaqs?.results)
            }
        }
    }


    return <div className={"col-sm-12 col-md-10 mx-auto my-2 my-md-4 px-2 px-md-0"}>
        <div className="mt-3 border border-success border-opacity-25 rounded p-4">
            <h4 className="mb-3">{cm?.translate("Frequently Asked Questions")}</h4>
            <div className="mb-4">
                <input name="search" className="form-control" placeholder={cm?.translate("Type keywords to search")} onKeyUp={e => handleSearch(e)}></input>
            </div>
            {renderFaqs()}
            {selectedFaq && <PreviewHtml title={cm?.language === "bn" ? selectedFaq?.question_bn : cm?.language === "jp" ? selectedFaq?.question_bn : selectedFaq?.question_en} content={cm?.language === "bn" ? selectedFaq?.answer_bn : cm?.language === "jp" ? selectedFaq?.answer_jp : selectedFaq?.answer_en} setContent={setSelectedFaq} />}
        </div>
    </div>
}
export default Faq