import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import VerificationModal from "./VerificationModal";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert"
import { useCommon } from "../../hooks/useCommon";

const Register = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [statement, setStatement] = useState("");
  const api = useApi();
  const alert = useAlert();
  const cm = useCommon();

  const [searchParams] = useSearchParams();

  const getStatement = () => {
    const sts = ["Where Sellers, Meet Buyers", "Effortless Ads, Seamless Sales", "Ad Locally, Grow Globally"]
    return sts[Math.floor(Math.random() * sts.length)]
  }

  useEffect(() => {
    setStatement(getStatement())
  }, [])

  useEffect(() => {
    const action = searchParams.get("action");
    const email_or_phone = searchParams.get("id");

    if (action === "verify-code") {
      setFormValues({ ...formValues, email_or_phone: email_or_phone })
      setShowModal(true)
    }
  }, [searchParams])

  const [formValues, setFormValues] = useState({
    email_or_phone: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value };

      const allFieldsFilled =
        updatedValues.email_or_phone &&
        updatedValues.password &&
        updatedValues.confirm_password;

      const isPasswordValid =
        updatedValues.password.length >= 6 &&
        /[a-zA-Z]/.test(updatedValues.password) &&
        /\d/.test(updatedValues.password);

      const passwordsMatch =
        updatedValues.password === updatedValues.confirm_password;
      setIsDisabled(!(allFieldsFilled && passwordsMatch && isPasswordValid));

      if (!passwordsMatch && updatedValues.confirm_password.length > 0) {
        setPasswordMismatch("Passwords do not match");
      } else {
        setPasswordMismatch("");
      }
      return updatedValues;
    });

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await api.register(formValues);
      if (res) {
        setShowModal(true);
      }

    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error");
    }

  };

  return (
    <div className="col-sm-12 col-md-9 mx-auto">
      <div
        className="row m-0 gx-5"
        style={{ paddingTop: "6em", paddingBottom: "6em" }}
      >
        <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
          <h1 className="mb-0 text-success text-center fw-bold">
            {cm?.translate("Register at Monozuna")}
          </h1>
          <p className="text-center">{cm?.translate(statement)}
          </p>
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-center">
          <div
            className="border rounded shadow-sm bg-light p-3"
            style={{ maxWidth: 500, minWidth: 350 }}
          >
            <form method="POST">
              <fieldset className="form-group">
                <div>
                  <input
                    onChange={handleChange}
                    placeholder={cm?.translate("Email or Phone")}
                    type="text"
                    name="email_or_phone"
                    autoFocus
                    autoCapitalize="none"
                    autoComplete="email_or_phone"
                    className="form-control"
                    required
                  />
                  <p className="font-size-9 text-secondary px-1 mt-1">{cm?.translate("Phone number of Bangladesh only")}</p>
                </div>


                <div>
                  <div className="input-group mt-3">
                    <input
                      onChange={handleChange}
                      placeholder={cm?.translate("Password")}
                      type={!isShowPassword ? "password" : "text"}
                      name="password"
                      autoComplete="password"
                      className="form-control border-end-0"
                      required
                    />
                    <div className="input-group-text bg-white">
                      {isShowPassword ? (
                        <BsEye
                          className="cursor-pointer"
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        />
                      ) : (
                        <BsEyeSlash
                          className="cursor-pointer"
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        />
                      )}
                    </div>
                  </div>

                  <p className="font-size-9 mt-1 text-secondary px-1">
                    * {cm?.translate("At least 6 characters long")}<br />
                    * {cm?.translate("Must contain both number and alphabet")}
                  </p>
                </div>

                <div>
                  <div className="input-group mt-2 ">
                    <input
                      onChange={handleChange}
                      placeholder={cm?.translate("Confirm Password")}
                      type={!isShowConfirmPassword ? "password" : "text"}
                      name="confirm_password"
                      className="form-control border-end-0"
                      required
                    />
                    <div className="input-group-text bg-white">
                      {isShowConfirmPassword ? (
                        <BsEye
                          className="cursor-pointer"
                          onClick={() =>
                            setIsShowConfirmPassword(!isShowConfirmPassword)
                          }
                        />
                      ) : (
                        <BsEyeSlash
                          className="cursor-pointer"
                          onClick={() =>
                            setIsShowConfirmPassword(!isShowConfirmPassword)
                          }
                        />
                      )}
                    </div>

                  </div>
                  <p className="text-danger font-size-9 px-1 mt-1">
                    {passwordMismatch}
                  </p>
                </div>
                <button
                  onClick={handleSubmit}
                  disabled={isDisabled}
                  type="submit"
                  className="btn btn-sm btn-success w-100 mt-4 font-size-14"
                >
                  <span
                    id="spinner"
                    className="spinner-border spinner-border-sm"
                    style={{ display: "none" }}
                  ></span>
                  <span>{cm?.translate("Send Verification Code")}</span>
                </button>

                <div className="mt-3 text-center border-top pt-3">
                  <Link
                    to="/login"
                    className="btn btn-sm btn-outline-dark  w-100"
                  >
                    {cm?.translate("Login")}
                  </Link>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <VerificationModal showModal={showModal} setShowModal={setShowModal} data={formValues} />
    </div >
  );
};

export default Register;
