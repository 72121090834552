import React from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import Loading from "./Loading";
import { useCommon } from "../hooks/useCommon";
import useIsScrolled from "../hooks/useIsScrolled";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import moment from "moment";
import CreateAd from "./mypage/CreateAd";

const Footer = () => {
    const cm = useCommon();
    const isScrolled = useIsScrolled();

    return (
        <>
            <div className={"col-sm-12 col-md-10 mx-auto text-center border-top d-print-none"} style={{ marginBottom: "6em" }}>
                <div className="d-flex flex-wrap justify-content-center align-items-center py-1 font-size-10 px-4 px-md-0">
                    <a href="/docs?title=about" className="text-decoration-none link-success">{cm?.translate("About")}</a>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <a href="/contact" className="text-decoration-none link-success">{cm?.translate("Contact")}</a>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <a href="/docs?title=terms" className="text-decoration-none link-success">{cm?.translate("Terms & Condition")}</a>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <a href="/docs?title=privacy_policy" className="text-decoration-none link-success">{cm?.translate("Privacy Policy")}</a>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <a href="/docs?title=news" className="text-decoration-none link-success">{cm?.translate("News")}</a>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <a href="/faqs" className="text-decoration-none link-success">{cm?.translate("FAQ")}</a>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <select name="language" className="border-0" value={cm?.language} onChange={(e) => cm?.setLanguage(e.target.value)}>
                        <option value="en">English</option>
                        <option value="bn">{cm?.translate("Bangla")}</option>
                    </select>
                </div>
                <span className="d-block mt-3 font-size-9">&#169; {moment().year()}. All rights reserved. Monozuna Incorporate</span>
            </div>
            <div className="sticky-bottom d-flex justify-content-end p-2">
                {isScrolled && <button className="btn btn-sm btn-danger bg-opacity-50 shadow-sm me-3 mb-3" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <MdOutlineKeyboardDoubleArrowUp className="fs-3" />
                </button>}
            </div>
            <div className={"fixed-bottom d-print-none d-md-none m-0 d-flex flex-row flex-wrap justify-content-center align-items-center p-1 border-top border-success border-opacity-50"} style={{ background: "#D7EADB" }} >
                <CreateAd />
            </div>

            <Loading option={cm.showLoading} />
        </>
    );
};
export default Footer;
