import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
    return (
        <>
            <Header />
            <div style={{ maxWidth: 1440, margin: "5.5em auto 0" }}>
                <Outlet />
            </div>
            <Footer />
        </>
    );
};
export default Layout;