import "./App.css";
import { Routes, Route } from "react-router-dom";
import Ads from "./components/Ads";
import AdDetail from "./components/AdDetail";
import UserAds from "./components/UserAds";
import Login from "./components/mypage/Login";
import Register from "./components/mypage/Register";
import MyAds from "./components/mypage/MyAds";
import Settings from "./components/mypage/Settings";
import InquiryPage from "./components/mypage/InquiryPage";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import Layout from "./components/Layout";
import { useApi } from "./hooks/useApi";
import { useEffect } from "react";
import StaticPage from "./components/StaticPage";
import Faq from "./components/Faq";
import { InquiryProvider } from "./hooks/useInquiry";
import { useCommon } from "./hooks/useCommon";
import Error from "./components/Error";
import useAlert from "./hooks/useAlert";
import Orders from "./components/mypage/Orders";
import JobApplications from "./components/mypage/JobApplications";
import Admin from "./components/admin/Admin";
import Contact from "./components/Contact";
import MyPage from "./components/mypage/MyPage";


function App() {
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();

    const checkDbChanges = async () => {
        try {
            const res = await api.getDbChanges();
            if (res) {
                if (res?.change_id !== cm?.dbChanges?.change_id) {
                    cm?.setDbChanges({ ...res, is_changed: true });
                } else {
                    cm?.setDbChanges({ ...cm?.dbChanges, is_changed: false });
                }
            }
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg, "error")
        }
    }

    const getCacheableData = async () => {
        try {
            const res = await api.getDictionary()
            if (res) {
                cm?.setDictionary(res);
            }
            const res2 = await api.getCategoryGroups()
            if (res2) {
                cm?.setCategoryGroups(res2);
            }

            const res3 = await api.getCategories()
            if (res3) {
                cm?.setCategories(res3);
            }

            const res4 = await api.getLocations()
            if (res4) {
                const sortedLocation = res4.sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                })
                cm.setLocations(sortedLocation);
            }
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg, "error")
        }
    }

    useEffect(() => {
        checkDbChanges();
        const interval = setInterval(() => {
            checkDbChanges();
        }, 1000 * 60 * 3)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (cm?.dbChanges?.is_changed || Object.keys(cm?.dbChanges).length === 0) {
            getCacheableData()
        }
    }, [cm?.dbChanges?.change_id])


    useEffect(() => {
        cm?.error && console.log("error occured", cm?.error)
        cm?.error && alert.toast(cm?.error?.msg || cm?.error, "error");
        cm?.error && cm.showLoader(false);
    }, [cm?.error])

    return (
        <div className="App">
            <Routes>
                <Route>
                    <Route element={<Layout />}>
                        <Route index element={<Ads />}></Route>
                        <Route exact path="/ads" element={<Ads />}></Route>
                        <Route
                            exact
                            path="/members/:aliasId"
                            element={<UserAds />}
                        ></Route>

                        <Route
                            exact
                            path="/ads/:aliasId"
                            element={<AdDetail />}
                        ></Route>
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="/register" element={<Register />}></Route>
                        <Route path="/docs" element={<StaticPage />}></Route>
                        <Route path="/faqs" element={<Faq />}></Route>
                        <Route path="/contact" element={<Contact />}></Route>
                        <Route path="/error" element={<Error />}></Route>
                    </Route>
                    <Route element={<ProtectedRoutes />}>
                        <Route
                            path="/mypage"
                            element={<MyPage />}
                        ></Route>
                        <Route
                            path="/mypage/settings"
                            element={<Settings />}
                        ></Route>
                        <Route
                            path="/mypage/ads"
                            element={<MyAds />}
                        ></Route>
                        <Route
                            path="/mypage/orders"
                            element={<Orders />}
                        ></Route>
                        <Route
                            path="/mypage/job-applications"
                            element={<JobApplications />}
                        ></Route>
                        <Route
                            path="/mypage/inquiries"
                            element={<InquiryProvider><InquiryPage /></InquiryProvider>}
                        ></Route>
                    </Route>
                    <Route element={<ProtectedRoutes />}>
                        <Route
                            path="/admin"
                            element={<Admin />}
                        ></Route>
                    </Route>
                </Route>
            </Routes>
        </div >
    );
}

export default App;
