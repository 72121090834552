import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import UserReaction from "./UserReaction";
import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import MemberThumb from "./MemberThumb";
import { useApi } from "../hooks/useApi";
import { useAuth } from "../hooks/useAuth";
import moment from "moment";
import useAlert from "../hooks/useAlert";
import { useCommon } from "../hooks/useCommon";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

const PostDetailBasicInfo = ({ post }) => {
    const [adPost, setAdPost] = useState()
    const api = useApi();
    const auth = useAuth();
    const alert = useAlert();
    const cm = useCommon();

    const handleFollow = async () => {
        if (auth.authUser) {
            try {
                const res = await api.updateUserFollower(post?.owner?.alias_id);
                if (res) {
                    setAdPost({ ...post, owner: res })
                }
            } catch (err) {
                alert.show(err, "error")
            }
        } else {
            alert.show("Login is required", "info")
        }
    }

    useEffect(() => {
        setAdPost(post)
    }, [post])

    const renderFollowUnfollowButton = () => {
        if (adPost?.owner !== auth.authUser) {
            if (adPost?.owner?.is_followed_by_auth_user === false) {
                return <><BsDot /><Link onClick={handleFollow} className="link-secondary text-decoration-none font-size-10">{cm?.translate("Follow")}</Link></>
            } else {
                return <><BsDot /><Link onClick={handleFollow} className="link-success text-decoration-none font-size-10 fw-semibold">{cm?.translate("Following")}</Link></>
            }
        }
    }

    return <div className="d-flex flex-row flex-wrap justify-content-between bg-white p-3 border-bottom postion-relative">
        <div className="d-flex">
            <MemberThumb user={adPost?.owner} size={100} />
            <div className="ms-2 flex-fill pe-2">
                <h4 className="ellipsis-1 mb-0 pb-1">{adPost?.title}</h4>
                <div className="d-flex flex-wrap align-items-center">
                    <Link to={"/ads/?category=" + adPost?.category?.name} className="text-decoration-none link-success font-size-11">{adPost?.category?.name}</Link>
                    <MdOutlineKeyboardArrowRight />
                    <Link to={"/ads/?location=" + (adPost?.location?.name || adPost?.owner?.user_business_profile?.location?.name)} className="text-decoration-none link-success font-size-11">{adPost?.location?.name || adPost?.owner?.user_business_profile?.location?.name}</Link>
                    <MdOutlineKeyboardArrowRight />
                    <span className="font-size-11">{moment(adPost?.created).fromNow()}</span>
                    <MdOutlineKeyboardArrowRight />
                    <span className="font-size-11">{cm?.translate("Ad by")} <Link to={"/members/" + adPost?.owner.alias_id} className="text-decoration-none link-success font-size-11">{adPost?.owner?.user_business_profile?.company_name || adPost?.owner?.user_profile?.name || cm?.translate("NAME NOT FOUND")}</Link></span>

                    {renderFollowUnfollowButton()}

                </div>
                <div className="mt-1 d-flex ">
                    <UserReaction post={post} size={"fs-5"} />
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-end align-items-start m-2">
            <FacebookShareButton
                url={`https://api.monozuna.com/ads/${adPost?.alias_id}/`}
                hashtag={adPost?.title}
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <i className="mx-1"></i>
            <TwitterShareButton
                url={`https://api.monozuna.com/ads/${adPost?.alias_id}/`}
                hashtag={adPost?.title}
            >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <i className="mx-1"></i>
            <LinkedinShareButton
                url={`https://api.monozuna.com/ads/${adPost?.alias_id}/`}
                hashtag={adPost?.title}
            >
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
        </div>
    </div >

};
export default PostDetailBasicInfo