import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import useAlert from "../../hooks/useAlert"
import { useCommon } from "../../hooks/useCommon";
import { GrDocumentText } from "react-icons/gr";

const ApplyToJob = ({ ad }) => {
    const [isValid, setIsValid] = useState(false);
    const [show, setShow] = useState(false);
    const [application, setApplication] = useState({})

    const [inputData, setInputData] = useState({
        first_name: "",
        last_name: "",
        dob: "",
        address: "",
        email: "",
        phone: "",
        duration_of_experience: "",
        expertise: "",
        current_employer: "",
        current_employer_address: "",
        current_salary: "",
        expected_salary: "",
        appealing_message: "",
        attached_resume: "",
        photo: "",
    });

    const cm = useCommon();
    const auth = useAuth();
    const api = useApi();
    const alert = useAlert();


    const toBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    const handleResumeUpload = (e) => {
        const fls = [...e.target.files];
        if (fls[0].size > 2097152) {
            alert.show(cm?.translate("File size should not more than 2MB"), "error");
            return;
        }
        fls.map(i => toBase64(i).then(res => {
            setInputData({ ...inputData, resume: res })
        }))
    }

    const handlePhoto = (e) => {
        const fls = [...e.target.files];
        if (fls[0].size > 1048576) {
            alert.show(cm?.translate("File size should not more than 1MB"), "error");
            return;
        }
        fls.map(i => toBase64(i).then(res => {
            setInputData({ ...inputData, photo: res })
        }))
    }

    const checkSaveingIsValid = () => {
        const allFieldsFilled =
            inputData.first_name &&
            inputData.last_name &&
            inputData.email &&
            inputData.phone &&
            inputData.dob &&
            inputData.resume &&
            inputData.appealing_message
        console.log(allFieldsFilled)

        if (allFieldsFilled) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevValues) => {
            const updatedValues = { ...prevValues, [name]: value };
            return updatedValues;
        });

    };

    useEffect(() => {
        Object.keys(ad).length && setInputData({ ...ad?.job_application })
    }, [ad])

    useEffect(() => {
        if (!Object.keys(ad?.job_application).length) {
            checkSaveingIsValid();
        }
    }, [inputData])


    const handleSave = async (e) => {
        try {
            cm.showLoader(true, "Sending..");

            const data = { ...inputData, ad_alias_id: ad?.alias_id }

            const res = await api.applyToJob(data);
            if (res) {
                alert.show("Your application was sent.", "success");
                setApplication(res);
                cm?.setRefreshAdInMyAds({ ad: ad?.alias_id })
                handleClose();
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error");
        }
    };


    const handleClose = () => {
        setShow(false);
        setIsValid(false)
        cm?.showLoader(false)
    };

    const handleShow = () => {
        setShow(true);
    };

    const renderApplyButton = () => {
        if (Object.keys(ad?.job_application).length > 0) {
            if (auth.authUser) {
                return <button
                    onClick={handleShow}
                    className="btn btn-sm btn-outline-success rounded-pill px-3 d-flex justify-content-center align-items-center"
                >
                    {cm?.translate("Already Applied")}
                </button>
            } else {
                return <a href="/login"
                    className="btn btn-sm btn-outline-success rounded-pill px-3 d-flex justify-content-center align-items-center"
                >
                    {cm?.translate("Login to apply")}
                </a>
            }
        } else {
            if (auth.authUser) {
                return <button
                    onClick={handleShow}
                    className="btn btn-sm btn-success rounded-pill px-3 d-flex justify-content-center align-items-center"
                >
                    {cm?.translate("Apply")}
                </button>
            } else {
                return <a href="/login"
                    className="btn btn-sm btn-success rounded-pill px-3 d-flex justify-content-center align-items-center"
                >
                    {cm?.translate("Login to apply")}
                </a>
            }

        }
    }


    return (
        <>
            {renderApplyButton()}
            <Modal size="lg" scrollable="true" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{cm?.translate("Apply for ")}{ad?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4 font-size-11">
                    <div className="row m-0 mb-1">
                        <div className={(inputData?.first_name && inputData?.last_name && "border-end border-2 ") + "col-sm-12 col-md-4 d-flex align-items-center bg-light py-3  position-relative"}>
                            {cm?.translate("Name")}
                            <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <input name="first_name" placeholder={cm?.translate("First")} className="form-control" onChange={handleChange} value={inputData?.first_name}></input>
                            <input name="last_name" placeholder={cm?.translate("Last")} className="form-control ms-2" onChange={handleChange} value={inputData?.last_name}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className={(inputData?.dob && "border-end border-2 ") + "col-sm-12 col-md-4  d-flex align-items-center bg-light py-3 position-relative"}>
                            {cm?.translate("Birth Date")}
                            <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                        </div>
                        <div className="col  d-flex align-items-center py-2">
                            <input name="dob" type="date" placeholder={cm?.translate("Birth Date")} className="form-control" onChange={handleChange} value={inputData?.dob}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className={(inputData?.email && "border-end border-2 ") + "col-sm-12 col-md-4  d-flex align-items-center bg-light py-3 position-relative"}>
                            {cm?.translate("Email")}
                            <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <input name="email" placeholder={cm?.translate("xyz@xyz.com")} className="form-control" onChange={handleChange} value={inputData?.email}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className={(inputData?.phone && "border-end border-2 ") + "col-sm-12 col-md-4  d-flex align-items-center bg-light py-3 position-relative"}>
                            {cm?.translate("Phone")}
                            <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <input name="phone" placeholder={cm?.translate("01xxxxxxxxx")} className="form-control" onChange={handleChange} value={inputData?.phone}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className={"col-sm-12 col-md-4 d-flex align-items-center bg-light py-3 position-relative"}>
                            {cm?.translate("Address")}
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <textarea name="address" placeholder={cm?.translate("Mailing address")} className="form-control" onChange={handleChange} value={inputData?.address || ""}></textarea>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className={"col-sm-12 col-md-4  d-flex align-items-center bg-light py-3"}>
                            {cm?.translate("Duration of Experience")}
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <input name="duration_of_experience" placeholder={cm?.translate("0 year 0 month")} className="form-control" onChange={handleChange} value={inputData?.duration_of_experience || ""}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className="col-sm-12 col-md-4  d-flex align-items-center bg-light py-3">
                            {cm?.translate("Expertise")}
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <input name="expertise" placeholder={cm?.translate("Skill you can do best")} className="form-control" onChange={handleChange} value={inputData?.expertise || ""}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className="col-sm-12 col-md-4  d-flex align-items-center bg-light py-3">
                            {cm?.translate("Current Employer")}
                        </div>
                        <div className="col  d-flex align-items-center py-2">
                            <input name="current_employer" placeholder={cm?.translate("ABC Co.")} className="form-control" onChange={handleChange} value={inputData?.current_employer || ""}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className="col-sm-12 col-md-4  d-flex align-items-center bg-light py-3">
                            {cm?.translate("Current Employer Address")}
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <input name="current_employer_address" placeholder={cm?.translate("Address where you work")} className="form-control" onChange={handleChange} value={inputData?.current_employer_address || ""}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className="col-sm-12 col-md-4  d-flex align-items-center bg-light py-3">
                            {cm?.translate("Current Role")}
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <textarea name="current_role" placeholder={cm?.translate("Responsibility in current employment")} className="form-control" onChange={handleChange} value={inputData?.current_role || ""}></textarea>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className="col-sm-12 col-md-4  d-flex align-items-center bg-light py-3">
                            {cm?.translate("Current Salary")}
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <input name="current_salary" placeholder={cm?.translate("00000 Taka")} className="form-control" onChange={handleChange} value={inputData?.current_salary || ""}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className="col-sm-12 col-md-4  d-flex align-items-center bg-light py-3">
                            {cm?.translate("Expected Salary")}
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <input name="expected_salary" placeholder={cm?.translate("00000 Taka")} className="form-control" onChange={handleChange} value={inputData?.expected_salary || ""}></input>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className={(inputData?.resume && "border-end border-2 ") + "col-sm-12 col-md-4  bg-light py-3 position-relative"}>
                            {cm?.translate("Appealing Message to Employer")}
                            <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                        </div>
                        <div className="col d-flex align-items-center py-2">
                            <textarea name="appealing_message" placeholder={cm?.translate("Why do you feel fit for the position?")} className="form-control" onChange={handleChange} value={inputData?.appealing_message || ""}></textarea>
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className={(inputData?.resume && "border-end border-2 ") + "col-sm-12 col-md-4  bg-light py-3 position-relative"}>
                            {cm?.translate("Resume")}
                            <span className="font-size-8 d-block">{cm?.translate("PDF, Doc size should less than 2MB")}</span>
                            <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                        </div>
                        <div className="col py-2 d-flex align-items-center">
                            {!ad?.job_application?.resume && <input name="resume" type="file" className="form-control" accept=".pdf,.doc,.docx" onChange={handleResumeUpload}></input>}
                            {ad?.job_application?.resume && <a className="font-size-9 d-block text-dark text-decoration-none" download={ad?.job_application?.resume?.includes("data:application/pdf") ? "resume.pdf" : "resume.doc"} href={ad?.job_application?.resume}><GrDocumentText className="fs-4" /> {cm?.translate("Resume was attached")}</a>}
                        </div>
                    </div>
                    <div className="row m-0 mb-1">
                        <div className="col-sm-12 col-md-4 bg-light py-3">
                            {cm?.translate("Photo")}
                            <span className="font-size-8 d-block">{cm?.translate("JPEG, PNG size should less than 1MB")}</span>
                        </div>
                        <div className="col py-2">
                            {!ad?.job_application?.photo && <input name="photo" type="file" className="form-control" accept=".png,.jpeg,.jpg" onChange={handlePhoto}></input>}
                            {ad?.job_application?.photo && <img src={ad?.job_application?.photo} height={72} width={72} alt="candidate_photo" className="object-fit-contain" />}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <div>
                        <span className="font-size-10">{cm?.translate("All required fields should fill up")}</span>
                    </div>
                    <div>

                        <button
                            className="btn btn-sm btn-outline-dark  me-2" onClick={handleClose}
                        >
                            {cm?.translate("Cancel")}
                        </button>
                        <button
                            disabled={!isValid}
                            onClick={handleSave}
                            className="btn btn-sm btn-outline-success"
                        >
                            {cm?.translate("Send")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ApplyToJob