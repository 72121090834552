import React from "react";
import { BsFillPersonLinesFill, BsPostcard } from "react-icons/bs";
import { BsFillGearFill } from "react-icons/bs";
import { BiSolidUserCircle } from "react-icons/bi";
import { TbMessageQuestion } from "react-icons/tb";
import { useAuth } from "../hooks/useAuth";
import { useCommon } from "../hooks/useCommon";

import { FaUserShield } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function MyPageMenu() {
    const auth = useAuth()
    const cm = useCommon()
    const navigate = useNavigate();

    const toIndex = () => {
        window.location = "/";
    };


    const logout = () => {
        auth.logout();
        window.location = "/login";
    }

    const getRenderProfileImage = () => {
        if (auth.authUser && auth.authUser?.user_profile?.profile_image) {
            return <img className="bg-light rounded-2 object-fit-cover" src={process.env.REACT_APP_MEDIA_URL + auth.authUser?.user_profile?.profile_image} alt="user" style={{ height: 42, width: 42 }} />
        } else {
            return <BiSolidUserCircle className="text-secondary" style={{ fontSize: 42 }} />
        }
    }
    const toMypage = () => {
        let path = "/mypage";
        navigate(path);
    };

    return (
        <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasMypageMenu"
            aria-labelledby="offcanvasMypageMenuLabel"
        >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasMypageMenuLabel">
                    {cm?.translate("Quick Menu")}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body ">
                {auth?.authUser &&
                    <button className="btn btn-white border-0 d-flex align-items-center w-100 px-2" onClick={toMypage}>
                        {getRenderProfileImage()}
                        <h5 className="text-center ms-2 mb-0">{auth.authUser?.user_business_profile?.company_name || auth.authUser?.user_profile?.name || cm?.translate("Name was not set")}</h5>
                    </button>
                }
                <div className="mt-2 d-flex justify-content-between">
                    {auth.authUser && <button onClick={logout} className="btn btn-sm btn-light w-100">{cm?.translate("Logout")}</button>}
                    {!auth.authUser && <button onClick={() => window.location = "/login"} className="btn btn-sm btn-outline-success w-100">{cm?.translate("Login")}</button>}
                    {!auth.authUser && <button onClick={() => window.location = "/register"} className="btn btn-sm btn-outline-success w-100 ms-1">{cm?.translate("Create Account")}</button>}

                </div>
                <div className="mt-4 d-block d-md-none">
                    <button
                        className="btn btn-white w-100 border-0 text-start"
                        onClick={toIndex}
                    >
                        <BsPostcard className="fs-3" />
                        <span className="ms-2 font-size-12">{cm?.translate("All Ads.")}</span>
                    </button>
                </div>

                <div className="d-flex align-items-center justify-content-between my-3 mt-4">
                    <select name="language" className="form-select" value={cm?.language} onChange={(e) => cm?.setLanguage(e.target.value)}>
                        <option value="en">English</option>
                        <option value="bn">{cm?.translate("Bangla")}</option>
                    </select>
                </div>
            </div>
        </div >
    );
}
export default MyPageMenu;
