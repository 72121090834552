import { useState } from "react";
import { Modal } from "react-bootstrap"
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";

const RenewPassword = ({ showModal, closeModal }) => {
    const [renewData, setRenewData] = useState({
        email_or_phone: "",
        recovery_code: "",
        new_password: "",
    });
    const [hasError, setHasError] = useState(true);
    const api = useApi();
    const alert = useAlert();

    const handleClose = () => {
        closeModal(false);
    }


    const handleInput = (e) => {
        const { name, value } = e.target;
        setRenewData((prevValues) => {
            const updatedValues = { ...prevValues, [name]: value };

            if (updatedValues.email_or_phone && updatedValues.recovery_code && updatedValues.new_password && updatedValues.new_password.length >= 6 &&
                /[a-zA-Z]/.test(updatedValues.new_password) &&
                /\d/.test(updatedValues.new_password)) {
                setHasError(false);
            } else {
                setHasError(true);
            }
            return updatedValues;
        });

    }

    const updatePassword = async () => {
        try {
            const res = await api.renewPassword(renewData);
            if (res) {
                handleClose(true);
                alert.show(res?.msg, "success");
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        }
    }

    return <>
        <Modal scrollable="true" show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Renew Password</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <input type="text" placeholder="Email or Phone" className="form-control" name="email_or_phone" onChange={(e) => handleInput(e)}></input>
                <input type="text" placeholder="Recovery Code" className="form-control mt-2" name="recovery_code" onChange={(e) => handleInput(e)}></input>
                <input type="password" placeholder="New Password" className="form-control mt-4" name="new_password" onChange={(e) => handleInput(e)}></input>
                <p className="font-size-9 mt-1 text-secondary px-1">
                    * At least 6 characters long<br />
                    * Must contain both number and alphabet
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-success" disabled={hasError} onClick={updatePassword}>Update</button>
                <button className="btn btn-sm btn-secondary" onClick={handleClose}>Cancel</button>
            </Modal.Footer>
        </Modal>
    </>
}
export default RenewPassword