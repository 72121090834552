import React from "react";
import UserReaction from "./UserReaction";
import PostBasicInfo from "./PostBasicInfo";
import PostTemplate2Media from "./PostTemplateMedia";
import MemberThumb from "./MemberThumb";

const PostTemplate = ({ post, fullWidth = false, hide = false, reactionIconDisable }) => {
    return (
        <div
            className={(!fullWidth ? "col-sm-12 col-md-6 col-lg-6 " : "col-sm-12 ")}
        >
            <div className="d-flex thumb-hover bg-white border border-secondary border-opacity-10 p-2"
                style={{ marginRight: -1, marginBottom: -1 }}
            >
                <PostTemplate2Media post={post} />
                <div className="d-fill bg-white bg-opacity-75 px-2 d-flex flex-column justify-content-between position-relative w-100">
                    <div>
                        <PostBasicInfo post={post} />
                        <div className="mt-1">
                            {!hide && <UserReaction post={post} disable={reactionIconDisable} />}
                        </div>
                    </div>
                    <span className="font-size-11 fw-semibold ellipsis-1">
                        {post?.pricing?.unit_price && post?.pricing?.unit_price + " " + post?.pricing?.currency}
                    </span>
                    {
                        post?.owner?.user_business_profile?.is_approved &&
                        <div className="position-absolute bottom-0 end-0">
                            <MemberThumb user={post?.owner} />
                        </div>
                    }
                </div>
            </div>
        </div >
    );
};
export default PostTemplate;
