import { Link, useSearchParams } from "react-router-dom";
import { useCommon } from "../../hooks/useCommon"
import { decodeSearchParams } from "../../utils/utils";
import AdminShorts from "./AdminShorts";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import AdminMembers from "./AdminMembers";
import AdminSendMail from "./AdminSendMail";
import AdminSendSms from "./AdminSendSms";
import AdminAds from "./AdminAds";
import AdminAutoUserCreate from "./AdminAutoUserCreate";


const Admin = () => {
    const [searchParams] = useSearchParams()
    const [selectedMenu, setSelectedMenu] = useState("")
    const cm = useCommon();
    const auth = useAuth();

    useEffect(() => {
        !auth?.authUser?.is_superuser && alert?.showThenRedirect(cm?.translate("You are not authorized!"), "error", "/login")

    }, [])

    useEffect(() => {
        const params = decodeSearchParams(searchParams)
        setSelectedMenu(params?.page)
    }, [searchParams])


    const renderAdminPage = () => {
        const params = decodeSearchParams(searchParams);
        switch (params?.page) {
            case "members":
                return <AdminMembers />
            case "ads":
                return <AdminAds />
            case "shorts":
                return <AdminShorts />
            case "send-mail":
                return <AdminSendMail />
            case "send-sms":
                return <AdminSendSms />
            case "create-auto-user":
                return <AdminAutoUserCreate />
            default:
                return <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 100 }}><h3 className="text-secondary text-opacity-50">Monozuna Admin</h3></div>
        }
    }


    return <div className={"col-sm-12 col-md-10 mx-auto my-2 my-md-4 p-2"}>
        <div className="row m-0 gx-4">
            <div className="col-sm-12 col-md-2 bg-light p-3 d-flex flex-wrap flex-row flex-md-column">
                <Link to={"?page=members"} className={(selectedMenu === "members" && "fw-semibold ") + "text-decoration-none btn btn-sm text-start"}>Members</Link>
                <Link to={"?page=ads"} className={(selectedMenu === "ads" && "fw-semibold ") + "text-decoration-none btn btn-sm text-start"}>Ads</Link>
                <Link to={"?page=shorts"} className={(selectedMenu === "shorts" && "fw-semibold ") + "text-decoration-none btn btn-sm text-start"}>Shorts</Link>
                <Link to={"?page=send-mail"} className={(selectedMenu === "send-mail" && "fw-semibold ") + "text-decoration-none btn btn-sm text-start"}>Send Mail</Link>
                <Link to={"?page=send-sms"} className={(selectedMenu === "send-sms" && "fw-semibold ") + "text-decoration-none btn btn-sm text-start"}>Send SMS</Link>
                <Link to={"?page=create-auto-user"} className={(selectedMenu === "create-auto-user" && "fw-semibold ") + "text-decoration-none btn btn-sm text-start"}>Create Auto User</Link>
            </div>
            <div className="col-sm-12 col-md-10">
                {renderAdminPage()}
            </div>
        </div>
    </div>
}
export default Admin