import { useEffect, useState } from "react";
import moment from "moment";
import AdminModifyShortsOption from "../modals/admin/AdminModifyShortsOption";
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import Swal from "sweetalert2";
import ValuePill from "../ValuePill";
import { getYouTubeEmbededLink } from "../../utils/utils";


const AdminShorts = () => {
    const [shorts, setShorts] = useState([])
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();



    const getShorts = async (params = {}) => {
        try {
            cm.showLoader(true)
            const res = await api.getShorts(params)
            if (res) {
                setShorts(res);
                cm.showLoader(false)
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false)
        }
    };

    useEffect(() => {
        getShorts()
    }, [])

    const onUpdate = (sh) => {
        const exS = shorts?.results?.filter(s => parseInt(s?.id) !== parseInt(sh?.id))

        const newList = [sh, ...exS];
        setShorts({ ...shorts, results: newList });
        window.location = "/admin/?page=shorts"
    }

    const deletePermanently = async (e, id) => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    cm.showLoader(true, "Deleting..")
                    await api.deleteShortsByAdmin(id);
                    cm.showLoader(false)
                    window.location = "/admin/?page=shorts"
                } catch (err) {
                    alert.show(err, "error")
                }
            }
        });
    }

    const renderShortsOptions = () => {
        const res = shorts?.results?.map((s, ix) => <div key={"shorts_" + ix} className="col-sm-12 col-md-4 mb-3 d-flex flex-column">
            <div className="border rounded bg-white">
                <div className="">
                    {s?.youtube_link ? <div className={"ratio ratio-1x1"}><iframe src={getYouTubeEmbededLink(s?.youtube_link)} width={"100%"} title={s?.title}></iframe> </div> :
                        <video width="100%" controls>
                            <source src={process.env.REACT_APP_MEDIA_URL + s?.shorts} type="video/mp4"></source>
                        </video>
                    }
                </div>
                <div className="bg-white p-2 d-flex flex-column">
                    <p className="font-size-11"><a href={"/ads/" + s?.ad?.alias_id} target="_blank" rel="noreferrer" className="link-dark text-decoration-none">{s.title}</a></p>
                    <ValuePill title={"Created"} value={moment(s.created).format("YYYY-MM-DD HH:mm")} />
                    <ValuePill title={"Shorts Link"} value={s?.youtube_link?.length >= 1 ? <a href={s?.youtube_link} target="_blank" rel="noreferrer" >View</a> : "Not set"} />
                    <ValuePill title={"Status"} value={s.is_deleted ? "Requested for delete" : "Active"} titleColorclassName={s.is_deleted && "bg-warning bg-opacity-50"} />
                </div>
                <div className="bg-light p-2 d-flex flex-row justify-content-end rounded-bottom">
                    {s.is_deleted && <button disabled={s?.youtube_link} className="btn btn-sm btn-outline-dark  border" onClick={e => deletePermanently(e, s.id)}>Delete</button>}
                    <AdminModifyShortsOption shorts={s} onUpdate={onUpdate} />
                </div>
            </div>
        </div >)
        if (res?.length) {
            return res;
        } else {
            return "There is no shorts"
        }
    }

    return <div className="row m-0">
        {renderShortsOptions()}
    </div>
};
export default AdminShorts;
