import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PostDetailBasicInfo from "./PostDetailBasicInfo";
import MediaCollage from "./MediaCollage";
import SimilarAds from "./SimilarAds";
import Comments from "./Comments";
import { useApi } from "../hooks/useApi";
import { useCommon } from "../hooks/useCommon";
import useAlert from "../hooks/useAlert";
import Inquiry from "./modals/Inquiry";
import OrderNow from "./modals/OrderNow";
import PreviewShorts from "./modals/PreviewShorts";
import ApplyToJob from "./modals/ApplyToJob";

const AdDetail = () => {
    const { aliasId } = useParams();
    const [post, setPost] = useState();
    const [showFullPhoneNumber, setShowFullPhoneNumber] = useState(false)
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();


    const getAdDetailFromApi = async () => {
        try {
            cm.showLoader(true);
            const res = await api.getAdDetail(aliasId);
            cm.showLoader(false);
            setPost(res);
        } catch (err) {
            cm.showLoader(false);
            if (err?.response?.status === 404) {
                alert?.showThenRedirect("Requested Ad. was not found!", "error", "/ads")
            } else if (err?.response?.status === 403) {
                alert?.showThenRedirect("You are not allowed to view this Ad.", "error", "/ads")
            }
            else {
                alert.show(err, "error")
            }

        }
    };

    const injectMetaData = () => {
        let ogTitle = document.createElement("meta");
        ogTitle.setAttribute("property", "og:title")
        ogTitle.setAttribute("content", post?.title)
        document.head.appendChild(ogTitle)

        ogTitle.setAttribute("property", "twitter:title")
        ogTitle.setAttribute("content", post?.title)
        document.head.appendChild(ogTitle)


        let ogUrl = document.createElement("meta");
        ogUrl.setAttribute("property", "og:url")
        ogUrl.setAttribute("content", window.location.href)
        document.head.appendChild(ogUrl)

        let ogDescription = document.createElement("meta");
        ogDescription.setAttribute("property", "og:description")
        ogDescription.setAttribute("content", post?.owner?.user_business_profile?.company_name + ", " + post?.category?.group?.name + ", " + post?.category?.name)
        document.head.appendChild(ogDescription)

        if (post?.media?.length > 0) {
            let ogImage = document.createElement("meta");
            ogImage.setAttribute("property", "og:image")
            ogImage.setAttribute("content", process.env.REACT_APP_MEDIA_URL + post?.media[0]?.media_url)
            document.head.appendChild(ogImage)
        }
    }

    useEffect(() => {
        getAdDetailFromApi();
    }, [aliasId]);

    useEffect(() => {
        post && injectMetaData();
    }, [post]);

    return <div className="col-sm-12 col-md-10 mx-auto my-3 my-md-5 border border-secondary border-opacity-10 shadow-sm px-2 px-md-0">
        {/* <div className="d-flex justify-content-end px-3 py-2 rounded-top border-top border-1 border-success border-opacity-25" style={{ background: "linear-gradient(170deg,#ecf0ec,#ffffff 100%)" }}></div> */}
        <PostDetailBasicInfo post={post} setPost={setPost} />
        {post?.media.length > 0 && <MediaCollage media={post?.media} />}
        {!cm.showMediaCarousel && <>
            <div className="px-3 py-2 bg-light border-bottom d-flex justify-content-between">
                <div className="d-flex">
                    <Inquiry post={post} />
                    <button className="ms-2 btn btn-sm btn-outline-success rounded-pill px-3" onClick={e => setShowFullPhoneNumber(!showFullPhoneNumber)}>{showFullPhoneNumber ? post?.owner?.user_business_profile?.company_phone || post?.owner?.user_business_profile?.company_email || post?.owner?.user_profile?.email_or_phone : cm?.translate("Show Contact")}</button>
                </div>
                <div className="d-flex justify-content-end">
                    {post?.category?.group?.id === 2 && <ApplyToJob ad={post} />}
                    {post?.shorts_option?.shorts && <PreviewShorts btnTitle={<img src={process.env.PUBLIC_URL + "/static/shorts.png"} height={22} alt="shorts_logo" className="" />} shortsOption={post?.shorts_option} />}
                    {post?.pricing?.unit_price && <button className="ms-2 btn btn-sm btn-danger rounded-pill px-3 me-2" style={{ pointerEvents: "none", clipPath: "polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%)" }}>{cm?.translate("Price")} {cm?.translate(post?.pricing?.unit_price, true)} {cm?.translate(post?.pricing?.currency)}</button>}
                    {post?.pricing?.unit_price && <OrderNow post={post} />}
                </div>
            </div>
            <div className="p-3" dangerouslySetInnerHTML={{ __html: post?.description }} style={{ minHeight: 200 }}></div>
            <SimilarAds post={post} />
            <Comments post={post} setPost={setPost} />
        </>
        }
    </div >
}
export default AdDetail