import { useEffect, useState } from "react";
import { Form, Modal, Tab, Tabs } from "react-bootstrap";
import { BsPostcard } from "react-icons/bs";
import ImageUploader from "../../ImageUploader";
import { useApi } from "../../../hooks/useApi"
import { useAuth } from "../../../hooks/useAuth"
import { useCommon } from "../../../hooks/useCommon";
import useAlert from "../../../hooks/useAlert";

const CreateBusinessProfile = ({ member }) => {
    const [show, setShow] = useState(false);
    const [businessProfile, setBusinessProfile] = useState({})

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();

    useEffect(() => {
        setBusinessProfile({ ...businessProfile, alias_id: member?.alias_id })
    }, [show])

    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }

    const handleFormInput = (e) => {
        const { name, value } = e.target;

        if (name === "location") {
            const bp = {
                ...businessProfile,
                [name]: cm?.locations?.find((l) => l.id === parseInt(value)),
            };
            setBusinessProfile(bp)
        }
        else {
            setBusinessProfile({ ...businessProfile, [name]: value })
        }


    };

    const renderLocation = () => {
        return cm?.locations?.map((l) =>
            <option key={"loc_" + l.id} value={l.id}>{l.name}</option>
        )
    }

    const handleSave = async () => {
        try {
            cm?.showLoader(true)
            const res = await api.createBusinessProfile(businessProfile);
            if (res) {
                cm?.showLoader(false)
                alert.showThenRedirect("User business profile was created", "success", "/admin?page=members")
            }
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err?.msg || err, "error")
            return false
        }
    };

    return <>
        <button onClick={handleShow} className="btn btn-sm btn-outline-dark ms-1">
            {cm?.translate("Create Business Profile")}
        </button>

        <Modal size="lg" scrollable="true" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Create Business Profile")}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="p-4">

                <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Company Name")}</span>
                        <div className="mt-3 ">
                            <input
                                type="text"
                                name="company_name"
                                className="form-control"
                                value={businessProfile?.company_name || ""}
                                onChange={handleFormInput}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Company name or your personal name is required. This name will be visible to user's Ad. post.")}</p>
                    </div>
                </div>

                <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Address")}</span>
                        <div className="mt-3 ">
                            <textarea
                                type="text"
                                rows={2}
                                name="address"
                                value={businessProfile?.address || ""}
                                className="form-control"
                                onChange={handleFormInput}
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Correct address is important to reach user. It is required to write in detail.")}</p>
                    </div>
                </div>

                <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Post Code")}</span>
                        <div className="mt-3 ">
                            <input
                                type="text"
                                name="post_code"
                                className="form-control"
                                value={businessProfile?.post_code || ""}
                                onChange={handleFormInput}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Although it is optional but it will help the delivery quickly.")}</p>
                    </div>
                </div>

                <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Location")}</span>
                        <div className="mt-3 ">
                            <select
                                name="location"
                                className="form-select"
                                value={businessProfile?.location?.id || ""}
                                onChange={handleFormInput}
                            >
                                <option value="">{cm?.translate("Select Location")}</option>
                                {renderLocation()}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("It is required so that users' post can be sorted quickly and user may get preference Ads.")}</p>
                    </div>
                </div>

                <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Business Email")}</span>
                        <div className="mt-3 ">
                            <input
                                type="text"
                                name="company_email"
                                className="form-control"
                                value={businessProfile?.company_email || ""}
                                onChange={handleFormInput}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("It is optional but it will help user to communicate easily.")}</p>
                    </div>
                </div>

                <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Business Phone")}</span>
                        <div className="mt-3 ">
                            <input
                                type="text"
                                name="company_phone"
                                className="form-control"
                                value={businessProfile?.company_phone || ""}
                                onChange={handleFormInput}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("It is required and without valid phone number business account will not active.")}</p>
                    </div>
                </div>

                <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Website Link")}</span>
                        <div className="mt-3 ">
                            <input
                                type="text"
                                name="web_link"
                                className="form-control"
                                value={businessProfile?.web_link || ""}
                                onChange={handleFormInput}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("It is not required but people may reach to product and service easily.")}</p>
                    </div>
                </div>

            </Modal.Body>



            <Modal.Footer>
                <button className="btn btn-sm btn-outline-dark" onClick={handleClose}>{cm?.translate("Cancel")}</button>
                <button className="btn btn-sm btn-success" onClick={handleSave}>{cm.translate("Save")}</button>
            </Modal.Footer>
        </Modal >
    </>
}
export default CreateBusinessProfile;