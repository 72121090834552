import { createContext, useContext, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

const DataContext = createContext({});

export const DataProvider = (props) => {
    const [error, setError] = useState("");
    const [showLoading, setShowLoading] = useState({});
    const [showMediaCarousel, setShowMediaCarousel] = useState(false);
    const [changedInUser, setChangedInUser] = useState({})
    const [ads, setAds] = useState([])
    const [myAds, setMyAds] = useState([])
    const [refreshAdInMyAds, setRefreshAdInMyAds] = useState({})
    const [dbChanges, setDbChanges] = useLocalStorage("dbChanges", {})
    const [categories, setCategories] = useLocalStorage("categories", [])
    const [categoryGroups, setCategoryGroups] = useLocalStorage("categoryGroups", [])
    const [locations, setLocations] = useLocalStorage("locations", [])
    const [dictionary, setDictionary] = useLocalStorage("dictionary", {})
    const [language, setLanguage] = useLocalStorage("language", "en")
    const [createAdData, setCreateAdData] = useState({})
    const [otpToken, setOtpToken] = useLocalStorage("otpToken", "")

    const showLoader = (show, title = "Loading") => {
        setShowLoading({ show: show, title: title });
    }

    const translate = (word, lang = language || "en", is_number = false) => {
        if (is_number) {
            return [...String(word)].map(letter => dictionary?.[letter]?.[lang] || letter).join("")
        }
        const tWord = dictionary?.[word]?.[lang] || word
        return tWord
    }

    const value = { otpToken, setOtpToken, changedInUser, setChangedInUser, ads, setAds, myAds, setMyAds, refreshAdInMyAds, setRefreshAdInMyAds, createAdData, setCreateAdData, categories, setCategories, categoryGroups, setCategoryGroups, locations, setLocations, dictionary, setDictionary, translate, showLoading, showLoader, language, setLanguage, showMediaCarousel, setShowMediaCarousel, dbChanges, setDbChanges, error, setError };

    return (
        <DataContext.Provider value={value}>
            {props.children}
        </DataContext.Provider>
    );
};


export const useCommon = () => {
    return useContext(DataContext);
};