import { GrNext } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"

const CreateAdStepOne = ({ navigateToStep, setNavigateToStep }) => {
    const cm = useCommon();

    const handleGroupClick = (g) => {
        cm?.setCreateAdData({ categoryGroup: g })
    }

    const handleNext = () => {
        setNavigateToStep(navigateToStep + 1)
    }

    const renderCategoryGroup = () => {
        return cm?.categoryGroups?.map((g, ix) => {
            return <div key={`group-${g?.id}`} className="col-sm-6 col-md-3">
                <button className={(cm?.createAdData?.categoryGroup?.id === g?.id ? "btn btn-success " : "btn btn-outline-success") + " w-100 d-flex flex-column align-items-center h-100"} onClick={() => handleGroupClick(g)}>
                    <span className="fw-semibold d-block text-center text-break">{cm?.translate(g?.name)}</span>
                    <p className="font-size-8 my-2 d-block text-center">
                        {cm?.translate(g?.description)}
                    </p>
                </button>
            </div>
        })
    }

    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Category")}</h4>
        <div className="row g-1 m-0 my-5 px-3">
            {renderCategoryGroup()}
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button disabled={!cm?.createAdData?.categoryGroup} className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleNext}>{cm?.translate("Next")} <GrNext className="fs-6 ms-2" />
            </button>
        </div>
    </div>
}
export default CreateAdStepOne