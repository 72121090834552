import { useEffect, useState } from "react";
import ChangePassword from "../modals/ChangePassword";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import { BsGrid, BsGrid3X2 } from "react-icons/bs";


const Settings = () => {
    const [userSettings, setUserSettings] = useState()
    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();


    const handleSetting = async (e) => {
        try {
            const { name, value } = e.target;

            setUserSettings({ ...userSettings, [name]: value === "true" ? false : true })
            const data = { [name]: value === "true" ? false : true }

            const res = await api.updateUserSettings(auth.authUser?.alias_id, data);

            if (res) {
                setUserSettings(res);
                auth.updateAuthUser({ ...auth.authUser, user_settings: res })
            }

            if (name === "browser_notification" && value === "false") {
                Notification.requestPermission().then(perm => console.log(perm))
            }

        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        }
    }

    const handleAdListing = async (value) => {
        try {
            setUserSettings({ ...userSettings, ads_listing_style: value })
            const data = { ads_listing_style: value }

            const res = await api.updateUserSettings(auth.authUser?.alias_id, data);

            if (res) {
                setUserSettings(res);
                auth.updateAuthUser({ ...auth.authUser, user_settings: res })
            }

        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        }
    }

    useEffect(() => {
        setUserSettings(auth.authUser?.user_settings)
    }, [auth.authUser]);

    return (
        <div
            className={""}
        >
            <div className="row m-0 p-2 pt-3 pb-4 border-secondary border-opacity-10">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Show business phone number in your Ad.")}</span>
                    <div className="mt-3">
                        <input name="show_phone_number" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.show_phone_number} value={userSettings?.show_phone_number}></input>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9">{cm?.translate("Check or uncheck to show or hide user business phone number in Ad.")}</p>
                </div>
            </div>

            <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Get updates from Monozuna")}</span>
                    <div className="mt-3">
                        <input name="mail_notification" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.mail_notification} value={userSettings?.mail_notification}></input>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9">{cm?.translate("Check or uncheck to get or refrain from getting Monozuna update mail or sms.")}</p>
                </div>
            </div>

            <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("New Ad. notification of your following sellers")}</span>
                    <div className="mt-3">
                        <input name="new_ad_notification" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.new_ad_notification} value={userSettings?.new_ad_notification}></input>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9">{cm?.translate("Check or uncheck to get or refrain from getting mail when sellers post new Ad. whom user follows.")}</p>
                </div>
            </div>

            {auth?.authUser?.user_business_profile?.is_approved &&
                <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                    <div className="col-sm-12 col-md-8">
                        <span className="fw-semibold">{cm?.translate("Notification when someone comment to your Ad.")}</span>
                        <div className="mt-3">
                            <input name="ad_comment_notification" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.ad_comment_notification} value={userSettings?.ad_comment_notification}></input>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-start">
                        <p className="my-2 my-md-0 font-size-9">{cm?.translate("Check or uncheck to show or hide user business phone number in Ad.")}</p>
                    </div>
                </div>
            }

            <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Ad. Listing Style")}</span>
                    <div className="mt-3 d-flex">
                        <button name="ads_listing_style" className={(userSettings?.ads_listing_style === "default" ? "btn-success" : "btn-white") + " btn rounded-0"} value="default" title="Grid" onClick={(e) => handleAdListing("default")}><BsGrid3X2 className="fs-4" /></button>
                        <button name="ads_listing_style" className={(userSettings?.ads_listing_style !== "default" ? "btn-success" : "btn-white") + " btn ms-2 rounded-0"} value="thumb" title="Thumb" onClick={(e) => handleAdListing("thumb")}><BsGrid className="fs-4" /></button>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9"></p>
                </div>
            </div>

            <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Change Password")}</span>
                    <div className="mt-3">
                        <ChangePassword />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9"></p>
                </div>
            </div>
            {/* <div className="row m-0 p-2 pt-3 pb-4 border-top border-secondary border-opacity-10">
                <div className="col-sm-12 col-md-8">
                    <span className="fw-semibold">{cm?.translate("Language")}</span>
                    <div className="mt-3">
                        <select name="language" className="form-select" value={cm?.language} onChange={(e) => cm?.setLanguage(e.target.value)}>
                            <option value="en">English</option>
                            <option value="bn">{cm?.translate("Bangla")}</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-start">
                    <p className="my-2 my-md-0 font-size-9"></p>
                </div>
            </div> */}
        </div>
    );

};
export default Settings;
