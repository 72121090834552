import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useCommon } from "../../hooks/useCommon";
import { getYouTubeEmbededLink } from "../../utils/utils";

const PreviewShorts = ({ shortsOption, btnTitle }) => {
  const [show, setShow] = useState(false);
  const cm = useCommon();

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <button
        onClick={handleShow}
        className="btn btn-sm btn-white border border-opacity-25 rounded-pill p-0 px-3"
      >
        {btnTitle || cm?.translate("View")}
      </button>
      <Modal scrollable="true" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center"><img src={process.env.PUBLIC_URL + "/static/shorts.png"} height={30} alt="shorts_logo" className="" /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {shortsOption?.youtube_link ? <div className={"ratio ratio-1x1"}><iframe src={getYouTubeEmbededLink(shortsOption?.youtube_link)} width={"100%"} title={shortsOption?.title}></iframe> </div> :
            <video width="100%" controls>
              <source src={process.env.REACT_APP_MEDIA_URL + shortsOption?.shorts} type="video/mp4"></source>
            </video>
          }
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-secondary" onClick={handleClose}
          >
            {cm?.translate("Close")}
          </button>
        </Modal.Footer>
      </Modal >
    </>
  );
}
export default PreviewShorts