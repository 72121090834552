import { Navigate } from "react-router-dom";

import Layout from "../components/Layout";
import { useAuth } from "../hooks/useAuth";

const ProtectedRoutes = () => {
    const auth = useAuth()
    return auth.authUser ? <Layout /> : <Navigate to={"/login"} />

}
export default ProtectedRoutes