import { useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap"
import PreviewMediaFile from "./PreviewMediaFile"
import { BsImages } from "react-icons/bs"
import { useCommon } from "../hooks/useCommon"
import { isArray, isObject } from "lodash";
import useAlert from "../hooks/useAlert"

const ImageUploader = ({ setNewFiles, setDeletedExistingFile, deletedExistingFile, existingMediaFiles, clearPreview = false, label = "Upload Photos", multipleUpload = "true", width = "100%", previewHeight = 72, previewWidth = 72, maxSize = 2000000, className = "" }) => {
    const inputFile = useRef(null)
    const [files, setFiles] = useState([]);
    const cm = useCommon();
    const alert = useAlert();

    const toBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    useEffect(() => {
        setFiles([])
    }, []);

    useEffect(() => {
        clearPreview && setFiles([])
    }, [clearPreview])

    useEffect(() => {
        if (existingMediaFiles) {
            if (isArray(existingMediaFiles)) {
                const ms = existingMediaFiles?.filter(i => (i?.type?.startsWith("image/") || i?.media_url) && i);
                setFiles(ms)
            } else {
                setFiles([...files, existingMediaFiles])
            }
        }

    }, [existingMediaFiles])

    const handleFileUpload = e => {
        const fls = [...e.target.files];

        fls.map(i => toBase64(i).then(res => {
            if (i.size > maxSize) {
                alert.show(cm?.translate("File size should not more than " + maxSize / 1000000 + "mb"), "error");
                return;
            }
            i["base64"] = res;
            if (multipleUpload === "true") {
                if (!clearPreview) {
                    setFiles(files => [...files, i])
                }
                setNewFiles(files => [...files, i])
            } else {
                if (!clearPreview) {
                    setFiles([i])
                }
                setNewFiles([i])
            }
        }))
    }

    const removeFromPreview = (name) => {
        const mfs = files?.filter(f => !(f?.name || f?.media_url).includes(name))
        const dfs = files?.filter(f => "media_url" in f && f?.media_url.includes(name));
        setFiles(mfs)
        setNewFiles(mfs);

        if (dfs && setDeletedExistingFile) {
            setDeletedExistingFile(deletedExistingFile.concat(dfs));
        }
    }
    const renderImages = () => {
        return files.map((i, idx) => <PreviewMediaFile key={"preview_" + idx + "_" + i.name || i.media_url || i} src={i} removeFromPreview={removeFromPreview} height={previewHeight} width={previewWidth} />)
    }

    return <div className="d-flex flex-column justify-content-center align-items-center">
        <button onClick={() => inputFile.current.click()} className={className + " btn btn-white border-0 d-flex flex-row justify-content-center align-items-center rounded"} style={{ width: width }}>{<BsImages className="fs-4" />} <span className="ms-2">{cm?.translate(label)}</span></button>
        {multipleUpload === "true" ?
            <Form.Control className="d-none" ref={inputFile} type="file" accept="image/*, video/*" multiple onChange={handleFileUpload} onClick={event => event.target.value = null} /> :
            <Form.Control className="d-none" ref={inputFile} type="file" accept="image/*, video/*" onChange={handleFileUpload} onClick={event => event.target.value = null} />}
        {files && <div className="d-flex flex-wrap align-items-center m-2">
            {renderImages()}
        </div>}
    </div>
}
export default ImageUploader