import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import useAlert from "../../hooks/useAlert"
import { useCommon } from "../../hooks/useCommon";

const Inquiry = ({ post }) => {
  const [isValid, setIsValid] = useState(false);
  const [show, setShow] = useState(false);

  const [formValues, setFormValues] = useState({
    inquirer: "",
    ad: "",
    message: "",
  });

  const cm = useCommon();
  const auth = useAuth();
  const api = useApi();
  const alert = useAlert();


  useEffect(() => {
    setFormValues({
      ...formValues,
      inquirer: auth.authUser?.alias_id,
      ad: post?.alias_id
    });
  }, [post])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value };

      if (updatedValues.message) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
      return updatedValues;
    });
  };



  const handleSubmit = async (e) => {
    try {
      cm.showLoader(true, "Sending..");

      const data = { ...formValues, message: formValues?.message.replaceAll("\n", "<br/>") }

      const res = await api.createInquiryConversation(data);
      if (res) {
        alert.toast("Your inquiry was sent", "success");
        handleClose();
      }
    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error");
      cm.showLoader(false);
    }
  };


  const handleClose = () => {
    setShow(false);
    setFormValues({})
    setIsValid(false);
    cm.showLoader(false);
  };

  const handleShow = () => {
    if (!auth?.authUser) {
      window.location = "/login";
    } else {
      setShow(true);
      setFormValues({
        ...formValues,
        inquirer: auth.authUser?.alias_id,
        ad: post?.alias_id
      });
    }
  };

  return (
    <>
      <button
        onClick={handleShow}
        className="btn btn-sm btn-outline-dark  rounded-pill px-3 d-flex justify-content-center align-items-center font-size-10"
      >
        {cm?.translate("Any Question?")}
      </button>
      <Modal scrollable="true" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{cm?.translate("Enquiry")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <input
            type="text"
            name="name"
            value={auth.authUser?.user_profile?.name || auth.authUser?.email_or_phone}
            readOnly={true}
            className="form-control"
          />
          <div className="mt-3">
            <textarea
              onChange={handleChange}
              value={formValues.message}
              name="message"
              rows="2"
              autoFocus={true}
              className="form-control"
              placeholder={cm?.translate("Message")}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-outline-secondary border-0" onClick={handleClose}
          >
            {cm?.translate("Cancel")}
          </button>
          <button
            disabled={!isValid}
            onClick={handleSubmit}
            className="btn btn-sm btn-success border-0"
          >
            {cm?.translate("Send")}
          </button>


        </Modal.Footer>
      </Modal >
    </>
  );
}
export default Inquiry