import { useState } from "react"
import { Modal } from "react-bootstrap"

const ValuePill = ({ title, value, valuePreview, clickable = false, titleColorClass = "bg-secondary bg-opacity-10", valueColorClass = "bg-white", className = "" }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    return <div className={className + " font-size-10 d-flex rounded me-2 mb-2"} style={{ width: "fit-content" }}>
        <span className={"px-2 py-1 border border-secondary border-opacity-50 border-end-0 rounded-start " + (titleColorClass ? titleColorClass : "bg-light")}>{title}</span>
        {clickable ? <span className={"px-2 py-1 border border-secondary border-opacity-50 border-start-0 rounded-end " + valueColorClass} onClick={handleShow} >{value}</span>
            : <span className={"px-2 py-1 border border-secondary border-opacity-50 border-start-0 rounded-end " + valueColorClass}>{value}</span>}
        <Modal scrollable="true" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                {title}
            </Modal.Header>
            <Modal.Body className="p-0">
                {valuePreview}
            </Modal.Body>
        </Modal >
    </div>
}
export default ValuePill