import React, { useState } from "react";
import { useEffect } from "react";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import PostTemplate from "../PostTemplate";

const Orders = () => {
    const [orders, setOrders] = useState([])
    const [orderDirection, setOrderDirection] = useState("sent")

    const api = useApi();
    const auth = useAuth();
    const cm = useCommon()
    const alert = useAlert()


    const getMyOrders = async (params) => {
        try {
            cm.showLoader(true);
            const res = await api?.getOrders(params);
            if (res) {
                setOrders(res?.results);
            }
            cm.showLoader(false);
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false);
        }
    };


    useEffect(() => {
        setOrderDirection("sent")
        getMyOrders({ direction: "sent" });
    }, [])


    const renderOrders = () => {
        return orders?.map((o, i) => {
            const postAtOrder = JSON.parse(o.post_info_at_order);
            return <Accordion.Item eventKey={i} key={"ord_" + i} className="rounded-0">
                <Accordion.Header className="">{o.order_no} <span className="ms-3 font-size-9 text-secondary">{moment(o.order_date).format("YYYY-MM-DD HH:mm")}</span></Accordion.Header>
                <Accordion.Body className="py-4">
                    <div className="d-flex flex-column justify-content-between align-items-stretch">
                        <PostTemplate post={postAtOrder} disabled={true} fullWidth={true} />
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-stretch m-0 mt-2">
                            <div className="col bg-warning bg-opacity-25 rounded p-3 shadow-sm me-0 me-md-1 mt-2 mt-md-0">
                                <div className="d-flex justify-content-between">
                                    <span className="font-size-11">{cm?.translate("Quantity")}</span>
                                    <span>{o?.quantity}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="font-size-11">{cm?.translate("Total Price")}</span>
                                    <div className="">
                                        <span className="">{o?.total_price}<span className="ms-1 font-size-9">{o?.currency}</span></span>
                                    </div>
                                </div>
                                {o?.tax > 0 && <div className="d-flex justify-content-between">
                                    <span className="font-size-11">{cm?.translate("Tax")}</span>
                                    <div className="">
                                        <span className="">{o?.tax}<span className="ms-1 font-size-9">{o?.currency}</span></span>
                                    </div>
                                </div>}
                                {o?.delivery_charge > 0 && <div className="d-flex justify-content-between">
                                    <span className="font-size-11">{cm?.translate("Delivery Charge")}</span>
                                    <div className="">
                                        <span className="">{o?.delivery_charge}<span className="ms-1 font-size-9">{o?.currency}</span></span>
                                    </div>
                                </div>}
                                <div className="d-flex justify-content-between text-danger fw-semibold">
                                    <span className="font-size-11">{cm?.translate("Total Pay")}</span>
                                    <div className="">
                                        <span className="">{o?.total_pay}<span className="ms-1 font-size-9">{o?.currency}</span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col ms-0 mt-2 mt-md-0 ms-md-1 bg-light rounded shadow-sm p-3 d-flex flex-column justify-content-center align-items-center">
                                <span className="font-size-11 fw-semibold">{cm?.translate("Delivery Instruction")}</span>
                                <p className="mb-0 font-size-11" dangerouslySetInnerHTML={{ __html: o?.delivery_info }}></p>
                            </div>
                        </div>
                    </div>

                </Accordion.Body>
            </Accordion.Item >
        }
        );
    };

    const decodeSearchParams = (searchParams) => {
        return [...searchParams.entries()].reduce((acc, [key, val]) => {
            return {
                ...acc,
                [key]: val
            };
        }, {});
    };



    return (
        <div>
            <Tabs
                activeKey={orderDirection}
                onSelect={(k) => setOrderDirection(k)}
                className="mb-3"
            >
                <Tab eventKey="sent" title={cm?.translate("Sent Orders")} className="p-2">
                    <Accordion className="mt-2">
                        {renderOrders()}
                    </Accordion>
                </Tab>
                {auth?.authUser?.user_business_profile && <Tab eventKey="received" title={cm?.translate("Received Orders")} className="p-2 justify-content-center">
                    <Accordion className="mt-2">
                        {renderOrders()}
                    </Accordion>
                </Tab>}
            </Tabs>
        </div >
    );
};
export default Orders;
