import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon";

const PreviewHtml = ({ title, content, setContent }) => {
  const [show, setShow] = useState(false);
  const cm = useCommon();

  const handleClose = () => {
    setShow(false);
    setContent(null)
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    handleShow()
  }, [content])

  return (
    <Modal scrollable="true" show={show} onHide={handleClose} centered>

      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">{title || "FAQ"}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4">
        <p className="m-0" dangerouslySetInnerHTML={{ __html: content }}></p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-secondary" onClick={handleClose}
        >
          {cm?.translate("Close")}
        </button>
      </Modal.Footer>
    </Modal >
  );
}
export default PreviewHtml