import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useApi } from "../../hooks/useApi"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import { IoMdImages } from "react-icons/io";
import CreateAdStepOne from "../stepbystep/CreateAdStepOne";
import CreateAdStepTwo from "../stepbystep/CreateAdStepTwo";
import CreateAdStepThree from "../stepbystep/CreateAdStepThree";
import CreateAdStepFour from "../stepbystep/CreateAdStepFour";
import CreateAdStepFive from "../stepbystep/CreateAdStepFive";
import CreateAdStepSix from "../stepbystep/CreateAdStepSix";
import CreateAdStepSeven from "../stepbystep/CreateAdStepSeven";
import CreateAdStepFinal from "../stepbystep/CreateAdStepFinal";
import QuickRegister from "../stepbystep/QuickRegister";
import VerifyOTP from "../stepbystep/VerifyOTP";
import QuickSignIn from "../stepbystep/QuickSignIn";

const CreateAd = ({ btnTitle = "Create Ad", redirectPath = "/ads", className }) => {
    const [show, setShow] = useState(false);
    const [navigateToStep, setNavigateToStep] = useState(1)


    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();


    const handleClose = () => {
        cm?.setCreateAdData({})
        setNavigateToStep(1)
        cm.showLoader(false);
        setShow(false);
    }

    const handleShow = () => {
        setShow(true)
    }

    const handleSave = async () => {
        try {
            cm.showLoader(true);
            const cd = { ...cm?.createAdData, description: cm?.createAdData?.description.replaceAll("\n", "<br />"), media: cm?.createAdData?.media?.map(f => f?.base64) }
            const res = await api.createAd(cd);
            if (res) {
                const pricing = { ...cm?.createAdData?.pricing, adAliasId: res?.alias_id, orderRelatedInfo: cm?.createAdData?.pricing?.orderRelatedInfo && cm?.createAdData?.pricing?.orderRelatedInfo.replaceAll("\n", "<br />") }

                if (pricing?.unitPrice) {
                    await api.createPricing(pricing)
                }
                handleClose()
                alert.showThenRedirect(cm?.translate("Congratulation! Your Ad was posted."), "success", redirectPath);
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false);
        }
    }

    const renderStepPage = (step) => {
        switch (step) {
            case 1:
                return <CreateAdStepOne navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 2:
                return <CreateAdStepTwo navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 3:
                return <CreateAdStepThree navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 4:
                return <CreateAdStepFour navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 5:
                return <CreateAdStepFive navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 6:
                return <CreateAdStepSix navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 7:
                return <CreateAdStepSeven navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 8:
                return <QuickRegister navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 9:
                return <QuickSignIn navigateToStep={step} setNavigateToStep={setNavigateToStep} />
            case 10:
                return <VerifyOTP navigateToStep={11} setNavigateToStep={setNavigateToStep} />
            case "final":
                return <CreateAdStepFinal navigateToStep={7} setNavigateToStep={setNavigateToStep} redirectPath={redirectPath} handleSave={handleSave} />
            default:
                return
        }
    }

    return <>
        <button onClick={handleShow} className={className + " align-self-stretch btn btn-outline-success border-0 text-dark hover-text-white d-flex justify-content-center align-items-center flex-column flex-md-row"}>
            <IoMdImages className="fs-4 hover-text-white" />
            <span className="d-inline-block ms-0 ms-md-2">{cm?.translate(btnTitle)}</span>
        </button>

        <Modal size="lg" fullscreen={false} scrollable="true" show={show} onHide={handleClose} backdrop="static" centered >
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Create Ad")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-1 overflow-scroll" style={{ maxHeight: 600 }}>
                {renderStepPage(navigateToStep)}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <div>
                    <a href="/docs?title=terms" className="text-decoration-none link-success font-size-10">{cm?.translate("Terms & Condition")}</a>
                </div>
                <div>
                    <button className="btn btn-sm btn-outline-dark border-0" onClick={handleClose}>{cm?.translate("Cancel")}</button>
                </div>
            </Modal.Footer>
        </Modal >
    </>
}
export default CreateAd;