import { isObject } from "lodash";

const PreviewMediaFile = ({ src, removeFromPreview, height = 64, width = 64 }) => {
    const getImageSource = () => {
        if (isObject(src)) {
            if (src?.media_url) {
                return process.env.REACT_APP_MEDIA_URL + src?.media_url
            } else if (src?.base64) {
                return src?.base64
            }
        }
        else {
            if (src.includes("data:image")) {
                return src
            } else {
                return process.env.REACT_APP_MEDIA_URL + src
            }
        }
    }
    return <div className="position-relative" style={{ width: "fit-content" }}>
        <img alt="preview_image" src={getImageSource()} className="img-fluid m-0 object-fit-contain"
            style={{ width: width, height: height }} />
        <button type="button" title="Delete" onClick={() => removeFromPreview(src?.name || src?.media_url || src)} className="btn-close rounded-0 bg-light font-size-9 position-absolute top-0 end-0 m-1" aria-label="Close"></button>
    </div>
}
export default PreviewMediaFile;