import { TbUserSquareRounded } from "react-icons/tb";
import moment from "moment";
import { useAuth } from "../hooks/useAuth";

const Comment = ({ comment, deleteComment }) => {
    const auth = useAuth();

    return <div key={"comment_" + comment.id} className="mb-2 border border-opacity-10 rounded">
        <div className="bg-light d-flex align-items-center justify-content-between p-1 rounded-top ">
            <div className="font-size-10 d-flex align-items-center  border-opacity-10">
                {(comment?.commenter?.user_business_profile?.company_logo || comment?.commenter?.user_profile?.profile_image) ? <img src={process.env.REACT_APP_MEDIA_URL + (comment?.commenter?.user_business_profile?.company_logo || comment?.commenter?.user_profile?.profile_image)} className="me-1 rounded-1" alt="commenter" style={{ height: 24, width: 24 }} /> : <TbUserSquareRounded style={{ fontSize: 24 }} />}
                <a href={"/members/" + comment?.commenter?.alias_id} className="text-decoration-none link-dark me-auto ms-1">{comment?.commenter?.user_business_profile?.company_name || comment?.commenter?.user_profile?.name}</a>
                <small className="ms-2">{moment(comment?.comment_time).fromNow()}</small>
            </div>
            {auth.authUser?.alias_id === comment?.commenter?.alias_id && <button type="button" className="btn-close font-size-9" onClick={() => deleteComment(comment?.id)}></button>}
        </div>
        <div className="font-size-10 p-2">
            <p className="mb-1" dangerouslySetInnerHTML={{ __html: comment?.comment }}></p>
        </div>
    </div>
}
export default Comment