import React, { useState } from "react";
import UserReaction from "../UserReaction";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import moment from "moment";
import ModifyAd from "./ModifyAd";
import { BsGlobe, BsLock, BsPersonFillLock } from "react-icons/bs";
import Swal from "sweetalert2";
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert";
import { useCommon } from "../../hooks/useCommon";
import CreateShorts from "../modals/CreateShorts";
import PreviewShorts from "../modals/PreviewShorts";
import SetToTopAd from "../modals/admin/SetToTopAd";


const PostTemplateForMyPage = ({ post, refreshAd }) => {
    const [showCreateShorts, setShowCreateShorts] = useState(false)
    const api = useApi();
    const alert = useAlert();
    const cm = useCommon();

    const renderMediaFiles = () => {
        if (post?.media.length > 0) {
            return post?.media?.map((m, i) => <img src={process.env.REACT_APP_MEDIA_URL + (m?.media_url_resized100x100 || m?.media_url_resized || m?.media_url)} key={"img_" + post?.alias_id + "_" + i} alt="img" className="me-1" style={{ width: 72, height: 72, objectFit: "cover" }} />)
        } else {
            return <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" key={"img_" + post?.alias_id} alt="no_img" className="me-1 ratio ratio-1x1" style={{ width: 56, height: 56, objectFit: "contain" }} />
        }
    }

    const handleDelete = () => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await api.deleteAd(post?.alias_id);
                    if (res) {
                        alert.toast(cm?.translate("Ad. was deleted"), "success")
                        refreshAd(post, "delete")
                    }
                } catch (err) {
                    alert.show(err, "error")
                }
            }
        });
    }

    const deleteShorts = async (e) => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await api.deleteShorts(post?.alias_id);
                    if (res) {
                        cm?.setRefreshAdInMyAds(post);
                    }
                } catch (err) {
                    alert.show(err, "error")
                }
            }
        });
    }

    const enableShortsButton = () => {
        const shortsBtn = <>
            <button
                onClick={(e) => setShowCreateShorts(true)}
                className="btn btn-sm bg-white border border-opacity-25 rounded-pill p-0 px-2"
            >
                {cm?.translate("Create")}
            </button>
            {showCreateShorts && <CreateShorts ad={post} setShowCreateShorts={setShowCreateShorts} />}
        </>

        if (Object.keys(post?.shorts_option).length && post?.shorts_option?.shorts) {
            return <>
                <PreviewShorts shortsOption={post?.shorts_option} />
                <button type="button" title={cm?.translate("Delete")} onClick={deleteShorts} className="btn btn-sm bg-white border border-danger border-opacity-50 text-danger rounded-pill p-0 px-2 ms-2" aria-label="Close">{cm?.translate("Delete")}</button>
            </>
        } else if (Object.keys(post?.shorts_option).length && !post?.shorts_option?.shorts) {
            return <>
                <span>{cm?.translate("In progress..")}</span>
                <button type="button" title={cm?.translate("Delete")} onClick={deleteShorts} className="btn btn-sm bg-white border border-danger border-opacity-50 text-danger rounded-pill p-0 px-2 ms-2" aria-label="Close">{cm?.translate("Delete")}</button>
            </>
        } else {
            return shortsBtn
        }
    }

    const checkTopAdStatus = () => {
        // 1 = running, 0 = expired, -1 = not set
        if (moment() < moment(post?.top_ads?.expire)) {
            return 1
        } else if (moment() > moment(post?.top_ads?.expire)) {
            return 0
        }
        else {
            return -1
        }
    }

    return (
        <div
            className="col-sm-12 p-0 mb-3"
        >
            <div className={(moment(post?.updated).fromNow().toString().includes("seconds ago") ? " border-danger " : post?.is_approved ? " border-success " : " border-secondary ") + "thumb-hover border border-2 border-opacity-25 d-flex flex-column font-size-11 p-1 rounded-1"}>
                <div className={"bg-success px-2 py-2 bg-opacity-10 border-bottom d-flex flex-wrap justify-content-between align-items-center"}>
                    <UserReaction post={post} size={"fs-6"} disable={true} />
                    <span className="d-flex align-items-center" title={cm?.translate("Privacy")}>{post?.privacy?.name === "Public" ? <BsGlobe className="fs-6 me-1" /> : post?.privacy?.name === "Private" ? <BsLock className="fs-6 me-1" /> : <BsPersonFillLock className="fs-6 me-1" />} {post?.privacy?.name}</span>
                    <div className="d-flex flex-wrap">
                        <span title={cm?.translate("Created on")}>{moment(post?.created).format("YYYY-MM-DD HH:mm")}</span>
                        <span className="mx-2">-</span>
                        <span title={cm?.translate("Expire on")}>{moment(post?.expire).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                </div>

                {post?.media?.length > 0 &&
                    <div className="row m-0 bg-light border-bottom">
                        <div className="col-3 p-2">{cm?.translate("Photos")}</div>
                        <div className="col bg-white p-2">
                            {renderMediaFiles()}
                        </div>
                    </div>
                }

                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Title")}</div>
                    <div className="col bg-white p-2"><a href={"/ads/" + post.alias_id} target="_blank" rel="noreferrer" className="text-decoration-none link-dark font-size-12 fw-semibold">{post?.title}</a></div>
                </div>


                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">{cm?.translate("Category")}</div>
                    <div className="col bg-white p-2">{post?.category?.group?.name} <MdOutlineKeyboardDoubleArrowRight />                    {post?.category?.name}</div>
                </div>
                {
                    post?.category?.group?.name !== "Job" &&
                    < div className="row m-0 bg-light border-bottom">
                        <div className="col-3 p-2 d-flex justify-content-between align-items-center">
                            {cm?.translate("Price")}
                        </div>
                        <div className="col bg-white p-2 d-flex align-items-center">
                            <span>{post?.pricing?.unit_price} {post?.pricing?.currency}</span> <span className="ms-2">{post?.pricing?.serving_unit}</span>
                        </div>
                    </div>
                }


                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">{cm?.translate("Ad. Status")}</div>
                    <div className="col bg-white p-2 d-flex align-items-center">{post?.is_approved ? "Approved" : <span className="text-danger">Not Approved</span>}</div>
                </div>


                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Top Ad Status")}</div>
                    <div className="col bg-white p-2 d-flex align-items-center">{checkTopAdStatus() === 1 ? <>{cm?.translate("Expire on ") + moment(post?.top_ads?.expire).format("YYYY-MM-DD HH:mm")}</> : checkTopAdStatus() === 0 ? <>{cm?.translate("Expired on ") + moment(post?.top_ads?.expire).format("YYYY-MM-DD HH:mm")}</> : cm?.translate("Not set")}</div>
                </div>

                <div className="p-2 bg-light border-bottom d-flex fle justify-content-between align-items-center">
                    <div>
                        {checkTopAdStatus() !== 1 && <SetToTopAd post={post} setPost={cm?.setRefreshAdInMyAds} />}
                    </div>
                    <div className="">
                        <ModifyAd post={post} refreshAd={refreshAd} />
                        <button className="btn btn-sm btn-outline-dark ms-2" onClick={handleDelete}>{cm?.translate("Delete")}</button>
                    </div>
                </div>

            </div>
        </div >
    );
};
export default PostTemplateForMyPage;
