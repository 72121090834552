import { GrNext, GrPrevious } from "react-icons/gr";
import { useCommon } from "../../hooks/useCommon"

const CreateAdStepTwo = ({ navigateToStep, setNavigateToStep }) => {
    const cm = useCommon();

    const handleCategoryClick = (c) => {
        cm?.setCreateAdData({ ...cm?.createAdData, category: c })
    }

    const handleNext = () => {
        setNavigateToStep(navigateToStep + 1)
    }

    const handlePrev = () => {
        setNavigateToStep(navigateToStep - 1)
    }

    const renderCategory = () => {
        return cm?.categories?.map((c, ix) => c?.group?.id === cm?.createAdData?.categoryGroup?.id && <button key={`cat-${c?.id}`
        } className={(cm?.createAdData?.category?.id === c?.id ? "btn btn-success m-1" : "btn btn-outline-success m-1")
        } onClick={() => handleCategoryClick(c)}>
            {cm?.translate(c?.name)}
        </button >
        )
    }

    return <div className="text-center">
        <h4 className="text-bg-success p-2">{cm?.translate("Sub-Category")}</h4>
        <div className="m-0 my-5 px-3 d-flex flex-row flex-wrap justify-content-center">
            {renderCategory()}
        </div>
        <div className="w-100 mt-5 mb-4 d-flex flex-row justify-content-center align-items-center">
            <button className="btn btn-dark w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handlePrev}><GrPrevious className="fs-6 me-2" /> {cm?.translate("Previous")}</button>
            <button disabled={!cm?.createAdData?.category} className="btn btn-success w-sm-100-md-25 mx-2 d-flex flex-row justify-content-center align-items-center" onClick={handleNext}>{cm?.translate("Next")} <GrNext className="fs-6 ms-2" /></button>
        </div>
    </div>
}
export default CreateAdStepTwo