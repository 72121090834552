import { useEffect, useState } from "react"
import { useApi } from "../hooks/useApi";
import useAlert from "../hooks/useAlert";
import { useCommon } from "../hooks/useCommon";
import { Tab, Tabs } from "react-bootstrap";

const Contact = () => {
    const [content, setContent] = useState("");
    const [key, setKey] = useState('contact');
    const [imageText, setImageText] = useState(null);
    const [randomText, setRandomText] = useState(Math.random().toString(36).substring(2, 8));
    const [inquiry, setInquiry] = useState({
        sender: "",
        subject: "",
        message: "",
        randomText: ""
    });

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();


    const getStaticContent = async (title) => {
        try {
            const res = await api.getStaticContent("contact");
            if (res) {
                setContent(res);
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err?.response?.data?.detail || err?.response?.statusText, "error");
        }
    }



    const randomTextToImage = () => {
        var canvas = document.createElement("canvas");
        canvas.width = 80;
        canvas.height = 36;
        var ctx = canvas.getContext('2d');
        ctx.font = "20px Arial";
        ctx.fillText(randomText, 8, 24);
        setImageText(canvas.toDataURL())
    }

    useEffect(() => {
        getStaticContent("contact")
        randomTextToImage()
    }, []);

    const handleInput = (e) => {
        setInquiry({ ...inquiry, [e.target.name]: e.target.value })
    }

    const handleSend = async (e) => {
        try {
            cm.showLoader(true);
            const data = { ...inquiry, message: inquiry?.message?.replaceAll("\n", "<br />") }
            const res = await api.sendMail(data);
            if (res) {
                cm.showLoader(false);
                alert.showThenRedirect(cm?.translate("Inquiry was sent"), "success", "/contact");
            }

        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    };

    return <div className={"col-sm-12 col-md-9 mx-auto my-2 my-md-4 px-2 px-md-0"}>
        <div className="mt-3 border border-success border-opacity-25 rounded p-4">
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="contact" title={cm?.translate("Contact Us")} className="p-4">
                    <p dangerouslySetInnerHTML={{ __html: content?.[cm?.language] }}>
                    </p>
                </Tab>
                <Tab eventKey="inquiry" title={cm?.translate("Send Your Enquiry")} className="p-4 justify-content-center">
                    <input name="sender" placeholder={cm?.translate("Email Address")} className="form-control mb-2" onKeyUp={e => handleInput(e)}></input>
                    <input name="subject" placeholder={cm?.translate("Subject")} className="form-control mb-2" onKeyUp={e => handleInput(e)}></input>
                    <textarea name="message" placeholder={cm?.translate("Type your message")} rows="3" className="form-control" onKeyUp={e => handleInput(e)}></textarea>
                    <div className="mt-3 d-flex align-items-center">
                        <img src={imageText} alt="randomText" />
                        <input name="randomText" placeholder={cm.translate("Type left side letters")} className="ms-2 form-control" onKeyUp={e => handleInput(e)}></input>
                    </div>
                    <div className="mt-3 d-flex justify-content-center">
                        <button className="btn btn-sm btn-success mt-3 d-block w-25" disabled={!(inquiry?.sender && inquiry?.subject && inquiry?.message && randomText === inquiry?.randomText)} onClick={handleSend}>{cm?.translate("Send")}</button>
                    </div>
                </Tab>

            </Tabs>

        </div>

    </div>
}
export default Contact