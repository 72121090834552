import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

const PostBasicInfo = ({ post }) => {

    const renderTitle = () => {
        return <Link to={"/ads/" + post?.alias_id} target="_blank" className="text-decoration-none link-dark">
            <OverlayTrigger trigger={post?.title.length > 30 ? ["hover", "focus"] : ""}
                overlay={
                    <Tooltip>
                        {post?.title}
                    </Tooltip>
                }
            >
                <span className="ellipsis-1 font-size-14">{post?.title}</span>
            </OverlayTrigger>
        </Link>
    }

    return <div className="d-flex flex-column">
        {renderTitle(post)}
        <div className="d-flex flex-wrap align-items-center">
            <Link to={"/ads/?category=" + post?.category?.name} className="text-decoration-none link-success font-size-10">{post?.category?.name}</Link>
            <MdOutlineKeyboardArrowRight />
            <Link to={"/ads/?location=" + (post?.location?.name || post?.owner?.user_business_profile?.location?.name)} className="text-decoration-none link-success font-size-10">{post?.location?.name || post?.owner?.user_business_profile?.location?.name}</Link>
            <MdOutlineKeyboardArrowRight />
            <span className="font-size-10">{moment(post?.created).fromNow()}</span>
        </div>
    </div>

};
export default PostBasicInfo