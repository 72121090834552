import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon"
import TopAdTemplate from "../TopAdTemplate";
import { Carousel } from "react-bootstrap";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";


const TopAds = () => {
    const [ads, setAds] = useState()
    const cm = useCommon()
    const api = useApi()
    const alert = useAlert()

    const getTopAdsFromApi = async () => {
        try {
            cm?.showLoader(true)
            const res = await api.getTopAds()
            if (res) {
                setAds(res);
            }
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err, "error")
        }
    };

    useEffect(() => {
        getTopAdsFromApi();
    }, [])


    const renderTopAds = () => {
        return ads?.map((a, i) => <Carousel.Item key={"topad_" + i} interval={15000}>
            <TopAdTemplate post={a} />
        </Carousel.Item>)
    }

    if (ads?.length > 0) {
        return <div className="row m-0 my-2">
            <div className="col-sm-12 px-0">
                <Carousel fade className="rounded" style={{ maxWidth: 1200, height: 300 }}>
                    {renderTopAds()}
                </Carousel>
            </div>
        </div>
    } else {
        <></>
    }

}
export default TopAds