import Swal from "sweetalert2"
import { useApi } from "../../hooks/useApi"
import { useCommon } from "../../hooks/useCommon"
import { getAge } from "../../utils/utils"
import ValuePill from "../ValuePill"
import GiveCoins from "../modals/GiveCoins"
import SendSms from "../modals/SendSms"
import SendMail from "../modals/SendMail"
import { useAuth } from "../../hooks/useAuth"
import useAlert from "../../hooks/useAlert"
import CreateBusinessProfile from "../modals/admin/CreateBusinessProfile"

const Member = ({ member, members, setMembers }) => {

    const cm = useCommon()
    const api = useApi()
    const auth = useAuth()
    const alert = useAlert()

    const renderAge = (dob) => {
        return dob ? getAge(dob) : ""
    }

    const renderGender = (gender) => {
        return gender === "m" ? "Male" : gender === "f" ? "Female" : gender === "o" ? "Other" : ""
    }


    const callSuspendMember = async (alias_id) => {
        try {
            cm.showLoader(true, "Suspending..");
            const res = await api.suspendMember(alias_id);
            if (res) {
                alert.toast("Suspended", "success");
                const ms = members?.results?.map(m => {
                    if (m?.alias_id === res?.alias_id) {
                        return res;
                    } else {
                        return m
                    }
                })

                setMembers({ ...members, results: ms })
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    const callActivateMember = async (alias_id) => {
        try {
            cm.showLoader(true, "Activating..");
            const res = await api.activateMember(alias_id);
            if (res) {
                alert.toast("Activated", "success");
                const ms = members?.results?.map(m => {
                    if (m?.alias_id === res?.alias_id) {
                        return res;
                    } else {
                        return m
                    }
                })

                setMembers({ ...members, results: ms })
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    const callSuspendBusinessAccount = async (alias_id) => {
        try {
            cm.showLoader(true, "Suspending..");
            const res = await api.updateBusinessProfile(alias_id, { "is_approved": false });
            if (res) {
                alert.toast("Suspended", "success");
                const ms = members?.results?.map(m => {
                    if (m?.alias_id === alias_id) {
                        return { ...m, user_business_profile: res };
                    } else {
                        return m
                    }
                })

                setMembers({ ...members, results: ms })
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    const callApproveBusinessAccount = async (alias_id) => {
        try {

            cm.showLoader(true, "Approving..");
            const res = await api.updateBusinessProfile(alias_id, { "is_approved": true });
            if (res) {
                alert.toast("Approved", "success");
                const ms = members?.results?.map(m => {
                    if (m?.alias_id === alias_id) {
                        return { ...m, user_business_profile: res };
                    } else {
                        return m
                    }
                })

                setMembers({ ...members, results: ms })
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    const callDeleteMember = async (alias_id) => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    cm.showLoader(true, "Deleting..");
                    const res = await api.deleteMember(alias_id);
                    if (res) {
                        alert.show("Member was deleted", "success");
                        const ms = members?.results?.filter(m => m.alias_id !== alias_id)
                        setMembers({ ...members, results: ms })
                    }
                    cm.showLoader(false);
                } catch (err) {
                    cm.showLoader(false);
                    alert.show(err?.response?.data?.msg || err, "error");
                }
            }
        });

    }
    const extractEmail = (user) => {
        if (user?.user_business_profile?.company_email) {
            return user?.user_business_profile?.company_email;
        } else if (user?.email_or_phone?.includes("@")) {
            return user?.email_or_phone;
        } else if (user?.user_profile?.email_or_phone?.includes("@")) {
            return user?.user_profile?.email_or_phone;
        } else {
            return false
        }
    }

    const extractPhone = (user) => {
        if (user?.user_business_profile?.company_phone) {
            return user?.user_business_profile?.company_phone;
        } else if (!user?.email_or_phone?.includes("@")) {
            return user?.email_or_phone;
        } else if (!user?.user_profile?.email_or_phone?.includes("@")) {
            return user?.user_profile?.email_or_phone;
        } else {
            return false
        }
    }


    return <div className="col-sm-12 mb-3">
        <div className="border">
            <div className="bg-white p-2 row m-0">
                <div className="col-sm-12 col-md py-2">
                    <h6>Main Account</h6>
                    <div className="d-flex flex-wrap">
                        <ValuePill title="Alias Id" value={<a href={"members/" + member?.alias_id} target="_blank" rel="noreferrer" className="link-primary text-decoration-none font-size-11 text-break">{member.alias_id}</a>} />
                        <ValuePill title="ID" value={<a href={"members/" + member?.alias_id} target="_blank" rel="noreferrer" className="link-primary text-decoration-none font-size-11">{member.email_or_phone}</a>} />
                        <ValuePill title="Name" value={member.user_profile?.name} />
                        <ValuePill title="Age" value={renderAge(member?.user_profile?.dob)} />
                        <ValuePill title="Gender" value={renderGender(member?.user_profile?.gender)} />
                        <ValuePill title={member?.email_or_phone.includes("@") ? "Phone" : "Email"} value={member.user_profile?.email_or_phone} />
                        <ValuePill title="Address" value={member.user_profile?.address} />
                        <ValuePill title="Active" value={member.is_active ? "Active" : "Inactive"} titleColorclassName={member.is_active ? "bg-success bg-opacity-25" : "bg-danger bg-opacity-25"} />
                    </div>
                    <ValuePill title="Profile Image" value={member.user_profile?.profile_image ? <img src={process.env.REACT_APP_MEDIA_URL + member.user_profile?.profile_image} alt="profile_image" style={{ width: 36, height: 36 }} /> : "Not uploaded"} />
                </div>
                <div className="col-sm-12 col-md py-2">
                    <h6>Business Profile</h6>
                    <div className="d-flex flex-row flex-wrap">
                        <ValuePill title="Logo" value={member.user_business_profile?.company_logo ? <img src={process.env.REACT_APP_MEDIA_URL + member.user_business_profile?.company_logo} alt="logo" style={{ width: 36, height: 36 }} /> : "Not uploaded"} />
                        <ValuePill title="Identification Doc." value={member.user_business_profile?.identification_doc ? <img src={process.env.REACT_APP_MEDIA_URL + member.user_business_profile?.identification_doc} alt="identification_doc" style={{ width: 36, height: 36 }} /> : "Not uploaded"} valuePreview={member.user_business_profile?.identification_doc ? <img src={process.env.REACT_APP_MEDIA_URL + member.user_business_profile?.identification_doc} alt="identification_doc" style={{ width: "100%" }} /> : ""} clickable={true} titleColorclassName={member.user_business_profile?.identification_doc ? "" : "bg-warning bg-opacity-50"} />
                    </div>
                    <div className="d-flex flex-wrap">
                        <ValuePill title="Company" value={member.user_business_profile?.company_name} />
                        <ValuePill title="Web Link" value={member.user_business_profile?.web_link} />
                        <ValuePill title="Location" value={member.user_business_profile?.location?.name} />
                        <ValuePill title="Location" value={member.user_business_profile?.location?.name} />
                        <ValuePill title="Address" value={member.user_business_profile?.address} />
                        <ValuePill title="Company Phone" value={member.user_business_profile?.company_phone} />
                        <ValuePill title="Company Email" value={member.user_business_profile?.company_email} />
                        <ValuePill title="Approval" value={member.user_business_profile?.is_approved ? "Approved" : "Pending"} titleColorclassName={member.user_business_profile?.is_approved ? "bg-success bg-opacity-25" : "bg-danger bg-opacity-25"} />
                        <ValuePill title="Coins" value={member?.coins} />
                    </div>

                </div>
            </div>
            <div className="bg-light p-2 d-flex flex-row flex-wrap justify-content-end rounded-bottom">
                {extractPhone(member) && <SendSms receiver={extractPhone(member)} />}
                {extractEmail(member) && <SendMail receiver={extractEmail(member)} sender={extractEmail(auth?.authUser)} adminMessage={true} />}

                {<GiveCoins receivableUser={member} />}

                {member?.is_active && <button className="btn btn-sm btn-outline-dark" onClick={e => callSuspendMember(member?.alias_id)}>Suspend Main Account</button>}
                {!member?.is_active && <button className="btn btn-sm btn-outline-dark me-1 ms-1" onClick={e => callActivateMember(member?.alias_id)}>Activate Main Account</button>}
                {Object.keys(member?.user_business_profile).length === 0 && <CreateBusinessProfile member={member} />}
                {Object.keys(member?.user_business_profile).length > 0 && member?.user_business_profile?.is_approved && <button className="btn btn-sm btn-outline-dark ms-1" onClick={e => callSuspendBusinessAccount(member?.alias_id)}>Suspend Business Profile</button>}
                {Object.keys(member?.user_business_profile).length > 0 && !member?.user_business_profile?.is_approved && <button className="btn btn-sm btn-outline-dark ms-1" onClick={e => callApproveBusinessAccount(member?.alias_id)}>Approve Business Profile</button>}
                <button className="btn btn-sm btn-outline-dark ms-1" onClick={e => callDeleteMember(member?.alias_id)} >Delete</button>
            </div>
        </div>
    </div >
}
export default Member