import { useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';

const MediaCarousel = ({showCarosel, mediaList}) => {
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {
        scrollToTop()
        const handleEsc = (event) => {
           if (event.key === 'Escape') {
            showCarosel(false)
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
    }, []);

      
    const renderCarouselItem = () => {
        return mediaList.map((e,i) => (<Carousel.Item key={"ci_"+i}>
            <img key={"img_"+i} src={e} className="object-fit-content" alt="img" style={{maxHeight:"100vh", maxWidth:"100vw"}}/>
          </Carousel.Item>))
    }

    return (
        <div className="d-block bg-dark overflow-hide position-absolute start-0 top-0 end-0 bottom-0 text-center d-flex justify-content-center align-items-center z-3" style={{height:"100vh", maxWidth:"100vw"}}>
            <button type="button" onClick={() =>showCarosel(false)} className="btn-close bg-white rounded-0 position-absolute top-0 end-0 m-1" aria-label="Close"></button>
            <Carousel>
                {renderCarouselItem()}
            </Carousel>
        </div>
    )
}
export default MediaCarousel