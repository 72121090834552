import { useEffect, useState } from "react"
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import Pagination from "../Pagination";
import Member from "./Member";

const AdminMembers = () => {
    const [members, setMembers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState("")

    const cm = useCommon()
    const api = useApi()
    const alert = useAlert()

    const getAllMembers = async (params = {}) => {
        try {
            cm.showLoader(true)
            const res = await api.getMembers(params)
            if (res) {
                setMembers(res);
                cm.showLoader(false)
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false)
        }
    };

    useEffect(() => {
        getAllMembers()
    }, [])

    const renderMembers = () => {
        if (members?.results?.length > 0) {
            return members?.results?.map((m, ix) => <Member key={"member_" + m?.alias_id} member={m} members={members} setMembers={setMembers} />)
        } else {
            return "There is no member"
        }
    }

    const onChangePage = (e) => {
        getAllMembers({ page: e.target.value });
        setCurrentPage(e.target.value)
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
        if (e.key === 'Enter') {
            getAllMembers({ search: e.target.value });
        }
    }

    const handleSearchClicked = (e) => {
        getAllMembers({ search: search });
    }


    return <>
        <div className="mt-2 mt-md-0 mb-3 d-flex flex-row justify-content-between align-items-center rounded-2 bg-success bg-opacity-10 border border-success border-opacity-25 p-2 ">
            <div className="input-group w-auto">
                <input type="text" name="search" className="form-control w-auto font-size-11" placeholder={cm?.translate("Type keyword")} onKeyUp={e => handleSearch(e)} />
                <button className="btn btn-sm btn-success rounded-end" onClick={e => handleSearchClicked(e)}>{cm?.translate("Search")}</button>
            </div>
        </div>
        {renderMembers()}
        <div className="mt-4 mb-5">
            <Pagination totalItems={members?.count} onChangePage={onChangePage} currentPage={currentPage} />
        </div>
    </>


}
export default AdminMembers